import React from 'react';
import { Button, Table, Select, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { IMG_EDIT } from '../../utils/ImageUtils';
import { userProfile } from '../../en.json';
import { DEV } from '../../constants/UserRolesConstant';


@inject("store")
@observer
class WellnessForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isWellnessPlanEdit: false,
    };
  }

  componentDidMount() {
    const {
      store: {
        ProfileStore,
      }
    } = this.props;
    // Fetch wellness plan by user when admin clicks on any user profile
    ProfileStore.fetchWellnessPlanFormByUser();
  };

  componentWillUnmount() {
    this.setState({
      isWellnessPlanEdit: false,
    });
  };

  editPress = () => {
    const { isWellnessPlanEdit } = this.state;
    // When admin clicks on edit icon then we need to change its state
    this.setState({
      ...this.state,
      isWellnessPlanEdit: !isWellnessPlanEdit
    });
  };

  submitWellnessPlanForm = () => {
    // When admin clicks on submit button then we need to change its state and set it to false
    this.editPress();
    // Submit form questions answers
    const {
      store: {
        ProfileStore,
      }
    } = this.props;
    ProfileStore.submitWellnessPlanFormByUser();
  };

  changeAnswerOfQuestion = (event, questionId) => {
    const answerValue = event?.target?.value;
    const {
      store: { ProfileStore },
    } = this.props;
    // Change answer when admin types any answer in text area according to question id
    ProfileStore.setAnswerForSpecificQuestion(questionId, answerValue);
  };

  renderQuestionsByCategory = (categoryName, questions = []) => {
    const { isWellnessPlanEdit } = this.state;
    const {
      store: {
        ProfileStore: {
          answersForQuestions
        },
      },
    } = this.props;
    // Here we are taking answersForQuestions obj state from mobx after preparing question & answer from api
    const answersObj = toJS(answersForQuestions);

    // Here we will render questions by category with its given answers
    return (
      <div className="category-questions-container">
        <label className="form-category-label">{categoryName}</label>
        <div className="form-questions-wrapper">
          {questions.map((questionObj, index) => (
            <div className="form-question-inner-wrapper" key={questionObj.id}>
              <span className="form-question-title">{questionObj.questionTitle}</span>
              <br />
              {isWellnessPlanEdit ? (
                <textarea
                  className="question-answer-textarea"
                  data-field="newNotes"
                  onChange={(event) => this.changeAnswerOfQuestion(event, questionObj.id)}
                  value={answersObj[questionObj.id]}
                  placeholder="--Not Answered--"
                />
              ) : (
                <div className="wellness-answers-wrapper">
                  <span className="answer-heading">{questionObj.answer}</span>
                </div>
              )}
            </div>
          ))}
        </div>

      </div>)
  };

  renderWellnessFormQuestions = (wellnessFormQuestionsByCategory = {}) => {
    const { goals = [], strengths = [], stressCrisisPrevention = [] } = wellnessFormQuestionsByCategory;
    // Here we will take goals, strengths & stressCrisisPrevention and render its questions with category on label
    return (<>
      {goals && goals.length ? this.renderQuestionsByCategory(userProfile.goals, goals) : null}

      {strengths && strengths.length ? this.renderQuestionsByCategory(userProfile.strengths, strengths) : null}

      {stressCrisisPrevention && stressCrisisPrevention.length ? this.renderQuestionsByCategory(userProfile.stressCrisisPrevention, stressCrisisPrevention) : null}
    </>);
  };

  render() {
    const {
      store: {
        AuthStore,
        ProfileStore: {
          wellnessFormLoading,
          wellnessFormQuestionsByCategory
        },
      },
    } = this.props;
    const { isWellnessPlanEdit } = this.state;
    const isSuperAdmin = AuthStore.type === "SA" || AuthStore.type === DEV ? true : false;
    const isAdmin = AuthStore.type === "moderator" ? true : false;

    return (
      <React.Fragment>
        <div className="profile-edit">
          <div className="profile-type">{userProfile.wellnessPlanForm}</div>
          {
            isWellnessPlanEdit ?
              (<div className="edit-buttons">
                <div
                  className="cancel-button"
                  edit="editClinical"
                  onClick={this.editPress}
                >
                  {userProfile.cancel}
                </div>
                <Button
                  type="primary"
                  title="Submit"
                  className="submit-button"
                  size="large"
                  edit="editClinical"
                  onClick={this.submitWellnessPlanForm}
                >
                  {userProfile.submit}
                </Button>
              </div>) :
              (isAdmin || isSuperAdmin) ?
                (<img
                  src={IMG_EDIT}
                  className="profile-edit-image"
                  onClick={this.editPress}
                  alt="Edit"
                  edit="editClinical"
                />)
                : null
          }

        </div>

        <div className="wellness-form-container">
          {wellnessFormLoading ?
            <div className="wellness-form-loader">
              <Spin />
            </div>
            : (wellnessFormQuestionsByCategory && this.renderWellnessFormQuestions(wellnessFormQuestionsByCategory))}
        </div>

      </React.Fragment>
    );
  }
};

export default WellnessForm;