import { Provider, observer } from "mobx-react";
import React, { Component } from "react";
import { Redirect, Route, Router, Switch, withRouter } from "react-router-dom";

import Protected from "./routes/Protected";
import Public from "./routes/Public";
import UserProtected from "./routes/UserProtected";
import history from "./utils/history";
import NetworkDetector from "./utils/NetworkDetector";
import store from "./stores";
import { DEV } from "./constants/UserRolesConstant";

var mixpanel = require("mixpanel-browser");

@withRouter
@observer
class App extends Component {
  componentDidMount() {
    mixpanel.track("App Opened", { from: "WEB" });
  }
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={Public} />
            <Route exact path="/forgot-password" component={Public} />
            <Route exact path="/change-password" component={Public} />
            <Route exact path="/sign-up" component={Public} />
            <Route exact path="/csm/:data+" component={Public} />
            <Route exact path="/signup-success" component={Public} />
            <Route
              exact
              path="/:view+"
              component={
                store.AuthStore.type === "moderator" ||
                  store.AuthStore.type === "SA" ||
                  store.AuthStore.type === DEV ||
                  store.AuthStore.type === "FD" ||
                  store.AuthStore.type === "NOA"
                  ? Protected
                  : UserProtected
              }
            />
            {store.AuthStore.type === "moderator" ? (
              <Redirect to="/members" />
            ) : (
              <Redirect to="/members" />
            )}
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default NetworkDetector(App);
