import { inject, observer } from "mobx-react";

import WaitingLeftSide from "./WaitingLeftSide";
import SVG from "react-inlinesvg";
import React from "react";
import flagFilled from "../../static/images/flagFilled.svg";
import WaitingSection from "./WaitingSection";
import UserDetails from "./UserDetails";
import flag from "../../static/images/flag.svg";
import close from "../../static/images/close.png";

@inject("store")
@observer
class Waiting extends React.Component {
  async componentDidMount() {
    const {
      store: { MemberListStore, ProfileStore, SiteStore },
    } = this.props;
    ProfileStore.getGroupDetails();
    ProfileStore.changeSelectedAction("");
    ProfileStore.getProfileQuestions();
    MemberListStore.emptyAlert();
    let siteId = SiteStore.selectedSiteId;
    if (siteId !== 0 && siteId !== -1) {
      const siteDataObj = siteId > 0 ? { siteId } : { ...SiteStore.getSiteDataObj() };
      MemberListStore.fetchUnResolvedMembers(siteDataObj);
      MemberListStore.fetchResolvedMembers(siteDataObj);
    } else {
      MemberListStore.fetchUnResolvedMembers();
      MemberListStore.fetchResolvedMembers();
    }
    if (!SiteStore.allSites.length) {
      SiteStore.fetchAllSites();
    }
  }
  changeFlagValue = (value) => {
    const {
      store: {
        ProfileStore: { toggleFlag },
      },
    } = this.props;
    toggleFlag(value);
  };

  openWaitingChat = async (member) => {
    const {
      store: {
        MessagesStore: { setIsWaiting },
        BroadcastStore: { startWaitingChat },
        ProfileStore: { setSelectedWaiting, setVisibility },
        FormMessageStore: { changeformsVisible },
      },
    } = this.props;
    await setSelectedWaiting(null);
    await setIsWaiting(false);
    changeformsVisible(false);
    setSelectedWaiting(member.id);
    startWaitingChat(member.username, member.id);
    this.handleCloseProfile();
    setVisibility(false);
  };

  handleCloseProfile = () => {
    const {
      store: {
        ProfileStore: { reset },
      },
    } = this.props;
    reset();
  };

  render() {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { name },
          isWaiting,
        },
        ProfileStore: {
          profileVisible,
          username,
          userId,
          selectedAction,
          showFlag,
        },
      },
    } = this.props;

    return (
      <div className="d-flex">
        <div className="left-alert-sidebar">
          <WaitingLeftSide openWaitingChat={this.openWaitingChat} />
        </div>
        {name && isWaiting ? <WaitingSection /> : null}
        {profileVisible && selectedAction === "" ? (
          <div className="right-section center">
            <div className="header-section assign-padding">
              <div className="profile-name">
                <span>
                  {alert.profileOf}{" "}
                  {username.length > 30
                    ? `${username.slice(0, 30)}...`
                    : username}
                </span>
                <button
                  className="flag-resolve-button"
                  onClick={() => this.openDM(username, userId)}
                >
                  {alert.dm}
                </button>
              </div>
              <div className="flag-actions-container">
                {showFlag ? (
                  <div
                    className="actions-container"
                    onClick={() => this.changeFlagValue(false)}
                  >
                    <SVG src={flagFilled}></SVG>
                    Hide Flags
                  </div>
                ) : (
                  <div
                    className="actions-container"
                    onClick={() => this.changeFlagValue(true)}
                  >
                    <SVG src={flag}></SVG>
                    Show Flags
                  </div>
                )}
                <img
                  src={close}
                  onClick={() => this.handleCloseProfile()}
                  alt="Close"
                />
              </div>
            </div>

            <div className="d-flex">
              <div style={{ flex: "1", position: "relative" }}>
                <div className="d-flex flex-column h-100">
                  <div id="alert-display-container">
                    <UserDetails allMembers={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default Waiting;
