import moment from "moment";
import { REWARD_STATUS } from "../constants/GlobalConstant";
import CryptoJS from "crypto-js";
import SecureLocalStorage from "./SecureLS";

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getFormattedTime = (number) => {
    if (number) {
        const hours = Math.floor(number / 60);
        const minutes = number % 60;
        return `${appendZeroIfLessThanTen(hours)} : ${appendZeroIfLessThanTen(minutes)}`;
    }
    return '';
};

const getDateWithMonthName = (date) => {
    return `${monthNames[date.getMonth()]} ${date.getDate()}`;
};

const appendZeroIfLessThanTen = (number) => {
    return number < 10 ? `0${number}` : number
};

const getDateInSpecificFormat = (date) => {
    const currentDate = new Date(`${date}`);
    return `${appendZeroIfLessThanTen(currentDate.getMonth() + 1)}/${appendZeroIfLessThanTen(currentDate.getDate())}/${currentDate.getFullYear()}`;
};

const getWeekRanges = (isPastDateFlag = false) => {
    const date = new Date();
    date.setHours(0, 0, 0);
    if (isPastDateFlag) {
        date.setDate(date.getDate() - 7);
    }

    const currentDate = date.getDate();
    const currentDay = date.getDay();
    const lessDays = currentDay == 0 ? 6 : currentDay - 1;

    const weekStartDate = new Date(new Date(date).setDate(currentDate - lessDays));
    const weekEndDate = new Date(new Date(weekStartDate).setDate(weekStartDate.getDate() + 6));

    const firstDayOfWeekWithMonthName = getDateWithMonthName(weekStartDate);
    const lastDayOfWeekWithMonthName = getDateWithMonthName(weekEndDate);

    return `${firstDayOfWeekWithMonthName} - ${lastDayOfWeekWithMonthName}`;
};

const getWeekRangesFromDay = (daysToSubtract = 0) => {
    const startDateOfWeek = moment().startOf('week').subtract(daysToSubtract, 'days').format('MMM DD');
    const endDateOfWeek = moment().endOf('week').subtract(daysToSubtract, 'days').format('MMM DD');
    return `${startDateOfWeek} - ${endDateOfWeek}`;
};

const getMonthName = (monthsToSubtract = 0, formatType = 'MMMM') => {
    return moment().subtract(monthsToSubtract, 'month').format(formatType);
};

const getMonthRanges = (monthsToSubtract = 0, formatType = 'MMM Do', monthName = '') => {
    if (monthName) {
        const startDateOfMonth = moment().month(monthName).startOf('month').format(formatType);
        const endDateOfMonth = moment().month(monthName).endOf('month').format(formatType);

        return {
            startDateOfMonth,
            endDateOfMonth
        };
    }
    const startDateOfMonth = moment().subtract(monthsToSubtract, 'months').startOf('month').format(formatType);
    const endDateOfMonth = moment().subtract(monthsToSubtract, 'months').endOf('month').format(formatType);

    return {
        startDateOfMonth,
        endDateOfMonth
    };
};

const getLast3MonthRanges = (data = {}) => {
    const { startMonth = 0, endMonth = 0, formatType = 'YYYY-MM-DD' } = data;
    const startDateOfMonth = moment().subtract(startMonth, 'months').startOf('month').format(formatType);
    const endDateOfMonth = moment().subtract(endMonth, 'months').endOf('month').format(formatType);

    return {
        startDateOfMonth,
        endDateOfMonth
    };
};

const getFormattedDate = (date, formatType = 'MMM Do YYYY', originalDateFormat = null) => {
    // We have this problem without defining original date format 😢:- 
    // value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions. Non RFC2822/ISO date formats are discouraged
    // Now we have added a variable to convert according to "originalDateFormat" and it fixed the problem 😎
    if (originalDateFormat) {
        return moment(date, originalDateFormat).format(formatType);
    }
    return moment(date).format(formatType);
};

const getRandomNumber = () => {
    return Math.floor(100 + Math.random() * 900);
};

const convertStringToCamelCase = (str = '', splitType = '-') => {
    return str.split(splitType).map((value, index) => {
        return index && value.length
            ? value[0].toUpperCase() + value.substring(1)
            : value;
    }).join('');
};

const generateTitleCase = (str = '') => {
    if (str) {
        const lowercaseStr = str.toLowerCase();
        return lowercaseStr.charAt(0).toUpperCase() + lowercaseStr.slice(1);
    }
    return str;
};

// Here function is used to prepare a string of only first letter from full string  i.e. -> Java Script Object Notation will be JSON
const getCombinedStringOfOnlyFirstLettersFromString = (str = '') => {
    let combinedString = '';
    // If str is available then we will perform some operation
    if (str) {
        // Extract the first letters from full string and create a array of only first letters for example Java Script Object Notation will be ['J','S','O','N'].
        const firstLettersArr = str.match(/\b(\w)/g);
        // Here we will convert the array of first letters to full string using join method
        combinedString = firstLettersArr.join('');
    }
    return combinedString;
};

const getSortedDataByDateSection = (data = [], keyToSort = 'createdAt') => {
    if (data && data.length) {
        const allDataObjects = data.reduce((allData, obj) => {
            const date = moment(obj[keyToSort]).format('YYYY-MM-DD');
            if (!allData[date]) {
                allData[date] = [];
            }
            allData[date].push(obj);
            return allData;
        }, {});
        const allSortedObjects = Object.keys(allDataObjects).map((date, index) => {
            let formattedDate = getFormattedDate(date);
            if (moment(date).isSame(moment(), 'day')) {
                formattedDate = 'Today';
            } else if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
                formattedDate = 'Yesterday';
            }
            return { index, title: formattedDate, data: allDataObjects[date] };
        });
        return allSortedObjects;
    }
    return data;
};

const getSortedDMsByDateSection = (dmsData = [], isDMsWithNoMessageRequired = false) => {
    const dmsDataWithNoMessages = [];
    const dmsDataWithMessages = [];
    const resolvedDMsData = [];

    for (const dmObj of dmsData) {
        const { message, timetoken, isResolved } = dmObj;
        if (isResolved) {
            resolvedDMsData.push(dmObj);
        } else if (message && timetoken) {
            dmsDataWithMessages.push(dmObj);
        } else {
            dmsDataWithNoMessages.push(dmObj);
        }
    }
    const sortedDMsWithMessagesData = getSortedDataByDateSection([...dmsDataWithMessages], 'timetoken');
    const sortedDMs = [...sortedDMsWithMessagesData];
    if (isDMsWithNoMessageRequired && dmsDataWithNoMessages.length) {
        sortedDMs.push({
            index: sortedDMs.length,
            title: 'Other DMs',
            data: [...dmsDataWithNoMessages]
        });
    }
    if (resolvedDMsData.length) {
        sortedDMs.push({
            index: sortedDMs.length,
            title: 'Resolved DMs',
            data: [...resolvedDMsData]
        });
    }
    return sortedDMs;
};

const getAllTerritoriesBySites = (sitesData = {}) => {
    let territoriesData = [];
    if (sitesData) {
        territoriesData = [...new Set(Object.values(sitesData).map(site => site.territory))].sort();
        return territoriesData;
    }
    return territoriesData;
};

const arraySortingFunction = (data = {}) => {
    const { isAscendingOrder = false, isObjectAvailableInArray = false, keyToSort = '' } = data;
    const isConditionMatchedForArrObject = isObjectAvailableInArray && keyToSort ? true : false;
    return (a, b) => {
        const valueOfA = isConditionMatchedForArrObject ? a[keyToSort] : a;
        const valueOfB = isConditionMatchedForArrObject ? b[keyToSort] : b;
        if (valueOfA === valueOfB) {
            return 0;
        }

        // nulls sort after anything else
        if (!valueOfA) {
            return 1;
        }
        if (!valueOfB) {
            return -1;
        }

        // otherwise, if we're ascending, lowest sorts first
        if (isAscendingOrder) {
            return valueOfA < valueOfB ? -1 : 1;
        }

        // if descending, highest sorts first
        return valueOfA < valueOfB ? 1 : -1;
    };
};

const getSortedRecordsWithNullLast = (data = {}) => {
    const { originalArr = [], keyToSort = '', sortOrder = 'asc', isObjectAvailableInArray = false } = data;

    if (originalArr && originalArr.length) {
        const isAscendingOrder = sortOrder === 'asc';
        originalArr.sort(arraySortingFunction({ isAscendingOrder, isObjectAvailableInArray, keyToSort }));
        return originalArr;
    }
    return originalArr;
};

const checkDate = (timestamp) => {
    if (timestamp) {
        if (moment(timestamp).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) { // check if today
            return `(Today) at ${moment(timestamp).format('HH:mm A')}`;
        } else if (moment(timestamp).format('DD-MM-YYYY') === moment().add(-1, 'days').format('DD-MM-YYYY')) { // check if yesterday
            return `(Yesterday) at ${moment(timestamp).format('HH:mm A')}`;
        } else { // return date string
            return moment(timestamp).format('MMM DD, YYYY HH:mm A');
        }
    }
}

const checkDateForComment = (timestamp) => {
    if (timestamp) {
        if (moment(timestamp).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) { // check if today
            return `Today at ${moment(timestamp).format('hh:mm A')}`;
        } else if (moment(timestamp).format('DD-MM-YYYY') === moment().add(-1, 'days').format('DD-MM-YYYY')) { // check if yesterday
            return `Yesterday at ${moment(timestamp).format('hh:mm A')}`;
        } else { // return date string
            return moment(timestamp).format('MMM DD, YYYY hh:mm A');
        }
    }
}

const formateDate = (dateString, toDisplay) => {
    return moment(dateString).format(toDisplay ? 'MM/DD/YYYY' : 'YYYY-MM-DD');
}

// this escapes all inner double quotes within the string
const jsonQuotes = (json) => {
    let n = json.length;
    let temp = new Array(n);

    for (let i = 0; i < n; i++) {
        temp[i] = json[i];
    }

    for (let i = 0; i < n; i++) {
        if (temp[i] == ':' && temp[i + 1] == '"') {
            for (let j = i + 2; j < n; j++) {
                if (temp[j] == '"') {
                    if (temp[j + 1] != ',' && temp[j + 1] != '}' && temp[j - 1] != '\\') {
                        temp[j] = '\\"';
                    } else if (temp[j + 1] == ',' || temp[j + 1] == '}') {
                        break;
                    }
                }
            }
        }
    }

    return temp.join("");
}

// escape new line and double quotes
const jsonEscape = (str) => {
    let temp = str;
    temp = temp.replace(/\n/g, "\\n");
    // this escapes all inner double quotes within the string
    temp = jsonQuotes(temp);
    return temp;
}

// This function is used to generate some final string based on usernames array and initial string
// The Queue message output will look like this :- 'Queue message between <username> and marigold team'
// The Direct message output will look like this :- 'Direct message between <username1> and <username2>'
const generateTouchpointChannelStringForDocument = (touchpointData = {}) => {
    const { initialStr, usernamesArr = [] } = touchpointData;
    // Initially setting the initial str value to finalStr variable 
    let finalStr = `${initialStr} `;
    const totalLengthOfUsernames = usernamesArr.length;
    // If total username counts in array are greater than 2 then we will append whole string with comma & and by iterating over it
    if (totalLengthOfUsernames > 2) {
        finalStr += 'among ';
        for (const [index, username] of usernamesArr.entries()) {
            if (index === totalLengthOfUsernames - 1) {
                finalStr += ` and ${username}`;
            } else if (index === totalLengthOfUsernames - 2) {
                finalStr += `${username}`
            } else {
                finalStr += `${username}, `;
            }
        }
    } else {
        // If total username counts in array are less than or equal to 2 then we will simply append and between two usernames
        finalStr += `between ${usernamesArr.join(' and ')} `;
    }
    return finalStr;
};

const getDateWithClientOffset = (date, offset) => {
    // Retrieve the database-stored date
    const dbDate = new Date(date);
    // Get the frontend client offset in minutes
    const dbDateWithClientOffset = new Date(dbDate.getTime() - offset * 60000);
    // Set the hours, minutes, seconds, and milliseconds of both dates to zero
    dbDateWithClientOffset.setHours(0, 0, 0, 0);
    return dbDateWithClientOffset;
}

const checkIsActive = (item, offset) => {
    const { startedAt, expiredAt, status, title } = item
    if (status == REWARD_STATUS.IN_PROGRESS) {
        const serverDate = new Date();
        serverDate.setHours(0, 0, 0, 0);
        //daily and weekly bonus
        if (startedAt && expiredAt) {
            //current date must in start and end
            const clientStartAtTime = getDateWithClientOffset(startedAt, offset);
            const clientExpireAtTime = getDateWithClientOffset(expiredAt, offset);
            if (clientStartAtTime.getTime() <= serverDate.getTime() && serverDate.getTime() <= clientExpireAtTime.getTime()) {
                return true;
            } else {
                return false;
            }
        } else if (startedAt && !expiredAt) {
            //barc10 and wellnesss
            const clientStartAtTime = getDateWithClientOffset(startedAt, offset);
            if (clientStartAtTime <= serverDate) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
}

const isValidReplyingToMessage = (message) => {
    return message && !isMessageDeleted(message) && isValidMessageType(message);
}

const isMessageDeleted = (message) => message.isDeleted ?? false;

const isValidMessageType = (message) => {
    const { type, text } = message.entry ?? message;
    const isImg = type === "img";
    const isGif = type === "gif";
    const isAdd = type === "add";
    return (type === "text" && text !== "") || isImg || isGif || isAdd;
}

const isUrlValid = (url) => {
    const res = url.match(/^(https:\/\/)?(([-a-zA-Z0-9_]){1,63}\.){0,10}([-a-zA-Z0-9_]){1,63}(\.[a-z]{2,20})([\/][-a-zA-Z0-9@:%_\+.~#?&\/=]*)?$/gmi);
    if (res == null)
        return false;
    else
        return true;
};

const encryptData = (data) => CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();

const decryptData = (encrypted) => {
    const bytes = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted);
};

const convertDateAndTime = (date, days = 5) => {
    const currentData = moment();
    const previousDate = moment(date);
    const milliseconds = currentData.diff(previousDate);
    const minutes = milliseconds / 60000; // Milliseconds to minutes
    switch (true) {
        case minutes < 1:
            return 'few seconds ago';
        case 60 > minutes && 1 <= minutes:
            const floorMinutes = Math.floor(minutes);
            return `${floorMinutes} ${floorMinutes === 1 ? 'minute' : 'minutes'} ago`;
        case 1440 > minutes && 60 <= minutes:
            return `${Math.floor(minutes / 60)} hours ago`; // Minutes to hours
        case days * 1440 > minutes && 1440 <= minutes:
            return `${Math.floor(minutes / 1440)} days ago`; // Minutes to days
        default:
            const year = moment(date).format('YYYY');
            const currentYear = moment(date).format('YYYY');
            return `${moment(date).format('MMMM Do')} ${currentYear === year ? '' : year}`;
    }
};

const getPhoneNumberWithoutCountryCode = (phoneNumber = "") => {
    if (!phoneNumber.startsWith("+")) {
        return phoneNumber;
    }

    if (phoneNumber.startsWith("+1")) {
        return phoneNumber.slice(2);
    }

    if (phoneNumber.startsWith("+91")) {
        return phoneNumber.slice(3);
    }

    console.warn(`getPhoneNumberWithoutCountryCode: phoneNumber with unhandled country code received: ${phoneNumber}`);
    return phoneNumber;
};

const getNormalizedPhoneNumber = (phoneNumber = "") => {
    const normalizedNumber = phoneNumber.replace(/[()\-\s]/g, "");
    return getPhoneNumberWithoutCountryCode(normalizedNumber);
};

const getFormattedPhoneNumber = (phoneNumber = "") => {
    if (!phoneNumber) {
        return "-";
    }
    
    if (phoneNumber.startsWith("(")) {
        return phoneNumber;
    }
    
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
};

const getLocalDateTime = (date) => {
    const dateUtc = new Date(date);
    const offsetMinutes = dateUtc.getTimezoneOffset();
    const offsetMilliseconds = offsetMinutes * 60 * 1000;
    const dateLocal = new Date(dateUtc.getTime() + offsetMilliseconds);
    const isoDateString = dateLocal.toISOString();
    return isoDateString;
}

function getFromSecureLS (key) {
    try {
        return SecureLocalStorage.get(key);
    } catch (e) {
        return localStorage.getItem(key);
    }
}

export {
    getFormattedTime,
    getWeekRanges,
    getDateInSpecificFormat,
    getWeekRangesFromDay,
    getMonthName,
    getMonthRanges,
    getLast3MonthRanges,
    getFormattedDate,
    getRandomNumber,
    convertStringToCamelCase,
    generateTitleCase,
    getCombinedStringOfOnlyFirstLettersFromString,
    getSortedDataByDateSection,
    getAllTerritoriesBySites,
    getSortedRecordsWithNullLast,
    getSortedDMsByDateSection,
    checkDate,
    checkDateForComment,
    formateDate,
    jsonEscape,
    generateTouchpointChannelStringForDocument,
    getDateWithClientOffset,
    checkIsActive,
    isValidReplyingToMessage,
    isUrlValid,
    convertDateAndTime,
    decryptData,
    encryptData,
    getNormalizedPhoneNumber,
    getFormattedPhoneNumber,
    getLocalDateTime,
    getFromSecureLS,
};
