import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Select, DatePicker, Input } from 'antd';
import { inject, observer } from "mobx-react";

import { IMG_DOWN_ARROW, IMG_UP_ARROW } from '../../utils/ImageUtils';
import { DEV, MODERATOR, SA } from '../../constants/UserRolesConstant';
import {
    AVAILABILITY_BY_TIME_OF_DAY_MAPPING,
    AVAILABILITY_BY_TIME_OF_DAY_TYPES,
    AVAILABILITY_BY_TIME_OF_DAY_TYPES_ARR,
    AVAILABILITY_BY_TYPE_OF_DAY_TYPES_ARR,
    GENDER_MAPPING,
    GENDER_TYPES_ARR,
    MODALITY_TYPES_ARR,
    PRONOUNS_TYPES_ARR,
    SUPPORT_TYPES_ARR
} from "../../constants/GlobalConstant";
import { getFormattedDate } from '../../utils/CommonUtils';
const { Option } = Select;

// Regex to validate input value would be number only
const inputNumberRegex = /^\d+$/;
// Regex to validate input value would be only letters only, no special characters or number allowed
const inputTextRegex = /^[a-zA-Z ]+$/;

const UserAdditionalDetails = inject("store")(observer((props) => {
    let {
        store: {
            ProfileStore: {
                editClinical,
                changePreviousValue,

                supportType,
                supportDescription,
                preferredPronouns,
                preferredName,
                gender,
                dischargeDate,
                availabilityByTimeOfDay,
                availabilityByTypeOfDay,
                typeOfContact,
                emergencyContactName,
                emergencyContactPhoneNumber,

                newSupportType,
                newSupportDescription,
                newPreferredPronouns,
                newPreferredName,
                newGender,
                newDischargeDate,
                newAvailabilityByTimeOfDay,
                newAvailabilityByTypeOfDay,
                newEmergencyContactName,
                newEmergencyContactPhoneNumber,
            },
            AuthStore: { type }
        } } = props;
    // Here we're checking if user type is "moderator" or "SA"
    const isSuperAdmin = type === SA || type === DEV;
    const isAdmin = type === MODERATOR;
    // If user type is "moderator" or "SA" then and edit button is preseed then we're allowing that admin type to edit the form
    const isEditConditionMatched = (isSuperAdmin || isAdmin) && editClinical ? true : false;

    // Here we're maintaining local states for modality
    const [modality, setModality] = useState([]);
    // Here we need to create different array for active modality and inactive modality
    const [activeModality, setActiveModality] = useState([]);
    const [inactiveModality, setInactiveModality] = useState([]);
    // Here we need to store discharge date
    const [selectedDischargeDate, setSelectedDischargeDate] = useState(null);
    // Here we're managing maximize flag to show/hide user additional section
    const [isMaximize, setIsMaximize] = useState(true);

    useEffect(() => {
        // When value of type of contact changes 
        if (typeOfContact) {
            // Here we're getting typeOfContact as a string like "Phone,Chat" so we will split it by comma
            const modality = typeOfContact.split(',');
            // Here we'll filter out which value is selected and which is not based on predefined array
            const missingModality = MODALITY_TYPES_ARR.filter(value => !modality.includes(value));
            const availableModality = MODALITY_TYPES_ARR.filter(value => modality.includes(value));
            // We'll set the values to our state
            setActiveModality(availableModality);
            setInactiveModality(missingModality);
            setModality(modality);
        }
    }, [typeOfContact]);

    // Here we're checking input type would be text or number
    const validateTextField = (event, keyName, fieldType = 'number') => {
        const { value } = event.target;
        if (value === '' || (fieldType === 'number' && inputNumberRegex.test(value))) {
            handleChange(event, keyName);
        } else if (value === '' || (fieldType === 'text' && inputTextRegex.test(value))) {
            handleChange(event, keyName);
        }
    };

    // Here we'll handle the change of text input field
    const handleChange = (event, keyName) => {
        changePreviousValue(keyName, event.target.value);
    };

    // Here we'll handle the change of dropdown field
    const handleDropdownChange = (keyName, value) => {
        changePreviousValue(keyName, value);
    };

    // Here we'll handle the change of multiple selected modality
    const updateModalityType = (value) => {
        setModality([...value]);
        changePreviousValue('newTypeOfContact', [...value].toString());
    };

    // Here we'll handle the change of discharge date
    const handleDateChange = (date, dateString) => {
        changePreviousValue('newDischargeDate', dateString);
        setSelectedDischargeDate(date);
    };

    newEmergencyContactPhoneNumber = newEmergencyContactPhoneNumber ?? "";
    const isEmergencyContactNumberValid = newEmergencyContactPhoneNumber === "" || (inputNumberRegex.test(newEmergencyContactPhoneNumber) && newEmergencyContactPhoneNumber.length === 10);

    return (<>
        <div className="user-additional-details-container">
            <div className="additional-details-header" onClick={() => setIsMaximize(!isMaximize)}            >
                <span className="header-title">Additional Information</span>
                <img src={isMaximize ? IMG_UP_ARROW : IMG_DOWN_ARROW} alt="expand" />
            </div>

            {isMaximize ?
                (<>
                    <div className="user-details-wrapper row bottom-border">
                        <div className="user-details-wrapper-inner">
                            <span className="subHeading">Preferred Pronouns:</span><br />
                            {isEditConditionMatched ?
                                (<div id="user-preferred-pronouns-container">
                                    <Select
                                        value={newPreferredPronouns || preferredPronouns || 'Select pronouns...'}
                                        style={{ width: '75%' }}
                                        onChange={(value) => handleDropdownChange('newPreferredPronouns', value)}
                                        getPopupContainer={() =>
                                            document.getElementById("user-preferred-pronouns-container")
                                        }
                                    >
                                        {PRONOUNS_TYPES_ARR.map((pronoun, index) => (
                                            <Option
                                                value={pronoun}
                                                key={index}
                                                disabled={newPreferredPronouns ? newPreferredPronouns === pronoun : preferredPronouns === pronoun}
                                            >
                                                {pronoun}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>) : (<span className="mainHeading">{preferredPronouns}</span>)
                            }
                        </div>

                        <div className="user-details-wrapper-inner">
                            <span className="subHeading">Preferred Name:</span><br />
                            {isEditConditionMatched ?
                                (<>
                                    <Input
                                        style={{ width: '75%' }}
                                        onChange={(event) => validateTextField(event, 'newPreferredName', 'text')}
                                        value={newPreferredName}
                                        placeholder="Enter preferred name..."
                                    />
                                </>) : (<span className="mainHeading">{preferredName}</span>)
                            }
                        </div>
                    </div>

                    <div className="user-details-wrapper row bottom-border">
                        <div className="user-details-wrapper-inner">
                            <span className="subHeading">Gender:</span><br />

                            {isEditConditionMatched ?
                                (<>
                                    <div id="user-gender-container">
                                        <Select
                                            value={newGender || gender || 'Select gender...'}
                                            style={{ width: '75%' }}
                                            onChange={(value) => handleDropdownChange('newGender', value)}
                                            getPopupContainer={() =>
                                                document.getElementById("user-gender-container")
                                            }
                                        >
                                            {GENDER_TYPES_ARR.map((genderType, index) => (
                                                <Option
                                                    value={genderType}
                                                    key={index}
                                                    disabled={newGender ? newGender === genderType : gender === genderType}
                                                >{GENDER_MAPPING[genderType]}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </>) : (<span className="mainHeading">{GENDER_MAPPING[gender]}</span>)
                            }
                        </div>

                        <div className="user-details-wrapper-inner">
                            <span className="subHeading">Discharge Date</span><br />
                            {isEditConditionMatched ? (
                                <DatePicker
                                    onChange={(date, dateString) => handleDateChange(date, dateString)}
                                    value={selectedDischargeDate ? moment(selectedDischargeDate) : newDischargeDate ? moment(newDischargeDate) : null}
                                    picker="month"
                                    format={'MM/DD/YYYY'}
                                />
                            ) : (<span className="mainHeading">{dischargeDate ? getFormattedDate(dischargeDate, 'MM/DD/YYYY') : null}</span>)}
                        </div>
                    </div>

                    <div className="user-details-wrapper row bottom-border">
                        <div className="user-details-wrapper-inner">
                            <span className="subHeading">What type of support are you looking for?</span><br />

                            {isEditConditionMatched ?
                                (<div id="user-support-type-container" style={{ display: 'flex', position: "relative" }}>
                                    <Select
                                        value={newSupportType || supportType || 'Select support type...'}
                                        style={{ width: '75%' }}
                                        onChange={(value) => handleDropdownChange('newSupportType', value)}
                                        getPopupContainer={() =>
                                            document.getElementById("user-support-type-container")
                                        }
                                    >
                                        {SUPPORT_TYPES_ARR.map((typeOfSupport, index) => (
                                            <Option
                                                value={typeOfSupport}
                                                key={index}
                                                disabled={newSupportType ? newSupportType === typeOfSupport : supportType === typeOfSupport}
                                            >{typeOfSupport}
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                ) : (<span className="mainHeading">{supportType}</span>)
                            }
                        </div>

                        <div className="user-details-wrapper-inner">
                            <span className="subHeading">What do you need the most help with?</span><br />
                            {isEditConditionMatched ? (<>
                                <textarea
                                    className="support-description-input"
                                    onChange={(event) => handleChange(event, 'newSupportDescription')}
                                    value={newSupportDescription}
                                    placeholder="Enter support you're looking for..."
                                />
                            </>) : (
                                <span className="mainHeading">{supportDescription}</span>
                            )}
                        </div>
                    </div>

                    <div className="user-details-wrapper bottom-border">
                        <span className="mainHeading">Emergency Contact Information:</span><br />
                        <div className="row-wrapper">
                            <div className="user-details-wrapper-inner">
                                {isEditConditionMatched ? (<>
                                    <span>Name:</span><br />
                                    <Input
                                        style={{ width: '75%', marginBottom: 20 }}
                                        placeholder="Enter emergency contact name..."
                                        onChange={(event) => validateTextField(event, 'newEmergencyContactName', 'text')}
                                        value={newEmergencyContactName}
                                    />
                                </>) : (
                                    <span className="name-wrapper">Name:<span className="mainHeading left-move"> {emergencyContactName}</span></span>
                                )}
                            </div>

                            <div className="user-details-wrapper-inner">
                                {isEditConditionMatched ? (<>
                                    <span>Number:</span><br />
                                    <Input
                                        style={{ width: '75%' }}
                                        placeholder="Enter emergency contact number..."
                                        maxLength={10}
                                        onChange={(event) => validateTextField(event, 'newEmergencyContactPhoneNumber')}
                                        value={newEmergencyContactPhoneNumber}
                                    />
                                      {!isEmergencyContactNumberValid &&
                                        <div style={{
                                            color: "red",
                                            marginTop: "3px"
                                        }}>Invalid Phone Number</div>}
                                </>
                                ) : (<span className="name-wrapper">Number:<span className="mainHeading left-move"> {emergencyContactPhoneNumber}</span></span>)}
                            </div>

                        </div>

                    </div>

                    <div className="user-details-wrapper row">
                        <div className="user-details-wrapper-inner">
                            <span className="mainHeading">Preferred modalities for communication</span><br />
                            {isEditConditionMatched ? (<>
                                <div id="user-modality-container">
                                    <Select
                                        mode='multiple'
                                        value={modality}
                                        style={{ width: '75%' }}
                                        placeholder="Select modality..."
                                        onChange={(value) => updateModalityType(value)}
                                        getPopupContainer={() =>
                                            document.getElementById("user-modality-container")
                                        }
                                    >
                                        {MODALITY_TYPES_ARR.map((modality, index) => (
                                            <Option value={modality} key={index}>{modality}</Option>
                                        ))}
                                    </Select>
                                </div>
                            </>) : (
                                <>
                                    {activeModality && activeModality.length ?
                                        (<>
                                            <span>You can contact with: <span className="modality-active">{activeModality.toString()}</span> </span>
                                            <br />
                                        </>) : null}
                                    {inactiveModality && inactiveModality.length ?
                                        (<span>You can't be contact with: <span className="modality-inactive">{inactiveModality.toString()}</span></span>) :
                                        null}
                                </>
                            )}
                        </div>

                        <div className="user-details-wrapper-inner">
                            <span className="subHeading">When are they available</span><br />
                            {isEditConditionMatched ? (
                                <>
                                    <div id="user-availability-time-container">
                                        <Select
                                            value={newAvailabilityByTimeOfDay || availabilityByTimeOfDay || 'Select time of day...'}
                                            style={{ width: '75%' }}
                                            onChange={(value) => handleDropdownChange('newAvailabilityByTimeOfDay', value)}
                                            getPopupContainer={() =>
                                                document.getElementById("user-availability-time-container")
                                            }
                                        >
                                            {AVAILABILITY_BY_TIME_OF_DAY_TYPES_ARR.map((availabilityTime, index) => (
                                                <Option
                                                    value={availabilityTime}
                                                    key={index}
                                                    disabled={newAvailabilityByTimeOfDay ? newAvailabilityByTimeOfDay === availabilityTime : availabilityByTimeOfDay === availabilityTime}
                                                >{AVAILABILITY_BY_TIME_OF_DAY_MAPPING[availabilityTime]}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <br />
                                    <div id="user-availability-type-container">
                                        <Select
                                            value={newAvailabilityByTypeOfDay || availabilityByTypeOfDay || 'Select type of day...'}
                                            style={{ width: '75%' }}
                                            onChange={(value) => handleDropdownChange('newAvailabilityByTypeOfDay', value)}
                                            getPopupContainer={() =>
                                                document.getElementById("user-availability-type-container")
                                            }
                                        >
                                            {AVAILABILITY_BY_TYPE_OF_DAY_TYPES_ARR.map((availabilityType, index) => (
                                                <Option
                                                    value={availabilityType}
                                                    key={index}
                                                    disabled={newAvailabilityByTypeOfDay ? newAvailabilityByTypeOfDay === availabilityType : availabilityByTypeOfDay === availabilityType}
                                                >
                                                    {availabilityType}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                </>
                            ) : (
                                <span className="mainHeading">{availabilityByTypeOfDay || null} {availabilityByTimeOfDay && availabilityByTimeOfDay !== AVAILABILITY_BY_TIME_OF_DAY_TYPES.NONE ? `especially in the ${availabilityByTimeOfDay}` : null}</span>
                            )}
                        </div>
                    </div>
                </>)
                : null}
        </div>
    </>);
}));

export default UserAdditionalDetails;
