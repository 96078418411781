import ApiService from "../utils/ApiService";
import { toJS } from "mobx";
import { types } from "mobx-state-tree";
import moment from "moment";
import PubNub from 'pubnub';

import {
    APP_HEALTH_USER_FLAGS,
    MIXPANEL_REPORT_EVENTS,
    FILTER_BY_ALL_FORMS_FLAG,
    PUBNUB_ENCRYPTION_KEY,
    SORTING_ORDERS,
    TERRITORIES_TYPE_ALL_TERRITORIES,
    USERS_WAU_DATA_FETCH_FLAGS_SITES,
    USERS_WAU_DATA_FETCH_FLAGS_TERRITORY,
    BADGE_TYPE_ENROLLED_CODE
} from "../constants/GlobalConstant";
import { USER_BADGES_ENROLLED_FLAGS, USER_BADGES_FLAGS } from "../constants/BadgeConstant";
import { IS_ISLAND, IS_ILLINOIS, IS_HP, IS_AMERI } from "../utils/getEnvironment";
import { getMonthName, getMonthRanges } from "../utils/CommonUtils";
import SiteStore from "../stores/SiteStore";
import SecureLocalStorage from "../utils/SecureLS";
import AuthStore from "../stores/AuthStore";
var mixpanel = require("mixpanel-browser");
const {
    ENROLLED_DELAWARE,
    ENROLLED_HP,
    ENROLLED_IHP,
    ENROLLED_STUDY,
    ENROLLED_WOODRIVER,
    ENROLLED_WOODRIVER_IHP,
    ENROLLED_ACDC,
    ENROLLED_CTC,
} = USER_BADGES_FLAGS;

const pubnub = new PubNub({
    publishKey: "",
    subscribeKey: "",
    authKey: "",
    useRandomIVs: false,
    userId: "web-instance-for-encrypt-decrypt",
});

const userBadgesMappingWithQTKeys = {
    [ENROLLED_DELAWARE]: 'usersQTDataForEnrolledDelaware',
    [ENROLLED_HP]: 'usersQTDataForEnrolledHp',
    [ENROLLED_IHP]: 'usersQTDataForEnrolledIhp',
    [ENROLLED_STUDY]: 'usersQTDataForEnrolledStudy',
    [ENROLLED_WOODRIVER]: 'usersQTDataForEnrolledWoodriver',
    [ENROLLED_WOODRIVER_IHP]: 'usersQTDataForEnrolledWoodriverIhp',
    [ENROLLED_ACDC]: 'usersQTDataForEnrolledAcdc',
    [ENROLLED_CTC]: 'usersQTDataForEnrolledCtc',
    default: 'usersQTDataForMembers'
};

const Stats = types
    .model("Stats", {
        loading: false,
        wauChartLoading: false,
        wauChartByTerritoriesLoading: false,
        statsTabKey: 1,
        filterBy: types.maybeNull(types.string),
        selectedMonthForEnrolledDelawareQT: types.maybeNull(types.string),
        selectedMonthForEnrolledHpQT: types.maybeNull(types.string),
        selectedMonthForEnrolledIhpQT: types.maybeNull(types.string),
        selectedMonthForEnrolledStudyQT: types.maybeNull(types.string),
        selectedMonthForEnrolledWoodriverQT: types.maybeNull(types.string),
        selectedMonthForEnrolledWoodriverIhpQT: types.maybeNull(types.string),
        selectedMonthForEnrolledAcdcQT: types.maybeNull(types.string),
        selectedMonthForEnrolledCtcQT: types.maybeNull(types.string),
        selectedMonthForMembersQT: types.maybeNull(types.string),

        selectedUserIdForCaseLoad: types.maybeNull(types.number),
        selectedUsernameForCaseLoad: types.maybeNull(types.string),
        selectedTerritoryForCaseLoad: types.maybeNull(types.string),
        selectedBadgeForCaseLoad: types.maybeNull(types.string),
        selectedIsEnrolledForCaseloads: types.maybe(types.boolean),
        selectedTerritoryForStatsUsers: types.maybeNull(types.string),
        allTerritories: types.array(types.string),
        statsData: types.array(types.frozen({
            eligibleUsersCount: types.maybe(types.number),
            enrolledUsersCount: types.maybe(types.number),
            notEligibleUsersCount: types.maybe(types.number),
            currentWeekUsersCount: types.maybe(types.number),
            pastWeekUsersCount: types.maybe(types.number),
        })),
        usersData: types.array(types.frozen({
            id: types.maybe(types.number),
            createdAt: types.maybe(types.string),
            notes: types.maybeNull(types.string),
            type: types.maybeNull(types.string),
            consentDoneAt: types.maybeNull(types.string),
            fullName: types.maybe(types.string),
            username: types.maybe(types.string),
            siteName: types.maybeNull(types.string),
            dmChannels: types.array(types.string),
            assignedPeerFullName: types.maybeNull(types.string),
            color: types.maybeNull(types.string),
            formCompletedAt: types.maybeNull(types.string),
            formReviewedAt: types.maybeNull(types.string),
            groups: types.array(types.string),
            thisMonthMessages: types.maybeNull(types.number),
            lastMonthMessages: types.maybeNull(types.number),
            indatorFlag: types.maybeNull(types.string),
            monthFlag: types.maybeNull(types.string),
            qtPoints: types.optional(types.frozen({})),
            barc10Date: types.optional(types.frozen({})),
        })),
        formsData: types.array(types.frozen({
            id: types.maybe(types.number),
            userId: types.maybe(types.number),
            formName: types.maybe(types.string),
            fullName: types.maybe(types.string),
            submittedFrom: types.maybe(types.string),
        })),
        userMessagesReportData: types.array(types.frozen({
            keyToDisplay: types.maybeNull(types.string),
            data: types.optional(types.frozen({
                overallData: types.optional(types.frozen({}))
            }))
        })),
        usersWauData: types.array(types.frozen({})),
        currentMonthRetentionUsers: types.array(types.frozen({})),
        pastMonthRetentionUsers: types.array(types.frozen({})),
        currentMonthUsersMessagedInCurrentMonth: types.maybeNull(types.number),
        pastMonthUsersMessagedInCurrentMonth: types.maybeNull(types.number),
        firstSectionThisWeekUsersData: types.array(types.frozen({})),
        firstSectionLastWeekUsersData: types.array(types.frozen({})),
        secondSectionLastWeekUsersData: types.array(types.frozen({})),
        secondSectionLastToLastWeekUsersData: types.array(types.frozen({})),
        firstSectionCommonUsersCount: types.maybeNull(types.number),
        secondSectionCommonUsersCount: types.maybeNull(types.number),
        overviewData: types.optional(types.frozen({
            annualGoal: types.optional(types.frozen({})),
            enrolled: types.optional(types.frozen({
                overview: types.optional(types.frozen({})),
                expanded: types.optional(types.frozen({
                    monthData: types.array(types.frozen({})),
                    weekData: types.array(types.frozen({}))
                }))
            })),
            engagement: types.optional(types.frozen({
                overview: types.optional(types.frozen({})),
                expanded: types.optional(types.frozen({
                    peerQTsBreakdown: types.array(types.frozen({})),
                }))
            })),
            outreach: types.optional(types.frozen({
                overview: types.optional(types.frozen({})),
                expanded: types.optional(types.frozen({
                    quarterlyData: types.array(types.frozen({})),
                }))
            })),
        })),
        usersQTsBreakdownData: types.optional(types.frozen({
            name: types.maybeNull(types.string),
            qt: types.maybeNull(types.number)
        })),
        usersWauDataBySites: types.optional(types.frozen({})),
        usersWauDataByTerritories: types.optional(types.frozen({})),
        usersQTDataForEnrolledDelaware: types.array(types.frozen({})),
        usersQTDataForEnrolledHp: types.array(types.frozen({})),
        usersQTDataForEnrolledIhp: types.array(types.frozen({})),
        usersQTDataForEnrolledStudy: types.array(types.frozen({})),
        usersQTDataForEnrolledWoodriver: types.array(types.frozen({})),
        usersQTDataForEnrolledWoodriverIhp: types.array(types.frozen({})),
        usersQTDataForEnrolledAcdc: types.array(types.frozen({})),
        usersQTDataForEnrolledCtc: types.array(types.frozen({})),
        usersQTDataForMembers: types.array(types.frozen({})),

        pagination: types.frozen({
            pageSize: 50,
            total: 0,
            current: 1,
        }),
        sortColumn: types.maybeNull(types.string),
        sortOrder: types.maybeNull(types.string),
        allTimeStats: types.optional(types.frozen([])),
        enrolledStats: types.array(types.frozen([])),
        isLoadingEnrolledStats: true,
        availableGoalsData: types.array(types.frozen({
            goal: types.maybe(types.number),
            endOfBarChart: types.maybe(types.number),
            id: types.maybe(types.number),
            badge: types.maybe(types.string),
        })),
        previewControl: types.array(types.frozen({})),
    })
    .actions((self) => ({
        async getUsersCount() {
            try {
                if (self.statsTabKey === 1) {
                    self.setLoading(true);
                    const response = await ApiService.getRequest("users-count");
                    const { success, data } = response;
                    if (success) {
                        const cloneArr = [...data.usersData];
                        self.setStatsData(cloneArr);
                    }
                    self.setLoading(false);
                }
            } catch (error) {
                self.setLoading(false);
            }
        },
        async getUsersList() {
            try {
                self.setLoading(true);
                const { statsTabKey, selectedTerritoryForStatsUsers, pagination: { pageSize: limit, current } } = self;
                const params = {
                    limit,
                    skip: (current - 1) * limit,
                    isAssignedPeerDetailsRequired: true,
                    isEnrolledPeerDetailsRequired: true
                };
                if (selectedTerritoryForStatsUsers && selectedTerritoryForStatsUsers !== TERRITORIES_TYPE_ALL_TERRITORIES) {
                    params['territory'] = selectedTerritoryForStatsUsers;
                }
                if (statsTabKey === 2) {
                    params['filterBy'] = 'enrolled';
                    params['isDMChannelsRequired'] = true;
                } else if (statsTabKey === 3) {
                    params['filterBy'] = 'eligible';
                } else {
                    params['filterBy'] = 'not-eligible';
                }

                const response = await ApiService.getRequest("users-list", params);
                const { success, data, meta } = response;
                if (success) {
                    const cloneArr = [...data];
                    self.setUsersData(cloneArr);
                    self.setPaginationData(self.parseMetaData(meta));
                }
                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async getFormsData() {
            try {
                const { statsTabKey, filterBy = FILTER_BY_ALL_FORMS_FLAG, pagination: { pageSize: limit, current } } = self;
                if (statsTabKey === 5) {
                    self.setLoading(true);
                    const params = {
                        include: true,
                        includeOnly: 'forms,users',
                        isSpecificKeysRequiredForUser: true,
                        isNeededUserSubmittedForms: true,
                        $limit: limit,
                        $skip: (current - 1) * limit,
                        '$sort[completedAt]': -1,
                        filterBy,
                        isAssignedPeerDetailsRequired: true,
                        isEnrolledPeerDetailsRequired: true,
                        source: 'WEB'
                    };

                    const response = await ApiService.getRequest("user-forms", params);
                    const { success, data, meta } = response;
                    if (success) {
                        const filterArr = self.modifyFormsDataArr(data);
                        self.setFormsData(filterArr);
                        self.setPaginationData(self.parseMetaData(meta));
                    }
                    self.setLoading(false);
                }
            } catch (error) {
                self.setLoading(false);
            }
        },
        modifyFirstArrByComparingWithOther(mainArr = [], compareArr = [], flagToAdd = '', isFirstSection = false) {
            const copyArr = [];
            let commonUsersCount = 0;
            for (const userObj of mainArr) {
                const { username } = userObj;
                const index = compareArr.findIndex(obj => obj.username === username);
                userObj['statusFlag'] = index > -1 ? APP_HEALTH_USER_FLAGS.COMMON : flagToAdd;
                copyArr.push({ ...userObj });
                commonUsersCount += index > -1 ? 1 : 0;
            }
            if (isFirstSection) {
                self.setFirstSectionCommonUsersCount(commonUsersCount);
            } else {
                self.setSecondSectionCommonUsersCount(commonUsersCount);
            }
            return copyArr.sort((a, b) => (a.statusFlag > b.statusFlag) ? 1 : (b.statusFlag > a.statusFlag) ? -1 : 0);
        },
        setFlagForCommonAndUniqueElementsOfArray(firstArr, secondArr, isFirstSection = false) {
            const thisWeekUsersData = self.modifyFirstArrByComparingWithOther(firstArr, secondArr, APP_HEALTH_USER_FLAGS.THIS_WEEK, isFirstSection);
            const lastWeekUsersData = self.modifyFirstArrByComparingWithOther(secondArr, firstArr, APP_HEALTH_USER_FLAGS.LAST_WEEK, isFirstSection);

            return { lastWeekUsersData, thisWeekUsersData };
        },
        modifyUsersWauDataForChart(usersWauData) {
            const finalArrForChart = [];
            for (let key in usersWauData) {
                const isKeyMatchedWithCurrentWeek = moment(key).format('YYYY-MM-DD') === moment().startOf('week').subtract(-1, 'days').format('YYYY-MM-DD');
                const isKeyMatchedWithLastWeek = moment(key).format('YYYY-MM-DD') === moment().startOf('week').subtract(6, 'days').format('YYYY-MM-DD');
                const value = usersWauData[key];
                const currentValue = isKeyMatchedWithCurrentWeek ? 0 : value;
                const predictedValue = isKeyMatchedWithCurrentWeek ? value : isKeyMatchedWithLastWeek ? value : 0;
                const tempObj = { name: moment(key).format('MMM DD YYYY'), date: key };
                if (isKeyMatchedWithLastWeek) {
                    tempObj['predictedValue'] = predictedValue;
                    tempObj['currentValue'] = currentValue;
                } else if (isKeyMatchedWithCurrentWeek) {
                    tempObj['predictedValue'] = predictedValue;
                } else {
                    tempObj['currentValue'] = currentValue;
                }
                finalArrForChart.push(tempObj);
            };
            return finalArrForChart.sort((a, b) => new Date(a.date) - new Date(b.date));
        },
        async getUsersWauData() {
            try {
                self.setLoading(true);
                const REPORT_USER_WAU_DATA = 'User WAU Data';

                const params = { 'report[]': REPORT_USER_WAU_DATA };

                const response = await ApiService.getRequest("mixpanel-reports", params);

                const { success, data } = response;
                if (success && data.length) {
                    const [usersWauData] = data;
                    const { jsonData } = usersWauData || {};
                    if (jsonData && Object.keys(jsonData).length) {
                        const wauDataForChart = self.modifyUsersWauDataForChart(jsonData);
                        self.setUsersWauData(wauDataForChart);
                    }
                }
                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async fetchAppHealthData() {
            try {
                self.setLoading(true);
                const params = {};
                for (const [index, reportEventObj] of MIXPANEL_REPORT_EVENTS.entries()) {
                    const { userMessagesDataEvent } = reportEventObj;
                    params[`report[${index}]`] = userMessagesDataEvent;
                }

                const response = await ApiService.getRequest("mixpanel-reports", params);

                const { success, data } = response;
                if (success && data.length) {
                    const userMessagesReportData = [];
                    let isCurrentSiteMatched = false;
                    let currentSiteDataObj = {};
                    for (const dataObj of data) {
                        const { name, jsonData } = dataObj;
                        const lowerCaseName = name.toLowerCase();
                        const siteNameToDisplay = name.split('-')[1].trim();
                        const keyToDisplay = siteNameToDisplay.replace(/([a-z])([A-Z])/g, '$1 $2');

                        if (!isCurrentSiteMatched &&
                            ((IS_ISLAND && lowerCaseName.includes('island'))
                                || (IS_ILLINOIS && lowerCaseName.includes('illinois'))
                                || (IS_HP && lowerCaseName.includes('newengland'))
                                || (IS_AMERI && lowerCaseName.includes('amerihealth')))) {
                            isCurrentSiteMatched = true;
                            currentSiteDataObj = jsonData ? { ...jsonData } : {};
                            userMessagesReportData.push({
                                keyToDisplay,
                                data: jsonData ? { ...jsonData } : {}
                            });
                        }

                        // We will enable it for all sites, as of now we only need current site data
                        // if (jsonData && Object.keys(jsonData).length) {
                        //     userMessagesReportData.push({
                        //         keyToDisplay,
                        //         data: { ...jsonData }
                        //     });
                        // }
                    }
                    if (isCurrentSiteMatched) {
                        const { thisWeekUsersData = [], lastWeekUsersData = [], lastToLastWeekUsersData = [] } = currentSiteDataObj;
                        const {
                            thisWeekUsersData: firstSectionThisWeekUsers,
                            lastWeekUsersData: firstSectionLastWeekUsers
                        } = self.setFlagForCommonAndUniqueElementsOfArray(thisWeekUsersData, lastWeekUsersData, true);
                        const {
                            thisWeekUsersData: secondSectionLastWeekUsers,
                            lastWeekUsersData: secondSectionLastToLastWeekUsers
                        } = self.setFlagForCommonAndUniqueElementsOfArray(lastWeekUsersData, lastToLastWeekUsersData, false);
                        self.setFirstSectionThisWeekUsersData(firstSectionThisWeekUsers);
                        self.setFirstSectionLastWeekUsersData(firstSectionLastWeekUsers);
                        self.setSecondSectionLastWeekUsersData(secondSectionLastWeekUsers);
                        self.setSecondSectionLastToLastWeekUsersData(secondSectionLastToLastWeekUsers);
                    }
                    self.setUserMessagesReportData(userMessagesReportData);
                }
                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async fetchOverviewData() {
            try {
                self.setLoading(true);
                const overviewDataUrl = 'https://app-25476.on-aptible.com';
                const body = { userid: "1E5A8B2421EDBC92C66EBC881D35F" };
                const response = await ApiService.postRequest("dashboard", body, true, overviewDataUrl);
                const { success, data } = response;
                if (success && data && Object.keys(data).length) {
                    // From the data, we are decrypting some information using pubnub methods & key
                    const decryptedData = pubnub.decrypt(data?.encryptedPacket, PUBNUB_ENCRYPTION_KEY);
                    if (decryptedData && decryptedData.length) {
                        const updatedDecryptedData = decryptedData.map(({ QT, ...rest }) => ({ ...rest, qt: QT }));
                        // We are storing users qt breakdown data to our mobx state and iterate it over overview page
                        self.setUsersQTsBreakdownData(updatedDecryptedData);
                    }
                    self.setOverviewData(data);
                }
                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async getRetentionData() {
            try {
                self.setLoading(true);
                const REPORT_USERS_RETENTION_DATA = 'Users Retention Data';

                const params = { 'report[]': REPORT_USERS_RETENTION_DATA };

                const response = await ApiService.getRequest("mixpanel-reports", params);

                const { success, data } = response;
                if (success && data.length) {
                    const [usersWauData] = data;
                    const { jsonData } = usersWauData || {};
                    if (jsonData && Object.keys(jsonData).length) {
                        const { currentMonthRetentionUsers, pastMonthRetentionUsers } = jsonData;
                        const pastMonthUsersMessagedInCurrentMonth = pastMonthRetentionUsers.filter(obj => obj.statusFlag === 'GREEN');
                        const currentMonthUsersMessagedInCurrentMonth = currentMonthRetentionUsers.filter(obj => obj.statusFlag === 'GREEN');
                        self.setCurrentMonthUsersMessagedInCurrentMonth(currentMonthUsersMessagedInCurrentMonth.length || 0);
                        self.setPastMonthUsersMessagedInCurrentMonth(pastMonthUsersMessagedInCurrentMonth.length || 0);
                        self.setCurrentMonthRetentionUsers(currentMonthRetentionUsers);
                        self.setPastMonthRetentionUsers(pastMonthRetentionUsers);
                    }
                }
                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        async fetchUsersWauDataBySites(paramsData = {}) {
            const { sitesArr = [], territory = [], fetchType = USERS_WAU_DATA_FETCH_FLAGS_SITES } = paramsData;
            // Check if fetch type is territory or not then we fetch its wau chart type
            const isWauChartNeededForTerritory = fetchType === USERS_WAU_DATA_FETCH_FLAGS_TERRITORY;
            try {
                if (isWauChartNeededForTerritory) {
                    self.setWauChartByTerritoriesLoading(true);
                } else {
                    self.setWauChartLoading(true);
                }
                // We will use this report to retrieve records from database and then return to the api response after doing some filtering
                let REPORT_USER_MESSAGES_DATA = 'User Messages Data - Island';
                if (IS_ILLINOIS) {
                    REPORT_USER_MESSAGES_DATA = 'User Messages Data - Illinois';
                } else if (IS_HP) {
                    REPORT_USER_MESSAGES_DATA = 'User Messages Data - NewEngland';
                } else if (IS_AMERI) {
                    REPORT_USER_MESSAGES_DATA = 'User Messages Data - AmeriHealth';
                }
                const params = { 'report': REPORT_USER_MESSAGES_DATA, };
                if (sitesArr && sitesArr.length) {
                    params['siteIds'] = sitesArr.toString();
                }
                if (territory && territory.length) {
                    for (const [index, territoryName] of territory.entries()) {
                        params[`territory[${index}]`] = territoryName;
                    }
                }
                // Fetch wau reports data according to territory or site ids
                const response = await ApiService.getRequest("wau-reports", params);
                const { success, data } = response;
                if (success && data && Object.keys(data).length) {
                    const wauDataForChart = self.modifyUsersWauDataForChart(data);
                    if (isWauChartNeededForTerritory) {
                        self.setUsersWauDataByTerritories(wauDataForChart);
                    } else {
                        self.setUsersWauDataBySites(wauDataForChart);
                    }
                }
                if (isWauChartNeededForTerritory) {
                    self.setWauChartByTerritoriesLoading(false);
                    return;
                }
                self.setWauChartLoading(false);
            } catch (error) {
                if (isWauChartNeededForTerritory) {
                    self.wauChartByTerritoriesLoading(false);
                } else {
                    self.setWauChartLoading(false);
                }
            }
        },
        async fetchCaseLoadData(userId, orderBy = null) {
            try {
                self.setLoading(true);
                const { pagination: { current, }, sortColumn, sortOrder, selectedTerritoryForCaseLoad, selectedBadgeForCaseLoad, selectedIsEnrolledForCaseloads } = self;
                const limit = 200;
                const params = {
                    skip: (current - 1) * limit,
                    limit
                };
                if (sortColumn && sortOrder && sortOrder !== 'unsorted') {
                    params['sortBy'] = sortColumn;
                    params['sortOrder'] = orderBy ? orderBy : SORTING_ORDERS[sortOrder] || null;
                }
                if (selectedTerritoryForCaseLoad && selectedTerritoryForCaseLoad !== TERRITORIES_TYPE_ALL_TERRITORIES) {
                    params['territory'] = selectedTerritoryForCaseLoad;
                }
                if (selectedBadgeForCaseLoad && selectedBadgeForCaseLoad != "AL") {
                    params['badgeType'] = selectedBadgeForCaseLoad;
                }
                if (selectedIsEnrolledForCaseloads) {
                    params['isEnrolled'] = selectedIsEnrolledForCaseloads;
                }
                const userID = userId === -1 ? 'NA' : (userId === -2 ? 'WP' : (userId === -3 ? 'EN' : userId))
                //  we will set NA as user id if Not Assigned is selected in case load dropdown otherwise user id will sent in api call
                const response = await ApiService.getRequest("users-list", params, userID);
                const { success, data, meta } = response;
                if (success) {
                    mixpanel.track("Caseload Filters Applied", { from: "WEB", peerId: userID, peerName: data[0]?.fullname, params, accessingPeername: AuthStore.username, accessingPeerId: AuthStore.userId });
                    const cloneArr = [...data];
                    self.setUsersData(cloneArr);
                    self.setPaginationData(self.parseMetaData(meta));
                }
                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        getTotalCountsForQTUsers(qtUsersData = []) {
            let totalQTCountForUsers = 0;
            let totalUsersCountInQT = 0;
            let totalUsersCountMoreThanTwoInQT = 0;
            let totalEnrolledUsersCount = 0;
            let totalEnrolledUsersCountMoreThanTwoInQT = 0;
            if (qtUsersData.length) {
                for (const userObj of qtUsersData) {
                    const { totalScore, badgeType } = userObj;
                    totalQTCountForUsers += totalScore;
                    const isTotalScoreGreaterThanTwo = totalScore >= 2;
                    if (isTotalScoreGreaterThanTwo) {
                        if (badgeType) {
                            totalEnrolledUsersCountMoreThanTwoInQT += 1;
                        }
                        totalUsersCountMoreThanTwoInQT += 1;
                    } else {
                        const finalCount = totalScore ? 1 : 0;
                        if (badgeType) {
                            totalEnrolledUsersCount += finalCount;
                        }
                        totalUsersCountInQT += finalCount;
                    }
                }
            }
            return {
                totalQTCountForUsers,
                totalUsersCountInQT,
                totalUsersCountMoreThanTwoInQT,
                totalEnrolledUsersCount,
                totalEnrolledUsersCountMoreThanTwoInQT
            };
        },
        async makeApiCallToFetchQTData(paramsData = {}) {
            try {
                const { badgeType, params = {}, monthName } = paramsData;
                // Make api call to fetch data of qualified touchpoint by user badges
                const response = await ApiService.getRequest("qualified-touchpoint", params);
                const { success, data } = response;
                if (success) {
                    const { firstMonth = [] } = data;

                    const {
                        totalQTCountForUsers,
                        totalUsersCountInQT,
                        totalUsersCountMoreThanTwoInQT,
                        totalEnrolledUsersCount,
                        totalEnrolledUsersCountMoreThanTwoInQT
                    } = self.getTotalCountsForQTUsers(firstMonth);

                    // Here data will be available in first month key
                    if (firstMonth && firstMonth.length) {
                        firstMonth.sort((a, b) => b.totalScore - a.totalScore);

                        // Store all territory result in order to show all time stats for the current month 
                        self.setAllTimeStats([...self.allTimeStats, ...firstMonth]);

                        // Here we're pushing total QT count in the main array
                        firstMonth.push({
                            totalKey: `Total QT in ${monthName}`,
                            totalScore: totalQTCountForUsers || 0,
                        });
                        // If "badgeType" is available then it means we have the data of enrolled users 👥
                        // Then we will push only enrolled users related count in the main array 
                        if (badgeType) {
                            firstMonth.push({
                                totalKey: `Total enrolled users who have 1 QT`,
                                totalScore: totalEnrolledUsersCount,
                            }, {
                                totalKey: `Total enrolled users who have 2+ QT`,
                                totalScore: totalEnrolledUsersCountMoreThanTwoInQT,
                            });
                        } else {
                            // If "badgeType" is not available 🥺 then it means we have the data of users only 👥
                            // Then we will push only users/members related count in the main array 
                            firstMonth.push({
                                totalKey: `Total users who have 1 QT`,
                                totalScore: totalUsersCountInQT,
                            }, {
                                totalKey: `Total users who have 2+ QT`,
                                totalScore: totalUsersCountMoreThanTwoInQT,
                            });
                        }
                    }
                    // Here we are setting the key in array of object by using map function
                    const allMonthsData = [...firstMonth].map((obj, index) => ({ ...obj, key: index + 1 }));
                    // Here we will find out the key according to the "badgeType", which will be used to store the data in respective user badge states
                    // By default, we're taking members state object
                    const keyName = userBadgesMappingWithQTKeys[badgeType] || userBadgesMappingWithQTKeys['default'];
                    // Here we're storing data to MobX state
                    self.setUsersQTDataForAnalytics(keyName, [...allMonthsData]);
                }
            } catch (error) {
                throw error;
            }
        },
        async fetchUsersQTData(params = {}) {
            try {
                self.setLoading(true);
                const { isMultipleBadgesDataRequired = false, badgeType, selectedMonth } = params;
                // Initially when component mount then we will fetch all data from all user badges with null(Members who does not have badge) for current month
                // That's why we are using "isMultipleBadgesDataRequired" flag
                if (isMultipleBadgesDataRequired) {
                    // Fetch all user badges names from constant file and here we're adding null for members only (who does not have any badge 😢) 
                    const enrolledUserBadgesArr = [...Object.values(USER_BADGES_ENROLLED_FLAGS), null];
                    // Get name of current month in MMM format like this -> "Jun", "Jul" etc.
                    const currentMonth = getMonthName(0, 'MMM');
                    // Find out start date and end date of month from the current month
                    const { startDateOfMonth: fromDate, endDateOfMonth: toDate } = getMonthRanges(0, 'YYYY-MM-DD', currentMonth);
                    // Create a params object of fromDate and toDate for the current month
                    const params = {
                        fromDate,
                        toDate
                    };
                    // If multiple user badges are available then we will add users QT data by user badge
                    if (enrolledUserBadgesArr && enrolledUserBadgesArr.length) {

                        for (let userBadge of enrolledUserBadgesArr) {
                            // We will bind the "badgeType" in params object before the api call
                            params['badgeType'] = userBadge;
                            // We will make the api call 🚀 to fetch the data based on territory and set the data according to the territory
                            await self.makeApiCallToFetchQTData({
                                badgeType: userBadge,
                                params: { ...params },
                                monthName: currentMonth
                            });
                        }


                        let studyTwoPlusQT = 0, studyOneQT = 0, acdeTwoPlusQT = 0, acdeOneQT = 0, hptcTwoPlusQT = 0, hptcOneQT = 0, ihpTwoPlusQT = 0, ihpOneQT = 0,
                            woodriverTwoPlusQT = 0, woodriverOneQT = 0, woodriverIhpTwoPlusQT = 0, woodriverIhpOneQT = 0, acdcTwoPlusQT = 0, acdcOneQT = 0, ctcTwoPlusQT = 0, ctcOneQT = 0;
                        for (let i = 0; i < self.allTimeStats.length; i++) {
                            const item = self.allTimeStats[i];
                            const { badgeType, totalScore } = item;
                            if ((badgeType === ENROLLED_STUDY) && totalScore >= 2) {
                                // count enrolled study 2+ QT
                                studyTwoPlusQT++;
                            } else if ((badgeType === ENROLLED_STUDY) && totalScore === 1) {
                                // count enrolled study 1 QT
                                studyOneQT++;
                            } else if ((badgeType === ENROLLED_DELAWARE) && totalScore >= 2) {
                                // count enrolled study 2+ QT
                                acdeTwoPlusQT++;
                            } else if ((badgeType === ENROLLED_DELAWARE) && totalScore === 1) {
                                // count enrolled study 1 QT
                                acdeOneQT++;
                            } else if ((badgeType === ENROLLED_HP) && totalScore >= 2) {
                                // count enrolled study 2+ QT
                                hptcTwoPlusQT++;
                            } else if ((badgeType === ENROLLED_HP) && totalScore === 1) {
                                // count enrolled study 1 QT
                                hptcOneQT++;
                            } else if ((badgeType === ENROLLED_IHP) && totalScore >= 2) {
                                // count enrolled study 2+ QT
                                ihpTwoPlusQT++;
                            } else if ((badgeType === ENROLLED_IHP) && totalScore === 1) {
                                // count enrolled study 1 QT
                                ihpOneQT++;
                            } else if ((badgeType === ENROLLED_WOODRIVER) && totalScore >= 2) {
                                // count enrolled study 1 QT
                                woodriverTwoPlusQT++;
                            } else if ((badgeType === ENROLLED_WOODRIVER) && totalScore === 1) {
                                // count enrolled study 1 QT
                                woodriverOneQT++;
                            } else if ((badgeType === ENROLLED_WOODRIVER_IHP) && totalScore >= 2) {
                                // count enrolled study 1 QT
                                woodriverIhpTwoPlusQT++;
                            } else if ((badgeType === ENROLLED_WOODRIVER_IHP) && totalScore === 1) {
                                // count enrolled study 1 QT
                                woodriverIhpOneQT++;
                            } else if ((badgeType === ENROLLED_ACDC) && totalScore >= 2) {
                                // count enrolled study 1 QT
                                acdcTwoPlusQT++;
                            } else if ((badgeType === ENROLLED_ACDC) && totalScore === 1) {
                                // count enrolled study 1 QT
                                acdcOneQT++;
                            } else if ((badgeType === ENROLLED_CTC) && totalScore >= 2) {
                                // count enrolled study 1 QT
                                ctcTwoPlusQT++;
                            } else if ((badgeType === ENROLLED_CTC) && totalScore === 1) {
                                // count enrolled study 1 QT
                                ctcOneQT++;
                            }
                        }

                        let enrolledStats = [];
                        // get goals value
                        const goals = await self.getGoalForGroups();
                        await Promise.all(goals.map(g => {
                            if (g.badge === "ACDE") {
                                enrolledStats.push({
                                    name: ENROLLED_DELAWARE,
                                    twoPlusQTCount: acdeTwoPlusQT,
                                    onePlusQTCount: acdeOneQT,
                                    goal: g.goal,
                                    endOfBarChart: g.endOfBarChart
                                });
                            } else if (g.badge === "HPTC") {
                                enrolledStats.push({
                                    name: ENROLLED_HP,
                                    twoPlusQTCount: hptcTwoPlusQT,
                                    onePlusQTCount: hptcOneQT,
                                    goal: g.goal,
                                    endOfBarChart: g.endOfBarChart
                                });
                            } else if (g.badge === "IHP") {
                                enrolledStats.push({
                                    name: ENROLLED_IHP,
                                    twoPlusQTCount: ihpTwoPlusQT,
                                    onePlusQTCount: ihpOneQT,
                                    goal: g.goal,
                                    endOfBarChart: g.endOfBarChart
                                });
                            } else if (g.badge === "STUDY") {
                                enrolledStats.push({
                                    name: ENROLLED_STUDY,
                                    twoPlusQTCount: studyTwoPlusQT,
                                    onePlusQTCount: studyOneQT,
                                    goal: g.goal,
                                    endOfBarChart: g.endOfBarChart
                                });
                            } else if (g.badge === "WRH") {
                                enrolledStats.push({
                                    name: ENROLLED_WOODRIVER,
                                    twoPlusQTCount: woodriverTwoPlusQT,
                                    onePlusQTCount: woodriverOneQT,
                                    goal: g.goal,
                                    endOfBarChart: g.endOfBarChart
                                });
                            } else if (g.badge === "WR+IH") {
                                enrolledStats.push({
                                    name: ENROLLED_WOODRIVER_IHP,
                                    twoPlusQTCount: woodriverIhpTwoPlusQT,
                                    onePlusQTCount: woodriverIhpOneQT,
                                    goal: g.goal,
                                    endOfBarChart: g.endOfBarChart
                                });
                            } else if (g.badge === "ACDC") {
                                enrolledStats.push({
                                    name: ENROLLED_ACDC,
                                    twoPlusQTCount: acdcTwoPlusQT,
                                    onePlusQTCount: acdcOneQT,
                                    goal: g.goal,
                                    endOfBarChart: g.endOfBarChart
                                });
                            } else if (g.badge === "CTC") {
                                enrolledStats.push({
                                    name: ENROLLED_CTC,
                                    twoPlusQTCount: ctcTwoPlusQT,
                                    onePlusQTCount: ctcOneQT,
                                    goal: g.goal,
                                    endOfBarChart: g.endOfBarChart
                                });
                            }
                        }));
                        self.setEnrolledStats(enrolledStats);
                        self.setIsLoadingEnrolledStats(false);
                    }
                } else if (selectedMonth) {
                    const lastMonthName = getMonthName(1, 'MMM');
                    const lastThirdMonthName = getMonthName(2, 'MMM');
                    let monthsToSubtract = 0;
                    if (selectedMonth === lastMonthName) {
                        monthsToSubtract = 1;
                    } else if (selectedMonth === lastThirdMonthName) {
                        monthsToSubtract = 2;
                    }
                    const { startDateOfMonth: fromDate, endDateOfMonth: toDate } = getMonthRanges(monthsToSubtract, 'YYYY-MM-DD');
                    const params = {
                        fromDate,
                        toDate,
                        badgeType
                    };
                    // We will make the api call to fetch the data based on user badge and set the data according to the user badge
                    await self.makeApiCallToFetchQTData({
                        badgeType,
                        params: { ...params },
                        monthName: selectedMonth
                    });
                }

                self.setLoading(false);
            } catch (error) {
                self.setLoading(false);
            }
        },
        fetchAllTerritoriesBySites(sitesData) {
            if (sitesData) {
                const territoriesData = [...new Set(Object.values(sitesData).map(site => site.territory))];
                self.setAllTerritories(territoriesData.sort());
            }
        },
        modifyFormsDataArr(formsData = []) {
            const modifiedFormsArr = [];
            if (formsData.length) {
                for (const formObj of formsData) {
                    const { id,
                        submittedFrom,
                        createdAt,
                        userId,
                        user,
                        form,
                        ...rest
                    } = formObj;
                    if (user && form) {
                        const { username, notes, color, badgeType, fullName, assignedPeersDetails = {} } = user;
                        const { name } = form;
                        const { assignedPeerUserDetails, submittedByUserDetails, ...otherPeerDetails } = assignedPeersDetails;
                        const fullDetailsOfAssignedPeer = { ...otherPeerDetails };
                        if (assignedPeerUserDetails && Object.keys(assignedPeerUserDetails).length) {
                            fullDetailsOfAssignedPeer['assignedPeerFullName'] = assignedPeerUserDetails.fullName;
                        }
                        if (submittedByUserDetails && Object.keys(submittedByUserDetails).length) {
                            fullDetailsOfAssignedPeer['submittedByFullName'] = submittedByUserDetails.fullName;
                        }
                        const formDataObj = {
                            id,
                            userId,
                            submittedFrom,
                            fullName,
                            formName: name,
                            createdAt,
                            username,
                            notes,
                            color,
                            badgeType,
                            assignedPeersDetails: { ...fullDetailsOfAssignedPeer },
                            ...rest
                        };
                        modifiedFormsArr.push(formDataObj);
                    }
                }
            }
            return modifiedFormsArr;
        },
        parseMetaData(meta) {
            return {
                pageSize: meta.limit,
                current: meta.skip / meta.limit + 1,
                total: meta.total,
            };
        },
        async applyWellnessPlanOrder(arr) {
            return Promise.all(arr.map(a => {
                // Default blank status
                let status = ""
                if (a.wellnessPlan) {
                    // Check for in-progress status
                    let inProgress = a.wellnessPlan.filter(wp => {
                        if (wp.questioncount != 0 && (wp.questioncount > 0 && wp.answercount != wp.questioncount)) return wp
                    })
                    // Set status in-progress
                    status = inProgress.length ? "in-progress" : ""

                    // skip status check if it is already set
                    if (!status) {
                        // Check for completed status
                        let completed = a.wellnessPlan.filter(wp => {
                            if (wp.questioncount != 0 && (wp.questioncount > 0 && wp.answercount == wp.questioncount)) return wp
                        })
                        // Set status completed
                        status = completed.length ? "completed" : ""
                    }

                    a.wellnessPlanSortIndex = (status == "completed" ? 2 : 1)
                } else {
                    a.wellnessPlanSortIndex = 0
                }
                return a
            }))
        },
        async getGoalForGroups() {
            try {
                const url = 'https://api.sheety.co/275eb6c47b45afabfd5f49e98bb0ea71/groupInfo'
                const response = await ApiService.getRequest("goals", {}, null, true, url);
                const { success, data } = response;
                if (success) {
                    const goalData = data['goals'];
                    const cloneArr = goalData.map((obj, index) => {
                        return { ...obj, key: index }
                    });
                    self.setGoalsGroupsData([...cloneArr]);
                    return cloneArr;
                } else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        },
        fetchPreviewControl(previewContolArr) {
            let previewControl = SecureLocalStorage.get("PREVIEW_CONTROL");
            if (!previewControl) {
                SecureLocalStorage.set("PREVIEW_CONTROL", JSON.stringify(previewContolArr));
            } else {
                // check for the new fields | if there is any difference, replace the new item
                previewControl = JSON.parse(previewControl);
                const diffItems = previewContolArr.filter(({ dataIndex: id1 }) => !previewControl.some(({ dataIndex: id2 }) => id2 === id1));
                if (diffItems.length) {
                    SecureLocalStorage.set("PREVIEW_CONTROL", JSON.stringify(previewContolArr));
                }
            }

            // set the previewControl
            previewControl = SecureLocalStorage.get("PREVIEW_CONTROL");
            self.setPreviewControl(JSON.parse(previewControl));
        },
        savePreviewControlToLS(previewContolArr) {
            SecureLocalStorage.set("PREVIEW_CONTROL", JSON.stringify(previewContolArr));
        },
        getSortOrder(column) {
            return self.sortColumn === column ? self.sortOrder : null;
        },
        reset() {
            self.usersData = [];
            self.formsData = [];
            self.thisWeekUsersData = [];
            self.lastWeekUsersData = [];
            self.firstSectionThisWeekUsersData = [];
            self.firstSectionLastWeekUsersData = [];
            self.secondSectionLastWeekUsersData = [];
            self.secondSectionLastToLastWeekUsersData = [];
            self.currentMonthRetentionUsers = [];
            self.pastMonthRetentionUsers = [];
            self.currentMonthUsersMessagedInCurrentMonth = null;
            self.pastMonthUsersMessagedInCurrentMonth = null;
            self.userMessagesReportData = [];
            self.usersWauData = [];
            self.overviewData = {};
            self.usersWauDataBySites = {};
            self.usersWauDataByTerritories = {};
            self.usersQTDataForEnrolledDelaware = [];
            self.usersQTDataForEnrolledHp = [];
            self.usersQTDataForEnrolledIhp = [];
            self.usersQTDataForEnrolledStudy = [];
            self.usersQTDataForEnrolledWoodriver = [];
            self.usersQTDataForEnrolledWoodriverIhp = [];
            self.usersQTDataForEnrolledAcdc = [];
            self.usersQTDataForEnrolledCtc = [];
            self.usersQTDataForMembers = [];

            self.firstSectionCommonUsersCount = null;
            self.secondSectionCommonUsersCount = null;
            self.filterBy = null;
            self.wauChartLoading = false;
            self.wauChartByTerritoriesLoading = false;
            self.usersQTsBreakdownData = [];
            self.resetPagination();
            self.selectedMonthForEnrolledDelawareQT = null;
            self.selectedMonthForEnrolledHpQT = null;
            self.selectedMonthForEnrolledIhpQT = null;
            self.selectedMonthForEnrolledStudyQT = null;
            self.selectedMonthForEnrolledWoodriverQT = null;
            self.selectedMonthForEnrolledWoodriverIhpQT = null;
            self.selectedMonthForEnrolledAcdcQT = null;
            self.selectedMonthForEnrolledCtcQT = null;
            self.selectedMonthForMembersQT = null;

            self.selectedUserIdForCaseLoad = null;
            self.selectedUsernameForCaseLoad = null;
            self.selectedTerritoryForCaseLoad = null;
            self.selectedBadgeForCaseLoad = null;
            self.selectedIsEnrolledForCaseloads = false;
            self.selectedTerritoryForStatsUsers = null;
            self.sortColumn = null;
            self.sortOrder = null;
            self.allTerritories = [];
            self.enrolledStats = [];
            self.isLoadingEnrolledStats = true;
            self.allTimeStats = [];
        },
        resetPagination() {
            self.pagination = {
                pageSize: 50,
                total: 0,
                current: 1,
            };
        },
        setAllTerritories(data) {
            self.allTerritories = data;
        },
        setSortParameters(column, order) {
            self.sortColumn = column;
            self.sortOrder = order;
        },
        resetSortParameters() {
            self.sortColumn = null;
            self.sortOrder = null;
        },
        setWauChartLoading(value) {
            self.wauChartLoading = value;
        },
        setWauChartByTerritoriesLoading(value) {
            self.wauChartByTerritoriesLoading = value;
        },
        setUsersWauDataByTerritories(data) {
            self.usersWauDataByTerritories = data;
        },
        setUsersWauDataBySites(data) {
            self.usersWauDataBySites = data;
        },
        setFilterBy(value) {
            self.filterBy = value;
        },
        setUsersQTsBreakdownData(arr) {
            arr.sort((a, b) => b.qt - a.qt);
            self.usersQTsBreakdownData = arr;
        },
        setSelectedUserIdForCaseLoad(value) {
            self.selectedUserIdForCaseLoad = value;
        },
        setSelectedUsernameForCaseLoad(value) {
            self.selectedUsernameForCaseLoad = value;
        },
        setSelectedTerritoryForCaseLoad(value) {
            self.selectedTerritoryForCaseLoad = value;
        },
        setSelectedBadgeForCaseLoad(value) {
            self.selectedBadgeForCaseLoad = value;
        },
        setSelectedEnrolledForCaseLoad(value) {
            self.selectedIsEnrolledForCaseloads = value;
        },
        setSelectedTerritoryForStatsUsers(value) {
            self.selectedTerritoryForStatsUsers = value;
        },
        setPaginationData(pagination) {
            self.pagination = pagination;
        },
        setUsersData(arr) {
            const modifiedArr = arr.map(obj => (
                {
                    ...obj,
                    qtPoints: { totalPoints: obj.totalPoints, lastCompletedAt: obj.lastCompletedAt },
                    barc10Date: { formReviewedAt: obj.formReviewedAt, formCompletedAt: obj.formCompletedAt, formSendAt: obj.formSendAt },
                    indator: { indatorFlag: obj.indatorFlag, monthFlag: obj.monthFlag, thisMonthMessages: obj.thisMonthMessages, lastMonthMessages: obj.lastMonthMessages }
                }
            ))
            self.usersData = modifiedArr;
        },
        setUsersQTDataForAnalytics(key, arr) {
            self[key] = arr;
        },
        setSelectedMonthForQTData(key, value) {
            // Here if we have multiple keys in the array with the same value 
            // Then we will set in MobX state using for of loop
            if (Array.isArray(key)) {
                for (const str of key) {
                    self[str] = value;
                }
            } else {
                self[key] = value;
            }
        },
        setUsersWauData(arr) {
            self.usersWauData = arr;
        },
        setOverviewData(data) {
            self.overviewData = data;
        },
        setUserMessagesReportData(arr) {
            self.userMessagesReportData = arr;
        },
        setFirstSectionThisWeekUsersData(arr) {
            self.firstSectionThisWeekUsersData = arr;
        },
        setFirstSectionLastWeekUsersData(arr) {
            self.firstSectionLastWeekUsersData = arr;
        },
        setSecondSectionLastWeekUsersData(arr) {
            self.secondSectionLastWeekUsersData = arr;
        },
        setSecondSectionLastToLastWeekUsersData(arr) {
            self.secondSectionLastToLastWeekUsersData = arr;
        },
        setFirstSectionCommonUsersCount(value) {
            self.firstSectionCommonUsersCount = value;
        },
        setSecondSectionCommonUsersCount(value) {
            self.secondSectionCommonUsersCount = value;
        },
        setCurrentMonthRetentionUsers(arr) {
            self.currentMonthRetentionUsers = arr;
        },
        setPastMonthRetentionUsers(arr) {
            self.pastMonthRetentionUsers = arr;
        },
        setCurrentMonthUsersMessagedInCurrentMonth(value) {
            self.currentMonthUsersMessagedInCurrentMonth = value;
        },
        setPastMonthUsersMessagedInCurrentMonth(arr) {
            self.pastMonthUsersMessagedInCurrentMonth = arr;
        },
        setFormsData(arr) {
            self.formsData = arr;
        },
        setStatsData(arr) {
            self.statsData = arr;
        },
        setLoading(value) {
            self.loading = value;
        },
        setStatsTabKeyForUnmount(key) {
            self.statsTabKey = key;
        },
        setAllTimeStats(key) {
            self.allTimeStats = key;
        },
        setEnrolledStats(key) {
            self.enrolledStats = key;
        },
        setIsLoadingEnrolledStats(key) {
            self.isLoadingEnrolledStats = key;
        },
        setGoalsGroupsData(data) {
            self.availableGoalsData = data;
        },
        setPreviewControl(data) {
            self.previewControl = data;
        },
        setStatsTabKey(key) {
            self.reset();
            self.statsTabKey = key;
            if (key === 1) {
                self.getUsersCount();
                const currentMonthName = getMonthName(0, 'MMM');
                // Here we're setting current month to all available tables at once
                self.setSelectedMonthForQTData(['selectedMonthForEnrolledDelawareQT',
                    'selectedMonthForEnrolledHpQT',
                    'selectedMonthForEnrolledIhpQT',
                    'selectedMonthForEnrolledStudyQT',
                    'selectedMonthForEnrolledWoodriverQT',
                    'selectedMonthForEnrolledWoodriverIhpQT',
                    'selectedMonthForEnrolledAcdcQT',
                    'selectedMonthForEnrolledCtcQT',
                    'selectedMonthForMembersQT'
                ], currentMonthName);
                // Here we're fetching users QT data using "isMultipleBadgesDataRequired" flag to true
                self.fetchUsersQTData({ isMultipleBadgesDataRequired: true });
                return;
            } else if (key === 5) {
                self.setFilterBy(FILTER_BY_ALL_FORMS_FLAG);
                self.getFormsData();
                return;
            } else if (key === 6) {
                const allTerritoriesNames = Object.keys(toJS(SiteStore.allTerritories));
                self.fetchAppHealthData();
                self.getUsersWauData();
                self.fetchOverviewData();
                self.getRetentionData();
                self.fetchUsersWauDataBySites({
                    fetchType: USERS_WAU_DATA_FETCH_FLAGS_TERRITORY,
                    territory: [...allTerritoriesNames]
                });
                return;
            } else if (key === 2 || key === 3 || key === 4) {
                const { allSites } = SiteStore;
                // set initial territory to all territories
                self.setSelectedTerritoryForStatsUsers(TERRITORIES_TYPE_ALL_TERRITORIES);
                self.getUsersList();
                if (toJS(allSites) && !toJS(self.allTerritories).length) {
                    // fetch territories by sites if admin comes to case load section after changing the tab in stats page
                    self.fetchAllTerritoriesBySites(toJS(allSites));
                }
                return;
            } else {
                //  we will reset the pagination if admin comes to case load section
                const { allSites } = SiteStore;
                self.resetPagination();
                // set initial territory to all territories
                self.setSelectedTerritoryForCaseLoad(TERRITORIES_TYPE_ALL_TERRITORIES);
                self.setSelectedBadgeForCaseLoad(BADGE_TYPE_ENROLLED_CODE);
                if (toJS(allSites)) {
                    // fetch territories by sites if admin comes to case load section after changing the tab in stats page
                    self.fetchAllTerritoriesBySites(toJS(allSites));
                }
                return;
            }
        },
    }))
    .views((self) => ({
    }));

export default Stats;
