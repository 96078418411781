import { commonTrackEvent } from "../utils/Analytics";
import AuthStore from "../stores/AuthStore";
import FlaggedMessagesStore from "../stores/FlaggedMessagesStore";
import ApiService from "./ApiService";
import MemberListStore from "../stores/MemberListStore";
import SiteStore from "../stores/SiteStore";
import { getReactionType } from "./getReactionType";
import MessagesStore from "../stores/MessagesStore";
import {
  subscribeSingleChannel,
  unsubscribeSingleChannel,
  getPubnubInstanceByUserType,
} from "./PubnubMethods";
import GroupListStore from "../stores/GroupListStore";
import { DEV } from "../constants/UserRolesConstant";
import { getCurrentPubNubToken, refreshPubNubAuthToken } from "./pubnub-auth";

export const signalHandler = async (signal) => {
  const userId = AuthStore.userId;
  const { actionType, data } = signal;
  console.log(`Received signal: ${actionType}`);
  switch (actionType) {
    case "GROUP_ADD": {
      const { id: groupId, siteId, name } = data;
      const userType = AuthStore.type;
      const selecetedSiteId = SiteStore.selectedSiteId;
      const isNotModerator = userType !== "moderator" && userType !== "SA" && userType !== DEV;

      const isSameSite =
        isNotModerator ||
        siteId === selecetedSiteId ||
        selecetedSiteId === null;
      if (isSameSite) {
        let filteredGroup = AuthStore.groups.filter(
          (group) => group.id === groupId
        );
        if (!filteredGroup.length) {
          commonTrackEvent("GROUP", "Added Into Group Chat", name, groupId);
          const params = { userId, groupId };
          const res = await ApiService.getRequest("user-groups", params);
          const pubnub = getPubnubInstanceByUserType(AuthStore.type);
          const user_groups = res.data[0];
          user_groups &&
            AuthStore.addGroupToUserGroups({ ...data, user_groups });
          subscribeSingleChannel(pubnub, `GROUP_CHAT_${groupId}`);
        }
      }
      break;
    }
    case "GROUP_REMOVE":
    case "GROUP_DELETE": {
      const { id: groupId } = data;
      AuthStore.removeGroupFromUserGroups(groupId);
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const groupChannel = `GROUP_CHAT_${groupId}`;
      await unsubscribeSingleChannel(pubnub, groupChannel);
      break;
    }
    // case "GROUP_EDIT": {
    //   AuthStore.editGroupDetails(data);
    //   break;
    // }
    case "USERDATA_UPDATE": {
      AuthStore.updateProfileData(data);
      break;
    }
    case "GROUP_MEMBER_REMOVE": {
      const { userId, groupId, type, status } = data;
      if (type === "user" || type === "semi-moderator") {
        GroupListStore.removeGroupUserThroughSignal(userId, groupId, status);
      }
      break;
    }
    case "GROUP_MEMBER_ADD": {
      const { type } = data;
      if (type === "user" || type === "semi-moderator") {
        GroupListStore.addGroupUserThroughSignal(data);
      }
      break;
    }
    case "GROUP_MEMBER_USER_GROUP_CHANGE": {
      const { groupId, userId, status } = data;
      GroupListStore.changePauseInSessionThroughSignal(groupId, userId, status);
      break;
    }
    case "ALERT_RESOLVED": {
      const { id, isResolved, siteId } = data;
      const selectedSiteId = SiteStore.selectedSiteId;
      if (
        selectedSiteId === 0 ||
        selectedSiteId === -1 ||
        selectedSiteId === siteId
      ) {
        FlaggedMessagesStore.updateUsingSignal(id, isResolved);
      }
      break;
    }
    case "ALERT_UNRESOLVED": {
      const { id, isResolved, siteId } = data;
      const selectedSiteId = SiteStore.selectedSiteId;
      if (
        selectedSiteId === 0 ||
        selectedSiteId === -1 ||
        selectedSiteId === siteId
      ) {
        FlaggedMessagesStore.updateUsingSignal(id, isResolved);
      }
      break;
    }
    case "ALERT_ADDED": {
      const { siteId } = data;
      const selectedSiteId = SiteStore.selectedSiteId;
      if (
        selectedSiteId === 0 ||
        selectedSiteId === -1 ||
        selectedSiteId === siteId
      ) {
        FlaggedMessagesStore.addToUnResolvedSignal(data);
      }
      break;
    }
    case "ALERT_REMOVED": {
      const { id, isResolved, siteId } = data;
      const selectedSiteId = SiteStore.selectedSiteId;
      if (
        selectedSiteId === 0 ||
        selectedSiteId === -1 ||
        selectedSiteId === siteId
      ) {
        FlaggedMessagesStore.removeFlagMessage(id, isResolved);
      }
      break;
    }
    case "USER_RESOLVED": {
      const { siteId } = data;
      const selectedSiteId = SiteStore.selectedSiteId;
      if (
        selectedSiteId === 0 ||
        selectedSiteId === -1 ||
        selectedSiteId === siteId
      ) {
        MemberListStore.removeFromUnResolved(data);
      }
      break;
    }
    case "USER_UNRESOLVED": {
      const { siteId } = data;
      const selectedSiteId = SiteStore.selectedSiteId;
      if (
        selectedSiteId === 0 ||
        selectedSiteId === -1 ||
        selectedSiteId === siteId
      ) {
        MemberListStore.addToUnResolved(data);
      }
      break;
    }
    case "DM_ADD": {
      const { id: dmId, name } = data;
      let filteredDmChannel = AuthStore.dms.filter(
        (dmChannelObj) => dmChannelObj.id === dmId
      );
      if (!filteredDmChannel.length) {
        commonTrackEvent("DIRECT", "Added Into DM Chat", name, dmId);
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        AuthStore.addDmToUserDms(data);
        subscribeSingleChannel(pubnub, `DIRECT_MESSAGE_${dmId}`);
      }
      break;
    }
    case "USER_GROUP_CHANGE": {
      const { groupId, status } = data;
      AuthStore.updateUserStatus(groupId, status);
      break;
    }
    case "PAUSE_ALL": {
      const { groupId, status } = data;
      AuthStore.updateAllPaused(groupId, status);
      break;
    }
    case "MESSAGE_REACTION_REMOVE": {
      const { userId, messageId, channel, siteId, reactionId } = data;
      const type = getReactionType(reactionId);
      const messageEntry = {
        type: type,
        timetoken: messageId,
        userId: userId,
      };
      const { selectedGroup } = MessagesStore;
      if (channel === selectedGroup.channel) {
        selectedGroup.updateMessage(messageEntry);
      }
      break;
    }
    case "MESSAGE_REACTION_ADD": {
      const { userId, messageId, channel, siteId, reactionId } = data;
      const type = getReactionType(reactionId);
      const messageEntry = {
        type: type,
        timetoken: messageId,
        userId: userId,
      };
      const { selectedGroup, addNewDeletedMessage } = MessagesStore;
      if (channel === selectedGroup.channel) {
        selectedGroup.updateMessage(messageEntry);
      } else {
        if (type === "delete") {
          addNewDeletedMessage(channel);
        }
      }
      break;
    }
    case "NEW_PUBNUB_TOKEN_GRANTED":
      const { newPubNubToken } = data;
      const currentPubNubToken = await getCurrentPubNubToken();

      if (currentPubNubToken !== newPubNubToken) {
       await refreshPubNubAuthToken(currentPubNubToken);
      }
      break;
    default: {
      return;
    }
  }
};
