import React from 'react';
import { Col, Row, Spin, notification, Icon, Button } from 'antd';
import { inject, observer } from 'mobx-react';
import * as Sentry from "@sentry/browser";

import { rewards as rewardsLabel } from '../../en.json';
import Reward from '../UI/Reward';
import RewardNoteModal from '../UI/RewardNoteModal';
import { IMG_DOWN_ARROW, IMG_UP_ARROW } from '../../utils/ImageUtils';
import mixpanel from 'mixpanel-browser';
import { CustomRewardInput } from './CustomRewardInput';
import { DEV, MODERATOR, SA } from '../../constants/UserRolesConstant';
import { MAX_REWARD_AMOUNT } from '../../constants/GlobalConstant';
import AuthStore from '../../stores/AuthStore';

@inject("store")
@observer
class Rewards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRewardNoteModalOpen: false,
      rewardNote: '',
      selectedRewardId: null,
      showCompletedSection: false,
      showExpiredSection: false,
      showAddTaskModal: false,
      startedAt: null,
      expiredAt: null,
      rewardAmount: '',
      rewardCriteria: '',
      rewardName: '',
      submitLoading: false,
    };
  }

  async componentDidMount() {
    // fetch onboarding status and rewards
    await this.fetchRewards();
  }

  fetchRewards = async () => {
    const {
      store: {
        ProfileStore,
        RewardStore,
      }
    } = this.props;
    // Fetch wellness plan by user when admin clicks on any user profile
    await RewardStore.fetchRewards(ProfileStore.userId);
  }

  openNotification = (description, type = 'success') => {
    notification[type]({
      message: "Alert",
      description,
    });
  };

  onClickComplete = async (id) => {
    this.setState({
      selectedRewardId: id,
      isRewardNoteModalOpen: true
    });
  }

  resetState = () => {
    this.setState({
      isRewardNoteModalOpen: false,
      rewardNote: '',
      selectedRewardId: null,
      startedAt: null,
      expiredAt: null,
      rewardAmount: '',
      rewardCriteria: '',
      rewardName: '',
    })
  }

  onComplete = async () => {
    const {
      store: {
        RewardStore: { completeReward },
        ProfileStore
      },
    } = this.props;

    const { selectedRewardId, rewardNote } = this.state;

    const payload = {
      rewardId: selectedRewardId,
      userId: ProfileStore.userId,
      note: rewardNote
    }
    try {
      // Do complete reward
      await completeReward(payload);
      // Reset state
      this.resetState()
      // Show success toast
      this.openNotification("This reward marked as completed", "success");
    } catch (e) {
      this.openNotification("Something went wrong", "error");
      Sentry.captureException(
        new Error(`Error on complete reward on web :: ${e.message}`)
      );
    }
  }

  onDateChange = async (rewardId, userId, startedAt) => {
    const {
      store: {
        RewardStore: { updateRewardStartDate },
        AuthStore: { username },
        ProfileStore,
      },
    } = this.props;

    const payload = { rewardId, userId, startedAt };
    try {
      // Do update start date
      await updateRewardStartDate(payload);
      this.openNotification("Start date has been updated", "success");
      mixpanel.track("REWARD_START_DATE_CHANGED", {
        from: "WEB",
        username: ProfileStore.username,
        peerUsername: username,
        changedOn: new Date().toISOString(),
      });

    } catch (e) {
      this.openNotification("Something went wrong", "error");
      Sentry.captureException(
        new Error(`Error on start date has been updated :: ${e.message}`)
      );
    }
  }
  onExpiryDateChange = async (rewardId, userId, expiredAt) => {
    const {
      store: {
        RewardStore: { updateRewardEndDate },
        AuthStore: { username },
        ProfileStore,
      },
    } = this.props;

    const payload = { rewardId, userId, expiredAt };
    try {
      await updateRewardEndDate(payload);
      this.openNotification("End date has been updated", "success");
      mixpanel.track("REWARD_END_DATE_CHANGED", {
        from: "WEB",
        username: ProfileStore.username,
        peerUsername: username,
        changedOn: new Date().toISOString(),
      });
    } catch (e) {
      this.openNotification("Something went wrong", "error");
      Sentry.captureException(
        new Error(`Error on end date has been updated :: ${e.message}`)
      );
    }
  }

  onCloseRewardNoteModal = () => {
    this.setState({ isRewardNoteModalOpen: false })
  }

  onChangeRewardNote = (e) => {
    this.setState({ rewardNote: e.target.value })
  }

  renderRewards = () => {
    const {
      store: {
        RewardStore: { rewards, totalUnRedeemed, onboardingStatus, completedRewards,expiredRewards }
      },
    } = this.props;
    const { showCompletedSection,showExpiredSection } = this.state;
    return (
      <React.Fragment>
        {/** Active rewards listing */}
        <div style={{ marginTop: 30 }}>
          {rewards ?
            rewards.map((t, index) => {
              return <Reward
                reward={t}
                onboardingStatus={onboardingStatus}
                key={index}
                onComplete={(id) => this.onClickComplete(id)}
                onDateChange={(rewardId, userId, startedAt) => this.onDateChange(rewardId, userId, startedAt)}
                onExpiryDateChange={(rewardId, userId, expiredAt) => this.onExpiryDateChange(rewardId, userId, expiredAt)}
              />
            }) :
            <Row>
              <Col span={24} className={"no-tasks"}>
                <p>{'No rewards found.'}</p>
              </Col>
            </Row>
          }
        </div>

        {/** Completed rewards listing */}
        <div style={{ marginTop: 30 }}>
          <Row>
            <Col span={12}>
              <label className="task-label">{rewardsLabel.completedRewards}</label>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => this.setState({ showCompletedSection: !showCompletedSection })}
              >
                <img
                  src={showCompletedSection ? IMG_UP_ARROW : IMG_DOWN_ARROW}
                  className="dropdown-arrow"
                  alt="arrow"
                />
              </span>
            </Col>
          </Row>
          {showCompletedSection && completedRewards &&
            completedRewards.map((t, index) => {
              return <Reward
                reward={t}
                onboardingStatus={onboardingStatus}
                key={index}
                onComplete={(id) => this.onClickComplete(id)}
              />
            })}
        </div>
        {/** Expire rewards listing */}
        <div style={{ marginTop: 30 }}>
          <Row>
            <Col span={12}>
              <label className="task-label">{rewardsLabel.expiredRewards}</label>
            </Col>
            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => this.setState({ showExpiredSection: !showExpiredSection })}
              >
                <img
                  src={showExpiredSection ? IMG_UP_ARROW : IMG_DOWN_ARROW}
                  className="dropdown-arrow"
                  alt="arrow"
                />
              </span>
            </Col>
          </Row>
          {showExpiredSection && expiredRewards &&
            expiredRewards.map((t, index) => {
              return <Reward
                reward={t}
                onboardingStatus={onboardingStatus}
                key={index}
              />
            })}
        </div>
      </React.Fragment>)
  };
  onAddTask = () => {
    this.setState({
      showAddTaskModal: true,
    })
  }
  onChangeStartedDate = (e) => {
    this.setState({ startedAt: e.target.value })
  }
  onChangeExpiredDate = (e) => {
    this.setState({ expiredAt: e.target.value })
  }
  onChangeRewardAmount = (e) => {
    const { min, max } = e.target;
    if (e.target.value.split('$')[1] === '' ) {
        this.setState({rewardAmount: ''})
        return
      }
    const rawValue = e.target.value.split("$")
    let value = rawValue.length === 1 ? rawValue[0] : rawValue[1];
    if (isNaN(value)) return;
    const amount = '$' + value;
    this.setState({ rewardAmount: amount })
  }
  onChangeRewardCriteria = (e) => {
    this.setState({ rewardCriteria: e.target.value })
  }
  onChangeName = (e) => {
    this.setState({ rewardName: e.target.value })
  }
  onSubmit = async () => {
    const { store: {
      ProfileStore: { userId },
      RewardStore: { addReward },
    } } = this.props;
    const { rewardAmount, rewardCriteria, rewardName, startedAt, expiredAt } = this.state;

    this.setState({submitLoading: true})

    let rewardValue = parseInt(rewardAmount.toString().slice(1,));
    const dataToSave = {
      userId,
      status: 'IN_PROGRESS',
      amount: rewardValue,
      title: rewardName,
      criteria: rewardCriteria,
      startedAt,
      expiredAt,
      isDefault: false,
      // isActive: true,
      rewardFormType: 'custom-reward'
    }
    await addReward(dataToSave);
    this.setState({ showAddTaskModal: false })
    this.resetState()
    this.setState({submitLoading: false})
  }
  isStartDateAhead = () => {
    const { startedAt, expiredAt } = this.state;
    return startedAt && expiredAt && new Date(startedAt) >= new Date(expiredAt);
  };
  isRewardAmountNotNumber = () => {
    const { rewardAmount } = this.state;
    let rewardValue = parseInt(rewardAmount.toString().slice(1,));
    if (rewardAmount.length == 0) {
      return false;
    }
    return isNaN(rewardValue) || Number(rewardValue) !== parseFloat(rewardValue);
  };
  isSaveButtonDisabled = () => {
    const { rewardAmount, rewardCriteria, rewardName, startedAt } = this.state;
    const amount = rewardAmount.split('$')[1];
    const {type} = AuthStore;
    return (
      !startedAt ||
      !rewardCriteria ||
      !rewardAmount ||
      !rewardName ||
      this.isStartDateAhead() ||
      this.isRewardAmountNotNumber() ||
      (amount < 1) || 
      (amount >  (type === MODERATOR ? MAX_REWARD_AMOUNT.MODERATOR : MAX_REWARD_AMOUNT.SA)));
  };
  getMessageToShow = () => {
    const {type} = AuthStore;
    const amount = +this.state.rewardAmount.split('$')[1];
    const max = type === MODERATOR ? MAX_REWARD_AMOUNT.MODERATOR : MAX_REWARD_AMOUNT.SA;
    if (this.isStartDateAhead()) return 'Error: The Start Date must be earlier than the Expiry Date.';
  
    if (this.isRewardAmountNotNumber()) return 'Error: Reward Amount should be a valid number.';
    
    if ( amount > max) return `Error: Reward Amount must be less than $${max}`
    
    if (amount < 1) return 'Error: Reward Amount must be greater than $1'
    
    if (amount && !Number.isInteger(amount)) return 'Error: Reward Amount cannot contain cents'
    
    return '';
  };
  render() {
    const {
      store: {
        RewardStore: { fetchRewardsLoading, completeRewardsLoading, totalUnRedeemed },
        AuthStore: { type: userType }
      },
    } = this.props;

    const { showAddTaskModal, isRewardNoteModalOpen, rewardNote, expiredAt, startedAt, rewardAmount, rewardCriteria, rewardName } = this.state;
    const canCreateReward = userType === SA || userType === DEV || userType === MODERATOR;
    return (
      <React.Fragment>
        <div className="profile-edit" style={{ marginBottom: 0 }}>
          <div className="task-label">{rewardsLabel.rewardLable}</div>
          {canCreateReward && !showAddTaskModal && <Button
            className="download-button"
            type="primary"
            size="large"
            style={{ width: "180px" }}
            onClick={() => this.onAddTask()}
          >
            Add Custom Reward
          </Button>}
        </div>
        <div style={{ marginTop: 30, display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginInline: 29 }}>
          <p className='reward-primary-label pt-0'>
            {`Total un-redeemed = $${totalUnRedeemed || 0}`}
          </p>
          <h3 className="reward-reload-label mt-18 " onClick={() => this.fetchRewards()} style={{ cursor: 'pointer', display: 'block', alignItems: 'center', margin: 0 }}>
            Reload&nbsp;
            <Icon
              type="reload"
              className="reward-reload-icon"
              style={{ marginLeft: '5px' }}
            />
          </h3>
        </div>
        {
          showAddTaskModal && <CustomRewardInput
            rewardName={rewardName} onChangeName={this.onChangeName}
            rewardCriteria={rewardCriteria} rewardAmount={rewardAmount} expiredAt={expiredAt}
            startedAt={startedAt} onChangeExpiredDate={this.onChangeExpiredDate} onChangeStartedDate={this.onChangeStartedDate}
            onChangeAmount={this.onChangeRewardAmount} onChangeRewardCriteria={this.onChangeRewardCriteria}
            onCancel={() => this.setState({ showAddTaskModal: false })}
            onSubmit={this.onSubmit}
            isSaveButtonDisabled={this.isSaveButtonDisabled}
            messageToShow={this.getMessageToShow()}
            submitLoading={this.state.submitLoading}
          />
        }
        <div className='task-root-container' style={{ marginBottom: 30 }}>
          {fetchRewardsLoading ?
            <div className="tasks-loader">
              <Spin />
            </div>
            : this.renderRewards()}
        </div>

        <RewardNoteModal
          rewardNote={rewardNote}
          visible={isRewardNoteModalOpen}
          onCancel={() => this.onCloseRewardNoteModal()}
          onComplete={() => this.onComplete()}
          onChangeRewardNote={(event) => this.onChangeRewardNote(event)}
          completeRewardsLoading={completeRewardsLoading}
        />
      </React.Fragment>
    );
  }
};

export default Rewards;