import HmacSHA256 from "crypto-js/hmac-sha256";
import Pubnub from "pubnub";
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import moment from 'moment';

import pubnubInstances from "./pubnubInstance";
import MessagesStore from "../stores/MessagesStore.js";
import { signalHandler } from "./signalHandler";
import AuthStore from "../stores/AuthStore.js";
import NotificationStore from "../stores/NotificationStore.js";
import FeedStore from "../stores/FeedStore";
import FormMessageStore from "../stores/FormMessageStore.js";
import { add } from "./addTimetokenHelper";
import MemberListStore from "../stores/MemberListStore";
import GroupStore from "../stores/Groups";
import SiteStore from "../stores/SiteStore";
import { sub } from "./subtractTimetokenHelper";
import GroupListStore from "../stores/GroupListStore.js";
import { commonTrackEvent } from "./Analytics";
import { encryptedPublish, decryptMessage } from "./messageHandler";
import { getBatchedArray } from "./getBatchArray";
import { APNS2_SETTINGS } from "./apnsSettings";
import { IS_ISLAND, currentSiteName, getFeedChannel, ENVIRONMENT } from "../utils/getEnvironment";
import ApiService from "./ApiService";
import { COMMENT, DELETE, FEED_CHANNEL, FEED_COMMENT_PER_PAGE, FEED_RECORD_PER_PAGE, NOTIFICATION_TYPE } from "../constants/GlobalConstant";
import UserBadgesStore from "../stores/UserBadgesStore";
import { isValidReplyingToMessage, jsonEscape } from "./CommonUtils";
import { DEV } from "../constants/UserRolesConstant";
import {
  emitter,
  getCurrentPubNubToken,
  pubNubCallWithRetry,
  refreshPubNubAuthToken,
  sendNotificationWithPubNubAuth
} from "./pubnub-auth";
const {
  REACT_APP_PUBKEY: pubKey,
  REACT_APP_SUBKEY: subKey,
  REACT_APP_SECRET_KEY: secretKey,
} = process.env;
const mixpanel = require("mixpanel-browser");

let existingListener = null;

export const getPubnubInstanceByUserType = (userType) => {
  return ["moderator", "SA",DEV].includes(userType)
    ? pubnubInstances.moderator
    : pubnubInstances.personal;
};

export const createUserPubnubInstance = async (params) => {
  const { userId } = params;
  const pubnub = new Pubnub({
    publishKey: pubKey,
    subscribeKey: subKey,
    useRandomIVs: IS_ISLAND,
    userId: userId.toString(),
    restore: true,
    enableEventEngine: true,
    retryConfiguration: Pubnub.LinearRetryPolicy({
      delay: 2,
      maximumRetry: 5,
    }),
  });

  const currentPubNubToken = await getCurrentPubNubToken();
  pubnub.setToken(currentPubNubToken);

  return pubnub;
};

export const setUUID = (pubnub, id) => {
  pubnub.setUserId(_.toString(id));
};

export const publish = (pubnub, message, replyingToMessage = null) => {
  const {
    selectedGroup: { channel, name, id, userType, isIntersiteGroup },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const { usernameToUserIdMap } = GroupStore;

  const channelType = channel.startsWith("GROUP")
    ? "GROUP"
    : channel.startsWith("WAITING")
      ? "WAITING"
      : "DIRECT";

  const replyMessage = isValidReplyingToMessage(replyingToMessage) ? (replyingToMessage.entry ?? replyingToMessage) : null;
  const dataToBePassed = {
    message: {
      replyMessage,
      type: "text",
      encrypted: true,
      text: message,
      sender: username,
      userId: userId,
      userType: userType,
    },
    channel,
  }
  const info = {
    message: `${username}: ${message}`.replace(/\n/g, " "),
    body: `${username}: ${message}`.replace(/\n/g, " "),
    notificationType: `${channelType}-TEXT MESSAGE`,
    title: name,
    channel,
    groupName: name,
    sender: username,
  };

  const notificationData = {
    message: generateNotificationMessage(info),
    storeInHistory: false,
    channel,
  }
  const encryptedData = encryptedPublish(pubnub, dataToBePassed);
  const encryptedNotificationData = encryptedPublish(pubnub, notificationData);

  let messageBody = channelType === "WAITING" ? message : `${username}: ${message}`;
  mixpanel.track('Notification Data For Dm And Group', { ...notificationData });

  if (isIntersiteGroup) {
    if (channelType === "GROUP") {
      // The case in which the message is in the particular chat group
      // Then make the api request to the Backend server
      let params = {
        message: dataToBePassed.message,
        channel: dataToBePassed.channel,
        channelType: channelType,
        userId: dataToBePassed.message.userId,
        notificationData: notificationData,
        groupName: name,
        groupId: id,
        currentSite: currentSiteName
      }

      ApiService.postRequest("inter-site", params)
        .then(r => {
          channelType === "DIRECT"
            ? commonTrackEvent("DIRECT", "Send Message In DM", name, id)
            : commonTrackEvent("GROUP", "Send Message In Group", name, id);
        })
        .catch(e => {
          Sentry.captureException(new Error(`Error on publish Text :: ${e}`));
          channelType === "DIRECT"
            ? commonTrackEvent("DIRECT", "Message fails to send in DM", name, id)
            : commonTrackEvent(
              "GROUP",
              "Message fails to send in Group",
              name,
              id
            );
          NotificationStore.setNotification(
            "error",
            "The following message wasn't sent",
            message,
            0
          );

        })
    } else {
      return;
    }
  }

  return new Promise((resolve, reject) => {
    pubNubCallWithRetry(() => pubnub.publish(encryptedData))
      .then(() => {
        channelType === "DIRECT"
          ? commonTrackEvent("DIRECT", "Send Message In DM", name, id)
          : commonTrackEvent("GROUP", "Send Message In Group", name, id);

        pubNubCallWithRetry(() => pubnub.publish(encryptedNotificationData)).then((result) => {
          mixpanel.track('Notification Successfully Sent', {
            from: 'WEB',
            response: result,
            channel,
            notificationData: JSON.stringify(notificationData),
          });
        }).catch((error) => {
          mixpanel.track('Error In Notification Sent', {
            from: 'WEB',
            error: error ? JSON.stringify(error) : 'Notification Sent Error',
            channel,
            notificationData: JSON.stringify(notificationData),
          });
        });

        sendNotificationToTaggedUsers({
          channelType,
          channelName: name,
          messageBody,
          channel,
          username,
          message,
          usernameToUserIdMap,
        });

        if (!!replyMessage) {
          mixpanel.track("Replies", {
            Sender_Username: username,
            Replied_Username: replyMessage?.sender,
            GroupName: name,
          });

          sendNotificationForReply({
            replyMessage,
            messageBody,
            username,
            channelType,
            channelName: name,
          });
        }

        resolve(true);
      })
      .catch(e => {
        Sentry.captureException(new Error(`Error on publish Text :: ${e}`));
        channelType === "DIRECT"
          ? commonTrackEvent("DIRECT", "Message fails to send in DM", name, id)
          : commonTrackEvent(
            "GROUP",
            "Message fails to send in Group",
            name,
            id
          );
        NotificationStore.setNotification(
          "error",
          "The following message wasn't sent",
          message,
          0
        );
        reject(false);
      });
  })
}

const getTaggedUserIds = (message, usernameToUserIdMap) => {
  let taggedUserId = [];

  const matches = message.match(/@[A-Za-z0-9-_]*/g);

  if (matches) {
    matches.forEach(match => {
      const username = match.slice(1);
      if (usernameToUserIdMap.has(username)) {
        taggedUserId.push(usernameToUserIdMap.get(username));
      }
    });
  }

  return taggedUserId;
};

const sendNotificationToTaggedUsers = ({
  channelType,
  channelName,
  messageBody,
  channel,
  username,
  message,
  usernameToUserIdMap,
}) => {
  const titleForUser = `New Mention in ${channelName}`;

  const notificationData = {
    message: messageBody,
    title: titleForUser,
    body: messageBody,
    channel,
    notificationType: `${channelType}-TEXT MESSAGE`,
    waitingRoomName: channelType === 'WAITING' ? username : ''
  };

  const taggedUserIds = getTaggedUserIds(message, usernameToUserIdMap);

  taggedUserIds.forEach(taggedUserId => {
    const tagPublishPayload = {
      message: generateNotificationMessage(notificationData),
      channel: `USER_PUSH_${taggedUserId}`,
      storeInHistory: false,
    };

    sendNotificationWithPubNubAuth(
      `USER_PUSH_${taggedUserId}`,
      tagPublishPayload,
    ).then((result) => {
      mixpanel.track('Tag Notification Successfully Sent', {
        from: 'WEB',
        response: result,
        channel: `USER_PUSH_${taggedUserId}`,
        notificationData: JSON.stringify(tagPublishPayload),
      });
    }).catch((error) => {
      mixpanel.track('Error In Tag Notification Sent', {
        from: 'WEB',
        error: error ? JSON.stringify(error) : 'Tag Notification Sent Error',
        channel: `USER_PUSH_${taggedUserId}`,
        notificationData: JSON.stringify(tagPublishPayload),
      });
    });
  });
}

const sendNotificationForReply = ({
  replyMessage,
  messageBody,
  username,
  channelType,
  channelName,
}) => {
  const channel = `USER_PUSH_${replyMessage?.userId}`;
  if (replyMessage) {
    const titleForReply = `${username} replied to your message`;
    const newNotificationData = {
      message: messageBody.replace(/\n/g, " "),
      title: titleForReply,
      body: messageBody.replace(/\n/g, " "),
      channel,
      notificationType: `${channelType}-TEXT MESSAGE`,
      groupName: channelName,
      sender: username,
    };
    const replyNotificationPayload = {
      message: generateNotificationMessage(newNotificationData),
      channel: `USER_PUSH_${replyMessage?.userId}`,
      storeInHistory: false,
    };

    sendNotificationWithPubNubAuth(
      `USER_PUSH_${replyMessage?.userId}`,
      replyNotificationPayload,
    ).then((result) => {
      mixpanel.track('Reply Notification Successfully Sent', {
        from: 'WEB',
        response: result,
        channel: `USER_PUSH_${replyMessage?.userId}`,
        notificationData: JSON.stringify(replyNotificationPayload),
      });
    }).catch((error) => {
      mixpanel.track('Error In Reply Notification Sent', {
        from: 'WEB',
        error: error ? JSON.stringify(error) : 'Reply Notification Sent Error',
        channel: `USER_PUSH_${replyMessage?.userId}`,
        notificationData: JSON.stringify(replyNotificationPayload),
      });
    });
  }
};

export const getOnlineUsers = (pubnub, channel) => {
  return new Promise((resolve, reject) => {
    pubNubCallWithRetry(() => pubnub
      .hereNow({
        channels: [channel],
        includeUUIDs: true,
        includeState: true,
      }))
      .then((response) => {
        resolve(response.channels);
      })
      .catch((error) => { });
  });
};

export const fetchViewInContext = (pubnub, callback = null, messageList) => {
  const {
    selectedGroup: {
      channel,
      addOldMessage,
      start,
      setLoading,
      setStart,
      setEnd,
      setHasMore,
      fetchReactions,
    },
    setViewportMessagesLoaded,
  } = MessagesStore;
  let count = 5;
  let prevScrollHeight;
  setLoading(true);
  pubNubCallWithRetry(() => pubnub
    .history({
      channel,
      start: sub(String(start), "1"),
      reverse: true,
      count,
      stringifiedTimeToken: true,
    }))
    .then((response) => {
      count = 4;
      setEnd(add(response.endTimeToken, "1"));
      _.reverse(response.messages).map((message) => {
        if (message.entry.encrypted) {
          const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
          return addOldMessage(decrypted);
        } else {
          return addOldMessage(message);
        }
      });

      prevScrollHeight = messageList ? messageList.current.scrollHeight : null;
      pubNubCallWithRetry(() => pubnub
        .history({
          channel,
          start,
          reverse: false,
          count,
          stringifiedTimeToken: true,
        }))
        .then((response) => {
          setViewportMessagesLoaded(true);
          setStart(response.startTimeToken);
          fetchReactions();
          if (response.messages.length < count) {
            setHasMore(false);
          }
          _.reverse(response.messages).map((message) => {
            if (message.entry.encrypted) {
              const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
              return addOldMessage(decrypted);
            } else {
              return addOldMessage(message);
            }
          });
        })
        .then(() => {
          if (callback) {
            callback(prevScrollHeight);
          }
        });
    })
    .catch((error) => { });
};

export const getMessageInfo = (pubnub, data) => {
  return new Promise((resolve, reject) => {
    pubNubCallWithRetry(() => pubnub
      .history({
        channel: data.channel,
        stringifiedTimeToken: true,
        start: sub(data.messageId, "1"),
        end: add(data.messageId, "1"),
      }))
      .then((response) => {
        const decryptedMessages = response.messages.map((message) => {
          if (message.entry.encrypted) {
            return decryptMessage(pubnub, _.cloneDeep(message));
          } else return message;
        });
        response.messages = decryptedMessages;
        resolve(response);
      })
      .catch((error) => {
        Sentry.captureException(
          new Error(`Error on Fetching MessageInfo :: ${error.message}`)
        );
        reject(error);
      });
  });
};

export const sendNotificationAndSaveUserForms = (
  pubnub,
  formId,
  response,
  channel
) => {
  const { timetoken } = response;
  const channelType = channel.split("_")[0];
  const FORM_TITLE = "Survey";
  const FORM_MESSAGE = "You have a new survey to complete";
  const FORM_ACTION_TYPE = "NEW_FORM";
  const data = {
    message: FORM_MESSAGE,
    body: FORM_MESSAGE,
    title: FORM_TITLE,
    channel,
    actionType: FORM_ACTION_TYPE,
    formId: formId.toString(),
    notificationType: `${channelType}-FORM`,
    messageId: timetoken,
  };
  const publishPayload = {
    message: generateNotificationMessage(data),
    storeInHistory: false,
    channel,
  };
  const encryptedData = encryptedPublish(pubnub, publishPayload);
  pubNubCallWithRetry(() => pubnub
    .publish(encryptedData))
    .then((res) => {
      let userFormPromises = [];
      if (channelType === "GROUP") {
        userFormPromises = FormMessageStore.saveUserFormsForGroup(
          formId,
          channel,
          response
        );
      } else if (channelType === "DIRECT") {
        userFormPromises = FormMessageStore.saveUserFormsForDms(
          formId,
          channel,
          response
        );
      } else if (channelType === "WAITING") {
        userFormPromises = FormMessageStore.saveUserFormsForWaitingRoom(
          formId,
          channel,
          response
        );
      }

      Promise.all(userFormPromises).catch(() => {
        const start = sub(String(response.timetoken), "1");
        const end = add(String(response.timetoken), "1");
        pubnub.deleteMessages({ channel, start, end }, (result) => { });
      });
    })
    .catch((err) => { });
};

export const publishForm = (pubnub, formId) => {
  const {
    selectedGroup: { channel, userType },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const encryptedData = encryptedPublish(pubnub, {
    message: {
      encrypted: true,
      type: "form",
      formId: formId,
      sender: username,
      userId: userId,
      userType: userType,
    },
    channel,
  });

  pubNubCallWithRetry(() => pubnub
    .publish(encryptedData))
    .then(async (response) => {
      await sendNotificationAndSaveUserForms(pubnub, formId, response, channel);
    })
    .catch((err) => { });
};

export const getToken = (position) => {
  const {
    selectedGroup: { messages },
  } = MessagesStore;
  const { timetoken } =
    position === "bottom" ? messages[messages.length - 1] : messages[0];
  return timetoken;
};

export const fetchBottomMessages = (pubnub, callback = null) => {
  const {
    selectedGroup: {
      channel,
      fetchBottomMessagesReactions,
      addNewerMessages,
      setHasBottomMessages,
    },
  } = MessagesStore;
  const latestToken = getToken("bottom");
  const count = 25;
  pubNubCallWithRetry(() => pubnub
    .history({
      channel,
      start: latestToken,
      reverse: true,
      count,
      stringifiedTimeToken: true,
    }))
    .then((response) => {
      fetchBottomMessagesReactions(
        response.startTimeToken,
        add(response.endTimeToken, "1")
      );
      if (response.messages.length < count) {
        setHasBottomMessages(false);
      }
      response.messages.map((message) => {
        if (message.entry.encrypted) {
          const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
          return addNewerMessages(decrypted);
        } else {
          return addNewerMessages(message);
        }
      });
    })
    .catch((err) => {
      Sentry.captureException(
        new Error(`Error on Fetching BottomMessages :: ${err.message}`)
      );
    });
};

export const fetchLatestMessageForChannels = async (params = {}) => {
  try {
    const { pubnub, channels = [] } = params;
    const response = await pubNubCallWithRetry(() => pubnub.fetchMessages({
      channels,
      end: Date.now().toString(),
      count: 1
    }));
    let subscribedChannels = [];
    if (response && response.channels) {
      const responseChannels = response.channels;
      for (const key in responseChannels) {
        const channelArr = responseChannels[key];
        if (channelArr) {
          subscribedChannels.push(...channelArr);
        }
      }
    }
    if (subscribedChannels.length) {
      subscribedChannels = subscribedChannels.map(({ channel, ...rest }) => {
        const channelNamesArr = channel.split('_');
        const channelId = channelNamesArr[channelNamesArr.length - 1];
        return {
          channelId: +channelId,
          channel,
          ...rest
        };
      });
    }
    return subscribedChannels;

  } catch (error) {
    throw error;
  }
};

export const fetchLastMessage = async (pubnub, channels, isResolved) => {
  const { addLastMessages } = MemberListStore;
  await subscribeChannels(pubnub, channels);
  pubNubCallWithRetry(() => pubnub
    .fetchMessages({
      channels,
      start: (Date.now() * 10000).toString(),
      count: 1,
    }))
    .then((response) => {
      Object.keys(response.channels).map((key) => {
        const obj = response.channels[key][0];

        if (obj.message.encrypted) {
          const decrypted = decryptMessage(pubnub, _.cloneDeep(obj.message));
          return addLastMessages(
            obj.channel,
            obj.timetoken,
            decrypted,
            isResolved
          );
        } else {
          return addLastMessages(
            obj.channel,
            obj.timetoken,
            obj.message,
            isResolved
          );
        }
      });
    })
    .catch((error) => { });
};

export const subscribeSingleChannelWithPresence = async (pubnub, channel) => {
  mixpanel.track('Single Channel Subscribe With Presence', { from: 'WEB', channel });
  try {
    await pubNubCallWithRetry(() => pubnub.subscribe({
      channels: [channel]}
    ))
  } catch (error) {
    mixpanel.track('Pubnub subscribe failure', { from: 'WEB', channels: [channel], error });
    Sentry.captureException(
      new Error(
        `Error on subscribeSingleChannelWithPresence  :: ${JSON.stringify(
          error
        )}`
      )
    );
  }
};

export const addListener = async (pubnub, callback = null, forceRegister = false) => {
  const { userId, username } = AuthStore;

  if (existingListener) {
    if (callback === null && !forceRegister) {
      return;
    }
    await pubNubCallWithRetry(() => pubnub.removeListener(existingListener));
  }
  existingListener = {
    message: (encryptedChannelMessage) => {
      const channelMessage = decryptMessage(pubnub, encryptedChannelMessage);
      const { selectedGroup } = MessagesStore;
      if (channelMessage.message.messageType === "SIGNAL") {
        if (signalHandler) {
          signalHandler(channelMessage.message);
        }
      } else if (_.startsWith(channelMessage.channel, NOTIFICATION_TYPE.FORUM_PUSH)) {
        showBrowserNotification(channelMessage.message.pn_fcm.android.data);

        mixpanel.track("Received Push Notification", {
          from: "WEB",
          userEnvironment: ENVIRONMENT,
          ...channelMessage,
        });
      } else {
        const newMsgObj = {
          timetoken: channelMessage.timetoken,
          entry: channelMessage.message,
        };
        if (
          channelMessage.channel === selectedGroup.channel &&
          channelMessage.message.type
        ) {
          let bannerIds = [];
          let formIds = [];
          if (channelMessage.message.type === "add") {
            bannerIds.push({
              groupId: channelMessage.message.groupId,
            });
          }
          if (channelMessage.message.type === "form") {
            formIds.push({
              formId: channelMessage.message.formId,
              timetoken: channelMessage.timetoken,
            });
          }
          setTimeout(() => fetchFormsData(formIds), 500);
          FormMessageStore.getUserForms(formIds);
          FormMessageStore.getFormName(formIds);
          FormMessageStore.getCountOfFormSubmittedByUserService(formIds);
          FormMessageStore.getCountOfFormSentToUsersService(formIds);
          GroupStore.fetchBanner(bannerIds);
          selectedGroup.addMessage(newMsgObj);
        }

        if (
          channelMessage &&
          channelMessage.message &&
          channelMessage.message.pn_fcm &&
          channelMessage.message.pn_fcm.android &&
          channelMessage.message.pn_fcm.android.data
        ) {
          if (channelMessage.message.sender !== AuthStore.username) {
            showBrowserNotification(channelMessage.message.pn_fcm.android.data);

            const { notificationType = "UNKNOWN", title, body, channel, channelName } = channelMessage;

            mixpanel.track("Received Push Notification", {
              from: "WEB",
              notificationType,
              userEnvironment: ENVIRONMENT,
              ...channelMessage,
              title,
              body,
              channel: channel ?? channelName,
            });

            if (
              !(
                channelMessage.message.messageType === "SIGNAL" ||
                (Object.keys(channelMessage.message).length === 2 &&
                  channelMessage.message.hasOwnProperty("pn_fcm") &&
                  channelMessage.message.hasOwnProperty("pn_apns"))
              )
            ) {
              if (_.startsWith(channelMessage.channel, "WAITING_ROOM")) {
                MemberListStore.toggleUnread(channelMessage);
              } else {
                MessagesStore.setUnreadMessage(channelMessage.channel);
              }
            }
          }
        }
        MessagesStore.saveLatestMessage(channelMessage.channel, newMsgObj);
        MessagesStore.sortLatestMessages();
        if (_.startsWith(channelMessage.channel, "DIRECT_MESSAGE")) {
          AuthStore.sortDms();
          // If value of "isRepliedFilterSelected" is true then we will set it to false
          AuthStore.setIsRepliedFilterSelected(false);
          // Here we will reset the search value of DM
          MessagesStore.updateQueryString('searchedDmQuery', '');
          // Then we will fetch all dms related to loggedin user
          AuthStore.fetchDMsForSpecificUser();
        } else {
          AuthStore.sortGroups();
          SiteStore.sortSelectedSiteGroups();
          SiteStore.sortAllGroups();
        }

        MessagesStore.toggleNewMessage();
        if (callback) {

          // save coming notification state
          const {
            setCurrentNotificationChannel,
            setCurrentNotificationTab
          } = MessagesStore;

          if (channelMessage?.channel) {
            setCurrentNotificationChannel(channelMessage.channel);
            let notificationTab = null;
            if (channelMessage.channel.includes('DIRECT')) {
              notificationTab = "dm";
            } else if (channelMessage.channel.includes('GROUP')) {
              notificationTab = "group";
            }
            if (notificationTab) {
              setCurrentNotificationTab(notificationTab);
            }
          }
          callback();
        }
      }
    },
    presence: (presence) => {
      const {
        selectedGroup: { channel, saveLastSeen },
      } = MessagesStore;
      const { updateOnlineStatus } = GroupListStore;
      if (presence && presence.state) {
        if (presence.state.isOnline) {
          updateOnlineStatus(true, presence.uuid);
        } else {
          updateOnlineStatus(false, presence.uuid);
        }
      }
    },
    status: async (s) => {
      if (s.error) {
        console.log("PUBNUB STATUS ERROR:");
        console.log(s);
      }

      console.log(`PubNub SDK Status Change: ${s.category}`);
      mixpanel.track("PubNub SDK Status Change", {
        from: "WEB",
        sdkStatus: s.category,
        userId,
        username,
      });

      if (s.error === "forbidden operation.") {
        const currentPubNubToken = await getCurrentPubNubToken();
        await refreshPubNubAuthToken(currentPubNubToken, true);
      }
    },
  };
  await pubNubCallWithRetry(() => pubnub.addListener(existingListener));
};

emitter.on("REFRESH_PUBNUB_LISTENER", async () => {
  const pubnub = getPubnubInstanceByUserType(AuthStore.type);
  await addListener(pubnub, null, true);
  await subscribeSingleChannelWithPresence(pubnub, `USER_PUSH_${AuthStore.userId}`);
  // Subscribing USER_PUSH_ channel here is a workaround to solve issue with addListener not working after generating new pubnub token
});

export const showBrowserNotification = (notificationData) => {
  try {
    const platform = window.navigator.platform;

    // do not show self triggered notifications
    if (AuthStore.username !== notificationData?.sender) {
      // restrict trigger feed channel notifications: i.e. ISLAND_FEED or ISLAND_FEED_<timetoken>
      if (!notificationData.channel.includes(getFeedChannel())) {
        // show notification if windows OS detected
        if (platform.includes('Win')) {
          NotificationStore.setNotificationMeta(notificationData);
          NotificationStore.setShowAlertNotification(true);
        }
        notificationData['createdAt'] = moment().toISOString();
        NotificationStore.saveNotification(notificationData);
      }
    }

    const title = notificationData.title;
    const options = {
      body: notificationData.message,
      tag: notificationData.channel,
      icon: "favicon.ico",
    };
    let notification = false;

    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        mixpanel.track("Notification Permission Granted", { from: "WEB" });
        notification = new Notification(title, options);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
          mixpanel.track("Notification Permission Denied", { from: "WEB" });
          if (permission === "granted") {
            notification = new Notification(title, options);
          }
        });
      }
      if (notification) {
        notification.onclick = () => {
          window.focus();
          notification.close();
        };
      }
    }
  } catch (err) { }
};

export const fetchFormsData = (formIds) => {
  FormMessageStore.getUserForms(formIds);
  FormMessageStore.getFormName(formIds);
  FormMessageStore.getCountOfFormSubmittedByUserService(formIds);
  FormMessageStore.getCountOfFormSentToUsersService(formIds);
};

export const history = (pubnub, callback = null, prevScrollHeight = null) => {
  const {
    selectedGroup: {
      channel,
      addOldMessages,
      start,
      setHistoryLoading,
      setStart,
      setEnd,
      setHasMore,
      messages,
      fetchReactions,
    },
    setIsFirstPage
  } = MessagesStore;

  const { userId, username } = AuthStore;

  const count = 100;
  const logPayload = {
    from: 'WEB',
    userId,
    username,
    channel,
    start,
    count,
    now: new Date().toISOString()
  };

  mixpanel.track(`loading pubnub history for username ${username}`, logPayload);
  mixpanel.time_event(`Loaded pubnub history for username ${username}`, logPayload);
  setHistoryLoading(true);
  pubNubCallWithRetry(() => pubnub
    .history({
      channel,
      start,
      reverse: false,
      count,
      stringifiedTimeToken: true,
    }))
    .then((response) => {
      mixpanel.track(`Loaded pubnub history for username ${username}`, logPayload);
      if (!messages.length) {
        setStart(response.startTimeToken);
      } else {
        setStart(response.startTimeToken);
        setEnd(add(response.endTimeToken, "1"));
      }
      fetchReactions();
      if (response.messages.length < count) {
        setHasMore(false);
      }
      let formIds = [],
        bannerIds = [];
      let oldMessages = [];
      _.reverse(response.messages).map((message) => {
        if (message.entry.encrypted) {
          const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
          if (decrypted.entry.type === "form") {
            formIds.push({
              formId: decrypted.entry.formId,
              timetoken: decrypted.timetoken,
            });
          } else if (decrypted.entry.type === "add") {
            bannerIds.push({
              groupId: decrypted.entry.groupId,
            });
          }
          oldMessages.unshift(decrypted);
        } else {
          if (message.entry.type === "form") {
            formIds.push({
              formId: message.entry.formId,
              timetoken: message.timetoken,
            });
          } else if (message.entry.type === "add") {
            bannerIds.push({
              groupId: message.entry.groupId,
            });
          }
          oldMessages.unshift(message);
        }
      });

      addOldMessages(oldMessages);

      FormMessageStore.getUserForms(formIds);
      FormMessageStore.getFormName(formIds);
      FormMessageStore.getCountOfFormSubmittedByUserService(formIds);
      FormMessageStore.getCountOfFormSentToUsersService(formIds);
      GroupStore.fetchBanner(bannerIds);
      if (callback && prevScrollHeight) {
        callback(prevScrollHeight);
      }
      setHistoryLoading(false);
    })
    .catch((err) => {
      Sentry.captureException(
        new Error(`Error on Fetching History :: ${err.message}`)
      );
      setHistoryLoading(false);
    });

  if (messages.length > 0) {
    setIsFirstPage(false);
  }
};

export const fetchFeedHistory = async (pubnub) => {
  try {
    FeedStore.setLoading(true);
    const count = FEED_RECORD_PER_PAGE;

    // fetch latest messages (e.g. last 20)
    const response = await pubNubCallWithRetry(() => pubnub.fetchMessages({
      channels: [FEED_CHANNEL],
      start: null,
      count,
      stringifiedTimeToken: true,
      includeMessageActions: true
    }));

    // destruct messages from response
    const messages = response.channels.hasOwnProperty(FEED_CHANNEL) ? response.channels[FEED_CHANNEL] : [];
    if ((messages?.length) < count) {
      FeedStore.setHasMore(false);
    } else {
      FeedStore.setHasMore(true);
    }

    // decrypt messages
    let decryptedFeeds = [];
    if (messages) {
      decryptedFeeds = await decryptMessages(pubnub, messages);
    }

    // set feeds data
    decryptedFeeds = await addUserBadgeKeyToReactions(decryptedFeeds);
    // sort by time
    decryptedFeeds = decryptedFeeds.sort((a, b) => b.message.time - a.message.time);
    FeedStore.setFeeds(decryptedFeeds);
    // attach listner for messages coming to feed channel
    await addFeedEventListener(pubnub);

    // set last message timetoken for pagination
    if (decryptedFeeds.length > 0) {
      FeedStore.setStart(decryptedFeeds[decryptedFeeds.length - 1].timetoken);
    }

    // set loading false
    FeedStore.setLoading(false);
  } catch (err) {
    // set loading false
    FeedStore.setLoading(false);
    // log err in sentry
    Sentry.captureException(
      new Error(`Error on Fetching Feed History :: ${err.message}`)
    );
  }
};

export const loadMoreFeedHistory = async (pubnub) => {
  try {
    const count = FEED_RECORD_PER_PAGE;

    // fetch latest messages (e.g. last 20)
    const response = await pubNubCallWithRetry(() => pubnub.fetchMessages({
      channels: [FEED_CHANNEL],
      start: FeedStore.start,
      count,
      stringifiedTimeToken: true,
      includeMessageActions: true
    }));

    // destruct messages from response
    const messages = response.channels[FEED_CHANNEL];
    if (messages.length < count) {
      FeedStore.setHasMore(false);
    }

    // decrypt messages
    let decryptedFeeds = await decryptMessages(pubnub, messages);

    // set feeds data
    decryptedFeeds = await addUserBadgeKeyToReactions(decryptedFeeds);

    // sort by time
    decryptedFeeds = decryptedFeeds.sort((a, b) => b.message.time - a.message.time);

    // set feeds data
    FeedStore.setFeeds([...FeedStore.feeds, ...decryptedFeeds]);

    // set last message timetoken for pagination
    FeedStore.setStart(decryptedFeeds[decryptedFeeds.length - 1].timetoken);
  } catch (err) {
    // log err in sentry
    Sentry.captureException(
      new Error(`Error on Paginate Feed History :: ${err.message}`)
    );
  }
};

const decryptMessages = async (pubnub, messages) => {
  // decrypt messages
  let decryptedFeeds = [];
  await Promise.all(messages.map((item) => {
    const decrypted = decryptMessage(pubnub, _.cloneDeep(item));
    decryptedFeeds.push(decrypted);
  }));
  return decryptedFeeds;
}

const addFeedEventListener = async (pubnub) => {
  let existingListener = {
    message: async (encryptedChannelMessage) => {
      let channelMessage = decryptMessage(pubnub, encryptedChannelMessage);
      if (channelMessage.message.hasOwnProperty('pn_apns') && channelMessage.message.hasOwnProperty('pn_fcm')) {
        return;
      }

      // new feed listner
      if (channelMessage.channel === FEED_CHANNEL) {
        // do not push in array of feeds if item already exists (Sometime getting two time events)
        const isExists = FeedStore.feeds.find(item => item.timetoken === channelMessage.timetoken);
        if (!isExists) {
          // append new feed to store
          FeedStore.setFeeds([channelMessage, ...FeedStore.feeds]);
        }
      }

      // new comment listner
      if (channelMessage.channel === `${FEED_CHANNEL}_${FeedStore.selectedFeedTimetoken}`) {
        // append new feed to store

        // add user badgeType to message object in channelMessage
        channelMessage = await addUserBadgeKeyToObject(channelMessage)

        // do not push in array of comments if item already exists (Sometime getting two time events)
        const isExists = FeedStore.comments.find(item => item.timetoken === channelMessage.timetoken);
        if (!isExists) {
          // prepend new comment to store
          FeedStore.setComments([...FeedStore.comments, channelMessage]);
        }

        // set load more comment flag
        if (FeedStore.comments < FEED_COMMENT_PER_PAGE) {
          FeedStore.setHasMoreComments(false);
        }
      }
    },
    messageAction: async (ma) => {
      let {
        data: {
          actionTimetoken,
          uuid,
          messageTimetoken,
          type,
          value
        },
        event,
        channel
      } = ma;
      let feeds = _.cloneDeep(FeedStore.feeds);
      let comments = FeedStore.comments;

      // find affected feed
      const index = feeds.findIndex(item => item.timetoken === messageTimetoken);

      // feed messageAction
      if (channel === FEED_CHANNEL) {
        // new comment added
        if (type === COMMENT) {

          // add user badgeType key into value variable
          value = await addUserBadgeKeyToReaction(value)

          let obj = {};
          obj[value] = [{ actionTimetoken, uuid }];
          if (feeds[index].actions) {
            // actions already exists
            if (feeds[index].actions.comment) {
              // comment already exists - update
              feeds[index].actions.comment = obj;
            } else {
              // add comment object
              feeds[index].actions = {
                comment: obj
              }
            }
          } else {
            // create action and add comment object
            feeds[index]['actions'] = {
              comment: obj
            }
          }
        } else if (event === "added" && type === DELETE) {
          // add comment delete object to feeds data
          let obj = {};
          obj[value] = [{ actionTimetoken, uuid }];

          // add delete object
          feeds[index].actions = {
            ...feeds[index].actions,
            delete: obj
          }
        } else if (event === "removed" && type === DELETE) {
          // remove delete action from feed object
          const i = feeds.findIndex(item => item.timetoken === messageTimetoken);
          if (i >= 0 && feeds[i].actions) {
            delete feeds[index].actions[DELETE];
          }
        }
        FeedStore.setFeeds([...feeds]);
      }

      // feedComment messageAction
      if (channel === `${FEED_CHANNEL}_${FeedStore.selectedFeedTimetoken}`) {
        // find affected comment
        const index = comments.findIndex(item => item.timetoken === messageTimetoken);

        if (event === "added" && type === DELETE) {
          // add comment delete object to feeds data
          let obj = {};
          obj[value] = [{ actionTimetoken, uuid }];

          // add delete object
          comments[index].actions = {
            ...comments[index].actions,
            delete: obj
          }
        } else if (event === "removed" && type === DELETE) {
          // remove delete action from comment object
          const i = comments.findIndex(item => item.timetoken === messageTimetoken);
          if (comments[i].actions) {
            delete comments[index].actions[DELETE];
          }
        }
        FeedStore.setComments([...comments]);
      }
    },
    presence: (presence) => {

    },
  };
  await pubNubCallWithRetry(() => pubnub.addListener(existingListener));
}

export const subscribeSingleChannel = async (pubnub, channel) => {
  mixpanel.track('Single Channel Subscribe', { from: 'WEB', channel });

  try {
    await pubNubCallWithRetry(() => pubnub.subscribe({
      channels: [channel],
      autoload: 100
    }))
  } catch (error) {
    mixpanel.track('Pubnub subscribe failure', { from: 'WEB', channels: [channel], error });
    Sentry.captureException(
      new Error(
        `Error on subscribeSingleChannel  :: ${JSON.stringify(error)}`
      )
    );
  }
};

export const unsubscribeSingleChannel = async (pubnub, channel) => {
  await pubNubCallWithRetry(() => pubnub.unsubscribe({
    channels: [channel],
  }));
};

export const createModeratorPubnubInstance = async (params) => {
  const { userId } = params;
  const pubnub = new Pubnub({
    publishKey: pubKey,
    subscribeKey: subKey,
    useRandomIVs: IS_ISLAND,
    userId: userId.toString(),
    restore: true,
    enableEventEngine: true,
    retryConfiguration: Pubnub.LinearRetryPolicy({
      delay: 2,
      maximumRetry: 5,
    }),
  });

  const currentPubNubToken = await getCurrentPubNubToken();
  pubnub.setToken(currentPubNubToken);

  return pubnub;
};

export const unsubscribeAll = () => {
  if (pubnubInstances.moderator) {
    pubnubInstances.moderator.unsubscribeAll();
  }
  if (pubnubInstances.personal) {
    pubnubInstances.personal.unsubscribeAll();
  }
};

export const subscribeModeratorChannels = async () => {
  const pubnub = pubnubInstances.moderator;
  if (!pubnub) {
    return;
  }
  let channelsToSubscribe = [];
  channelsToSubscribe.push("ALERTS"); // For signal
  channelsToSubscribe.push("MODERATOR"); // For signal
  await subscribeChannels(pubnub, channelsToSubscribe);
};

export const subscribeUserChannels = async (params) => {
  const { userId } = params;
  const pubnub = pubnubInstances.personal;
  if (!pubnub) {
    return;
  }

  let channelsToSubscribe = [];
  channelsToSubscribe.push(`USER_PUSH_${userId}`); // For signal
  await subscribeChannels(pubnub, channelsToSubscribe);
};

export const subscribeChannels = async (pubnub, channels) => {
  if (!pubnub) {
    return;
  }
  const batchedChannels = getBatchedArray(channels);
  mixpanel.track('Subscribe Channels', { from: 'WEB', channels: batchedChannels });
  for (const batch of batchedChannels) {
    await pubNubCallWithRetry(() => pubnub.subscribe({ channels: batch })).catch(error => {
      mixpanel.track('Pubnub subscribe failure', { from: 'WEB', channels: batch, error });
    });
    setStateInChannel(pubnub, batch, {
      isOnline: false,
    });
  }
};

export const subscribeAllGroupChannels = (pubnub, channels) => {
  if (!pubnub) {
    return;
  }
  const batchedChannels = getBatchedArray(channels);
  mixpanel.track('Subscribe All Group Channels', { from: 'WEB', channels });
  batchedChannels.forEach((batch) => {
    setTimeout(async () => {
      await pubNubCallWithRetry(() => pubnub.subscribe({ channels: batch })).catch(error => {
        mixpanel.track('Pubnub subscribe failure', { from: 'WEB', channels: batch, error });
      });
    }, 500);
  });
};

export const subscribeDMs = async (pubnub, channels) => {
  if (!pubnub) {
    return;
  }
  const batchedChannels = getBatchedArray(channels);
  mixpanel.track('DMs Subscribe', { from: 'WEB', channels });
  for (const batch of batchedChannels) {
    await pubNubCallWithRetry(() => pubnub.subscribe({ channels: batch })).catch(error => {
      mixpanel.track('Pubnub subscribe failure', { from: 'WEB', channels: batch, error });
    });
  }
};

export const getAuthKey = (userId) => {
  return HmacSHA256(`USER_ID_${userId}`, secretKey).toString();
};

export const getModAuthKey = () => {
  return HmacSHA256("MODERATOR", secretKey).toString();
};

export const setStateInChannel = (pubnub, channel, newState) => {
  pubNubCallWithRetry(() => pubnub.setState(
    {
      state: newState,
      channels: [channel],
    })).then();
};

export const publishBanner = (pubnub, groupId) => {
  const {
    selectedGroup: { channel, userType },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const encryptedData = encryptedPublish(pubnub, {
    message: {
      type: "add",
      encrypted: true,
      groupId: groupId,
      sender: username,
      userId: Number(userId),
      userType: userType,
    },
    channel,
  });
  return new Promise(async (resolve, reject) => {
    try {
      await pubNubCallWithRetry(() => pubnub.publish(encryptedData));
      resolve(true);
    } catch (e) {
      Sentry.captureException(new Error(`Error on publish Banner :: ${e}`));
      reject(false);
    }
  });
};

export const publishImage = (pubnub, url, replyingToMessage = null) => {
  const {
    selectedGroup: { channel, name, userType },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const channelType = channel.startsWith("GROUP")
    ? "GROUP"
    : channel.startsWith("WAITING")
      ? "WAITING"
      : "DIRECT";
  const replyMessage = isValidReplyingToMessage(replyingToMessage) ? (replyingToMessage.entry ?? replyingToMessage) : null;
  const encryptedData = encryptedPublish(pubnub, {
    message: {
      replyMessage,
      type: "img",
      encrypted: true,
      imgUrl: url,
      sender: username,
      userId: userId,
      userType: userType,
    },
    channel,
  });
  const info = {
    message: "Photo",
    body: "Photo",
    notificationType: `${channelType}-IMAGE`,
    title: name,
    channel,
    groupName: name,
    mediaUrl: url,
    mediaType: url.split('.').pop(),
    sender: username,
    image: url,
  };
  const notificationData = {
    message: generateNotificationMessage(info),
    storeInHistory: false,
    channel,
  };

  const encryptedNotificationData = encryptedPublish(pubnub, notificationData);
  return new Promise(async (resolve, reject) => {
    try {
      await pubNubCallWithRetry(() => pubnub.publish(encryptedData));

      pubNubCallWithRetry(() => pubnub
        .publish(encryptedNotificationData))
        .then((result) => {
          mixpanel.track('Notification Successfully Sent', {
            from: 'WEB',
            message: 'Photo',
            response: result,
            channel,
            notificationData: JSON.stringify(notificationData),
          });
        })
        .catch((error) => {
          mixpanel.track('Error In Notification Sent', {
            from: 'WEB',
            message: 'Photo',
            error: error ? JSON.stringify(error) : 'Notification Sent Error',
            channel,
            notificationData: JSON.stringify(notificationData),
          });
      });

      if (!!replyMessage) {
        mixpanel.track("Replies", {
          Sender_Username: username,
          Replied_Username: replyMessage?.sender,
          GroupName: name,
        });

        let messageBody = "Photo";
        sendNotificationForReply({
          replyMessage,
          messageBody,
          username,
          channelType,
          channelName: name,
        });
      }
      resolve(true);
    } catch (e) {
      Sentry.captureException(new Error(`Error on publish Image :: ${e}`));
      reject(false);
    }
  });
};

export const publishCustomBanner = (pubnub, message, emojiName) => {
  const {
    selectedGroup: { channel, userType },
  } = MessagesStore;
  const { userId, username } = AuthStore;
  const encryptedData = encryptedPublish(pubnub, {
    message: {
      encrypted: true,
      type: "customBanner",
      emojiName: emojiName ? emojiName : "partyPopper",
      message: message,
      sender: username,
      userId: Number(userId),
      userType: userType,
    },
    channel,
  });
  return new Promise(async (resolve, reject) => {
    try {
      await pubNubCallWithRetry(() => pubnub.publish(encryptedData));
      resolve(true);
    } catch (e) {
      Sentry.captureException(new Error(`Error on publish Banner :: ${e}`));
      reject(false);
    }
  });
};

export const latestMessage = (pubnub, channel) => {
  const { saveLatestMessage } = MessagesStore;

  return pubNubCallWithRetry(() => pubnub.history({
      channel,
      reverse: false,
      count: 1,
      stringifiedTimeToken: true,
    }))
    .then(response => {
      response.messages.map((message) => {
        if (message.entry.encrypted) {
          const decrypted = decryptMessage(pubnub, _.cloneDeep(message));
          return saveLatestMessage(channel, decrypted);
        } else {
          return saveLatestMessage(channel, message);
        }
      });
    })
    .catch(e => {
      Sentry.captureException(
        new Error(`Error on Fetching latestMessage :: ${e}`)
      );
    });
};

export const publishTextPost = async (pubnub, text) => {
  const { userId, username } = AuthStore;

  const encryptedData = encryptedPublish(pubnub, {
    message: {
      type: "text",
      encrypted: true,
      sender: username,
      userId: userId,
      groupName: FEED_CHANNEL,
      text: text,
      time: new Date().getTime(),
    },
    channel: FEED_CHANNEL
  });
  await pubNubCallWithRetry(() => pubnub.publish(encryptedData));
}

export const publishMediaPost = async (pubnub, payload) => {
  const { userId, username } = AuthStore;

  const encryptedData = encryptedPublish(pubnub, {
    message: {
      type: "img",
      encrypted: true,
      sender: username,
      userId: userId,
      groupName: FEED_CHANNEL,
      time: new Date().getTime(),
      ...payload
    },
    channel: FEED_CHANNEL,
  });
  await pubNubCallWithRetry(() => pubnub.publish(encryptedData));
}

export const fetchComments = async (pubnub) => {
  try {
    // reset state before fetching new data;
    FeedStore.resetFeedCommentMeta();
    FeedStore.setFetchCommentLoading(true);
    const count = FEED_COMMENT_PER_PAGE;

    let timetoken = FeedStore.selectedFeedTimetoken;

    // fetch latest messages (e.g. last ${count} messages)
    const response = await pubNubCallWithRetry(() => pubnub.fetchMessages({
      channels: [`${FEED_CHANNEL}_${timetoken}`],
      start: null,
      count,
      stringifiedTimeToken: true,
      includeMessageActions: true
    }));

    // destruct messages from response
    const messages = response.channels[`${FEED_CHANNEL}_${timetoken}`];

    if (messages.length < count) {
      FeedStore.setHasMoreComments(false);
    } else {
      FeedStore.setHasMoreComments(true);
    }
    // decrypt messages
    let decryptedComments = await decryptMessages(pubnub, messages);

    // set user badgeType key to obj
    decryptedComments = await addUserBadgeKey(decryptedComments);

    // set feeds data
    FeedStore.setComments(decryptedComments)

    // set last message timetoken for pagination
    FeedStore.setStartComment(decryptedComments[0].timetoken);

    // set loading false
    FeedStore.setFetchCommentLoading(false);
  } catch (err) {
    // set loading false
    FeedStore.setFetchCommentLoading(false);

    // log err in sentry
    Sentry.captureException(
      new Error(`Error on Fetching Feed History :: ${err.message}`)
    );
  }
};

export const loadMoreComments = async (pubnub) => {
  try {
    const count = FEED_COMMENT_PER_PAGE;
    let timetoken = FeedStore.selectedFeedTimetoken;
    FeedStore.setLoadMoreLoading(true);

    // fetch latest messages (e.g. last 20)
    const response = await pubNubCallWithRetry(() => pubnub.fetchMessages({
      channels: [`${FEED_CHANNEL}_${timetoken}`],
      start: FeedStore.startComment,
      count,
      stringifiedTimeToken: true,
      includeMessageActions: true
    }));

    // destruct messages from response
    const messages = response.channels[`${FEED_CHANNEL}_${timetoken}`];
    if (messages.length < count) {
      FeedStore.setHasMoreComments(false);
    }

    // decrypt messages
    let decryptedComments = await decryptMessages(pubnub, messages);

    // set user badgeType key to obj
    decryptedComments = await addUserBadgeKey(decryptedComments);

    // set feeds data
    FeedStore.setComments([...decryptedComments, ...FeedStore.comments]);

    // set last message timetoken for pagination
    FeedStore.setStartComment(decryptedComments[0].timetoken);
    FeedStore.setLoadMoreLoading(false);
  } catch (err) {
    FeedStore.setLoadMoreLoading(false);
    // log err in sentry
    Sentry.captureException(
      new Error(`Error on Paginate Feed History :: ${err.message}`)
    );
  }
};

export const addCommentReaction = async (
  pubnub,
  channel,
  timetoken,
  username,
  userId,
  commentMessage,
  count,
  commentTimetoken,
  isDeleted = false
) => {
  try {
    return pubNubCallWithRetry(() => pubnub.addMessageAction({
      channel,
      messageTimetoken: timetoken,
      action: {
        type: COMMENT,
        value: `{"timetoken":"${commentTimetoken}","username":"${username}", "userId": ${userId}, "message":"${commentMessage}","count":"${count}","isDeleted":"${isDeleted}"}`
      }
    }));
  } catch (e) {
    throw e;
  }
};

export const removeCommentReaction = async (
  pubnub,
  channel,
  timetoken,
  actionTimetoken
) => {
  try {
    const comment = await pubNubCallWithRetry(() => pubnub.removeMessageAction({
      channel,
      messageTimetoken: timetoken,
      actionTimetoken: actionTimetoken
    }));
    return comment?.data;
  } catch (e) {
    throw e;
  }
};

export const addDeleteReaction = (
  pubnub,
  channel,
  timetoken,
  userId
) => {
  try {
    return pubNubCallWithRetry(() => pubnub.addMessageAction({
      channel,
      messageTimetoken: timetoken,
      action: {
        type: DELETE,
        value: `${userId}`
      }
    }));
  } catch (e) {
    throw e;
  }
};

export const removeDeleteReaction = (
  pubnub,
  channel,
  timetoken,
  actionTimetoken
) => {
  try {
    return pubNubCallWithRetry(() => pubnub.removeMessageAction({
      channel,
      messageTimetoken: timetoken,
      actionTimetoken: actionTimetoken
    }));
  } catch (e) {
    throw e;
  }
};


const addUserBadgeKeyToReactions = async (data) => {
  try {
    let userBadges = UserBadgesStore.userBadges;
    if (!userBadges.length) {
      userBadges = await UserBadgesStore.fetchUserBadges();
    }

    // iterate over array of object of feed
    await Promise.all(data.map(d => {
      // take comment object from action
      let commentObj = d?.actions?.comment;
      if (commentObj) {
        let userBadge = null;

        let key = Object.keys(commentObj)[0];

        // escape new line
        const escapedKey = jsonEscape(key);
        const value = commentObj[key];

        // parse comment
        let comment = JSON.parse(escapedKey);
        let { userId } = comment;

        // check if given userId has badge?
        if (userId) {
          userBadge = userBadges.find(item => item.id === userId);
        }
        // assign userBadge key to parsed comment
        comment.userBadge = userBadge?.badgeType || null;

        // stringify comment and replace comment over action
        let s = JSON.stringify(comment);
        let obj = {};
        obj[s] = value;
        d.actions.comment = obj;
      }
    }));
    return data;
  } catch (e) {
    throw e
  }
}

const addUserBadgeKeyToReaction = async (value) => {
  try {
    let userBadges = UserBadgesStore.userBadges;
    if (!userBadges.length) {
      userBadges = await UserBadgesStore.fetchUserBadges();
    }

    let userBadge = null;

    // escape new line and double quotes
    value = jsonEscape(value);

    let comment = JSON.parse(value);

    let { userId } = comment;

    // check if given userId has badge?
    if (userId) {
      userBadge = userBadges.find(item => item.id === userId);
    }
    // assign userBadge key to parsed comment
    comment.userBadge = userBadge?.badgeType || null;

    // stringify comment and replace comment over action
    value = JSON.stringify(comment);
    return value;
  } catch (e) {
    throw e;
  }
}

const addUserBadgeKey = async (data) => {
  try {
    let userBadges = UserBadgesStore.userBadges;
    if (!userBadges.length) {
      userBadges = await UserBadgesStore.fetchUserBadges();
    }
    await Promise.all(data.map(d => {
      const userInfo = userBadges.find(item => item.id === d.message.userId);
      d.message.userBadge = userInfo?.badgeType || null;
      d.message.userType = userInfo?.type || null;
    }));
    return data;
  } catch (e) {
    throw e;
  }
}

const addUserBadgeKeyToObject = async (data) => {
  try {
    let userBadges = UserBadgesStore.userBadges;
    if (!userBadges.length) {
      userBadges = await UserBadgesStore.fetchUserBadges();
    }

    const userBadge = userBadges.find(item => item.id === data.message.userId);
    data.message.userBadge = userBadge?.badgeType || null;

    return data;
  } catch (e) {
    throw e;
  }
}

export const generateNotificationMessage = (data) => {
  const { title, body } = data;

  return {
    pn_apns: {
      pn_push: [{ targets: [{ ...APNS2_SETTINGS }], version: "v2" }],
      aps: { alert: { title, body }, "mutable-content": 1, sound: "default" },
      info: { ...data },
    },
    pn_fcm: {
      notification: { title, body },
      android: { data: { ...data }, notification: { sound: 'default' } },
    }
  }
}
