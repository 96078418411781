import { toJS } from "mobx";
import { types } from "mobx-state-tree";

import ApiService from "../utils/ApiService";
import { SELECT_SENDER_PHONE_NUMBER, SENDER_PHONE_NUMBER_LIST, TERRITORIES_TYPE_ALL_TERRITORIES, USER_STATUS_ALL_STATUS } from "../constants/GlobalConstant";
import { USER_BADGES_FLAGS } from "../constants/BadgeConstant";
import { getNormalizedPhoneNumber, getSortedDataByDateSection } from "../utils/CommonUtils";

import NotificationStore from "../stores/NotificationStore";
import { REACT_APP_FROM_NUMBER } from "../configs/EnvConfig";
import AuthStore from "../stores/AuthStore";
var mixpanel = require("mixpanel-browser");

const { ENROLLED_DELAWARE, ENROLLED_HP, ENROLLED_IHP } = USER_BADGES_FLAGS;

const SmsInbox = types
    .model("SmsInbox", {
        loading: false,
        isUsersListLoading: false,
        isUsersDataOnListLoading: false,
        isRepliedFilterSelected: false,
        isAllNonAccountsFilterSelected: false,
        isAllAccountsFilterSelected: false,
        isMyCaseloadSelected: false,
        isSmsHistoryLoading: false,
        isSmsSending: false,
        isBulkSmsSending: false,
        inboxUsersListUpdatedTime: types.maybeNull(types.number),
        selectedTerritoryForSmsInbox: types.maybeNull(types.string),
        selectedSenderPhoneNumber: types.maybeNull(types.string),
        selectedUserStatusForSmsInbox: types.maybeNull(types.string),
        searchString: types.maybeNull(types.string),
        allTerritories: types.array(types.string),
        originalUsersListData: types.array(types.frozen({})),
        userSmsData: types.array(types.frozen({})),
        smsHistoryUpdatedTime: types.maybeNull(types.number),
        usersListData: types.array(types.frozen({
            id: types.maybeNull(types.number),
            text: types.maybeNull(types.string),
            senderId: types.maybeNull(types.number),
            receiverId: types.maybeNull(types.number),
            status: types.maybeNull(types.string),
            createdAt: types.maybeNull(types.string),
            smsSenderFullName: types.maybeNull(types.string),
            smsSenderBadgeType: types.maybeNull(types.string),
            smsSenderUsername: types.maybeNull(types.string),
        })),
        pagination: types.frozen({
            pageSize: 50,
            total: 0,
            current: 1,
        }),
        skipUsersInListing: 0,
        totalUsersInListing: 0,
        defaultPhoneNumber: SENDER_PHONE_NUMBER_LIST[0].split("-")[1]
    })
    .actions((self) => ({
        reset() {
            self.loading = false;
            self.isUsersListLoading = false;
            self.isUsersDataOnListLoading = false;
            self.isRepliedFilterSelected = false;
            self.isAllNonAccountsFilterSelected = false;
            self.isAllAccountsFilterSelected = false;
            self.isMyCaseloadSelected = false;
            self.inboxUsersListUpdatedTime = null;
            self.selectedTerritoryForSmsInbox = null;
            self.selectedSenderPhoneNumber = SENDER_PHONE_NUMBER_LIST[0].split("-")[0].slice(2);
            self.defaultPhoneNumber = SENDER_PHONE_NUMBER_LIST[0].split("-")[1];
            self.selectedUserStatusForSmsInbox = null;
            self.searchString = null;
            self.usersListData = [];
            self.allTerritories = [];
            self.resetPagination();
            self.resetUsersListData();
            self.userSmsData = [];
            self.smsHistoryUpdatedTime = null;
            self.isSmsHistoryLoading = false;
            self.isSmsSending = false;
            self.isBulkSmsSending = false;
        },
        resetUsersListData() {
            self.usersListData = [];
            self.originalUsersListData = [];
            self.skipUsersInListing = 0;
            self.totalUsersInListing = 0;
        },
        async getInboxUsersList(isFirstTimeLoading = true) {
            try {
                // When component mounts then we will be showing loader in left section
                // Otherwise we are not showing loader when any message is being sent to user
                if (isFirstTimeLoading) {
                    self.setIsUsersListLoading(true);
                } else {
                    self.setIsUsersDataOnListLoading(true);
                }
                const { originalUsersListData, skipUsersInListing, searchString, isAllAccountsFilterSelected, isAllNonAccountsFilterSelected, isRepliedFilterSelected, isMyCaseloadSelected, selectedUserStatusForSmsInbox, selectedTerritoryForSmsInbox, selectedSenderPhoneNumber } = self;
                const params = {
                    skip: skipUsersInListing,
                    senderPhoneNumber: REACT_APP_FROM_NUMBER.slice(2,)
                };
                // If value of "isMyCaseloadSelected" is available then we are setting it into params object 
                if (isMyCaseloadSelected) {
                    params['isMyCaseloadFilterActive'] = isMyCaseloadSelected;
                }
                // If value of "isRepliedFilterActive" is available then we are setting it into params object 
                if (isRepliedFilterSelected) {
                    params['isRepliedFilterActive'] = isRepliedFilterSelected;
                }
                // If value of "isAllNonAccountsFilterSelected" is available then we are setting it into params object 
                if (isAllNonAccountsFilterSelected) {
                    params['isAllNonAccountsFilterActive'] = isAllNonAccountsFilterSelected;
                }
                // If value of "isAllAccountsFilterSelected" is available then we are setting it into params object 
                if (isAllAccountsFilterSelected) {
                    params['isAllAccountsFilterActive'] = isAllAccountsFilterSelected;
                }
                // If search value is available in search bar then we are setting it into params object 
                if (searchString) {
                    params['search'] = searchString;
                }
                // By default, "All Territories" will be selected,if value changed from dropdown
                // Then we are passing that particular territory to the API call
                if (selectedTerritoryForSmsInbox && selectedTerritoryForSmsInbox !== TERRITORIES_TYPE_ALL_TERRITORIES) {
                    params['territory'] = selectedTerritoryForSmsInbox;
                }
                // By default "All Status" will be selected, if value changed from dropdown
                // Then we are passing that particular status to the API call
                if (selectedUserStatusForSmsInbox && selectedUserStatusForSmsInbox !== USER_STATUS_ALL_STATUS) {
                    params['badgeType'] = selectedUserStatusForSmsInbox;
                }
                if (selectedSenderPhoneNumber && selectedSenderPhoneNumber !== SELECT_SENDER_PHONE_NUMBER) {
                    params['senderPhoneNumber'] = selectedSenderPhoneNumber;
                }
                // API call to fetch all users list with latest message
                const response = await ApiService.getRequest("sms-inbox", params);
                const { success, data, meta } = response;
                mixpanel.track("Inbox View", { from: "WEB", accessingPeername: AuthStore.username, accessingPeerId: AuthStore.userId });
                if (success) {
                    const { limit, total } = meta;
                    // Here we are setting pagination related data
                    self.setUsersListingMeta({ skipUsers: self.skipUsersInListing + limit, totalUsers: total });
                    // We are updating last updated time after every reload
                    self.setInboxUsersListUpdatedTime(Date.now());
                    // Here we are taking previous users data and current users data to append
                    const cloneArr = [...toJS(originalUsersListData), ...data];
                    // Here we are applying the sorted users data by date section
                    const sortedUsers = getSortedDataByDateSection(cloneArr);
                    // We will set the users list data to mobx state once it is grouped by date
                    self.setUsersListData(sortedUsers);
                    // We will set backup of original users data
                    self.setOriginalUsersListData(data);
                }
                // Changing the state of loading if first time loads
                if (isFirstTimeLoading) {
                    self.setIsUsersListLoading(false);
                } else {
                    self.setIsUsersDataOnListLoading(false);
                }
            } catch (error) {
                // Changing the state of loading when there is an error if first time loads
                if (isFirstTimeLoading) {
                    self.setIsUsersListLoading(false);
                } else {
                    self.setIsUsersDataOnListLoading(false);
                }
            }
        },
        // This function is used to fetch user sms history for specific user
        async fetchUserSmsHistory(paramsData = {}) {
            const { isFirstTimeLoading = true, userPhoneNumber, userId } = paramsData;
            try {
                // When component mounts then we will display loader
                // Otherwise we are not showing loader in the messages component
                if (isFirstTimeLoading) {
                    self.setIsSmsHistoryLoading(true);
                }
                const params = {};
                if (userId) {
                    params['receiverId'] = userId;
                } else {
                    params['receiverPhoneNumber'] = getNormalizedPhoneNumber(userPhoneNumber);
                    self.selectedSenderPhoneNumber != SELECT_SENDER_PHONE_NUMBER ?
                        params['senderPhoneNumber'] = self.selectedSenderPhoneNumber :
                        params['senderPhoneNumber'] = SENDER_PHONE_NUMBER_LIST[0].split("-")[0].slice(2)

                }
                // API call to fetch all messages between admin and receiver/member
                const response = await ApiService.getRequest('send-sms', params);
                const { success, data } = response;
                if (success) {
                    const { smsMessagesHistory = [] } = data;
                    self.setSmsHistoryUpdatedTime(Date.now());
                    // If there are messages in history then we are plotting the data to the messages component
                    self.setUserSmsData(smsMessagesHistory);
                }
                // Changing the state of loading if first time loads
                if (isFirstTimeLoading) {
                    self.setIsSmsHistoryLoading(false);
                }
            } catch (error) {
                // Changing the state of loading when there is an error if first time loads
                if (isFirstTimeLoading) {
                    self.setIsSmsHistoryLoading(false);
                }
            }
        },
        // This function is used to send user sms to the particular user
        async sendUserSms(body) {
            try {
                const { receiverId, receiverPhoneNumber } = body;
                body.receiverPhoneNumber = getNormalizedPhoneNumber(receiverPhoneNumber);
                self.setIsSmsSending(true);
                // We are sending receiver id and message text in the api call to send message to the user's number
                const response = await ApiService.postRequest('send-sms', body);
                const { success, data } = response;
                if (success) {
                    // If we aer getting success response then we are fetching all messages history in silent api call without showing any loader
                    self.fetchUserSmsHistory({
                        userId: receiverId,
                        isFirstTimeLoading: false,
                        userPhoneNumber: receiverPhoneNumber
                    });
                } else {
                    // If there is any error like if user unsubscribe to that particular number then we are showing as an alert popup
                    if (data && data.message) {
                        alert(data.message);
                    }
                }

                self.setIsSmsSending(false);
            } catch (error) {
                self.setIsSmsSending(false);
            }
        },
        // This function is used to send bulk sms to the users by uploading excel file
        async sendBulkSms(formData) {
            try {
                self.setIsBulkSmsSending(true);
                // We are sending receiver id and message text in the api call to send message to the user's number
                const response = await ApiService.postRequest('upload-csv-send-sms', formData, false, '', true);
                const { success, data, message } = response;
                if (success) {
                    NotificationStore.setNotification('success', 'Send bulk SMS messages to members from every one minute.', '', 5);
                } else {
                    NotificationStore.setNotification('error', message);
                }
                self.setIsBulkSmsSending(false);
            } catch (error) {
                NotificationStore.setNotification('error', error.message);
                self.setIsBulkSmsSending(false);
            }
        },
        parseMetaData(meta) {
            return {
                pageSize: meta.limit,
                current: meta.skip / meta.limit + 1,
                total: meta.total,
            };
        },
        resetPagination() {
            self.pagination = {
                pageSize: 50,
                total: 0,
                current: 1,
            };
        },
        setIsSmsHistoryLoading(value) {
            self.isSmsHistoryLoading = value;
        },
        setIsSmsSending(value) {
            self.isSmsSending = value;
        },
        setIsBulkSmsSending(value) {
            self.isBulkSmsSending = value;
        },
        setUserSmsData(arr) {
            self.userSmsData = arr;
        },
        setSmsHistoryUpdatedTime(value) {
            self.smsHistoryUpdatedTime = value;
        },
        setUsersListingMeta({ skipUsers, totalUsers }) {
            self.skipUsersInListing = skipUsers;
            self.totalUsersInListing = totalUsers;
        },
        setSelectedTerritoryForSmsInbox(value) {
            self.selectedTerritoryForSmsInbox = value;
        },
        setSelectedSenderPhoneNumber(value) {
            self.selectedSenderPhoneNumber = value;
        },
        setDefaultPhoneNumber(value) {
            self.defaultPhoneNumber = value;
        },
        setSelectedUserStatusForSmsInbox(value) {
            self.selectedUserStatusForSmsInbox = value;
        },
        setSearchString(value) {
            self.searchString = value;
        },
        setAllTerritories(data) {
            self.allTerritories = data;
        },
        setInboxUsersListUpdatedTime(value) {
            self.inboxUsersListUpdatedTime = value;
        },
        setPaginationData(pagination) {
            self.pagination = pagination;
        },
        setLoading(value) {
            self.loading = value;
        },
        setIsUsersDataOnListLoading(value) {
            self.isUsersDataOnListLoading = value;
        },
        setIsUsersListLoading(value) {
            self.isUsersListLoading = value;
        },
        setIsRepliedFilterSelected(value) {
            self.isRepliedFilterSelected = value;
        },
        setIsAllNonAccountsFilterSelected(value) {
            self.isAllNonAccountsFilterSelected = value;
        },
        setIsAllAccountsFilterSelected(value) {
            self.isAllAccountsFilterSelected = value;
        },
        setIsMyCaseloadSelected(value) {
            self.isMyCaseloadSelected = value;
        },
        setOriginalUsersListData(arr) {
            self.originalUsersListData = [...self.originalUsersListData, ...arr];
        },
        setUsersListData(arr) {
            self.usersListData = arr;
        },
    }))
    .views((self) => ({
    }));

export default SmsInbox;
