import { ISLAND, ILLINOIS, HP, AMERI_HEALTH, getFeedChannel } from '../utils/getEnvironment';
import { REACT_APP_BULK_SMS_FROM_NUMBER, REACT_APP_FROM_NUMBER } from "../configs/EnvConfig";
import {
    IMG_ENROLLED_STUDY,
    IMG_ENROLLED_HP,
    IMG_ELIGIBLE_HP,
    IMG_ENROLLED_DELAWARE,
    IMG_ELIGIBLE_DELAWARE,
    IMG_ENROLLED_IHP,
    IMG_ELIGIBLE_IHP,
    IMG_ELIGIBLE_STUDY,
    IMG_ENROLLED_WOODRIVER,
    IMG_ELIGIBLE_WOODRIVER,
    IMG_ENROLLED_WOODRIVER_IHP,
    IMG_ELIGIBLE_WOODRIVER_IHP,
    IMG_ENROLLED_ACDC,
    IMG_ELIGIBLE_ACDC, 
    IMG_ENROLLED_CTC,
    IMG_ELIGIBLE_CTC} from "../utils/ImageUtils";

export const SMS_OUTREACH_FLAGS = {
    SMS_DUE: 'SMS_DUE',
    SMS_SENT: 'SMS_SENT'
};

export const CALL_OUTREACH_FLAGS = {
    CALL_DUE: 'CALL_DUE',
    CALL_ATTEMPT: 'CALL_ATTEMPT',
    TALKED_TO: 'TALKED_TO'
};

export const TEXT_GREEN_COLOR = '#189016';
export const TEXT_RED_COLOR = '#FF1616';
export const TEXT_YELLOW_COLOR = '#DE8500';
export const ORANGE_COLOR = '#FF4D00';

export const TABLE_ROW_GREEN_COLOR = '#C6EFCE';
export const TABLE_ROW_RED_COLOR = '#FFC7CE';
export const TABLE_ROW_YELLOW_COLOR = '#FFEB9C';
export const DEFAULT_GROUP_NAME = "what's new";

export const TABLE_FONT_BLACK_COLOR = '#000000';
export const TABLE_FONT_GREEN_COLOR = '#3FBF61';
export const TABLE_FONT_RED_COLOR = '#EB4E2C';
export const TABLE_FONT_YELLOW_COLOR = '#FFB337';

export const TASK_STATUS = {
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
}

export const TASK_TYPE_FLAGS = {
    OVERDUE: 'OVERDUE',
};

export const STYLE_OBJ_FOR_CERTAIN_GROUP = {
    border: '1px solid #FF4D00',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingTop: '10px',
    paddingBottom: '10px',
};

export const APP_HEALTH_USER_FLAGS = {
    COMMON: 'COMMON',
    THIS_WEEK: 'THIS_WEEK',
    LAST_WEEK: 'LAST_WEEK'
};

export const CASELOAD_USER_FLAGS = {
    THIS_MONTH: 'THIS_MONTH',
    LAST_MONTH: 'LAST_MONTH',
    BOTH_MONTH: 'BOTH_MONTH'
};

export const BARC10_NOTE_TEMPLATE_CATEGORY_FLAGS = {
    BARC10_NOTE: 'barc10-score',
    LOWEST_SCORING_DOMAINS: 'lowest-scoring-domains',
    HIGHEST_SCORING_DOMAINS: 'highest-scoring-domains'
};

export const BARC10_NOTE_OPTIONS = {
    1: 'Strongly Disagree',
    2: 'Disagree',
    3: 'Somewhat Disagree',
    4: 'Somewhat Agree',
    5: 'Agree',
    6: 'Strongly Agree',
};
const NOT_ASSIGNED = -1;
const WELCOME_PROCESS = -2;
const ALL_ENROLLED = -3;

export const customArrayCaseload = [
    { id: NOT_ASSIGNED, fullName: 'Not Assigned' },
    { id: WELCOME_PROCESS, fullName: 'Welcome Process' },
    { id: ALL_ENROLLED, fullName: 'All Enrolled' }
];
export const NOTIFICATION_TYPE = {
    GROUP_MESSAGE: 'GROUP-TEXT MESSAGE',
    GROUP_IMAGE: 'GROUP-IMAGE',
    GROUP_FORM: 'GROUP-FORM',
    GROUP_ATTACHMENT: 'GROUP-ATTACHMENT',
    GROUP_VIDEO: 'GROUP-VIDEO',
    DIRECT_MESSAGE: 'DIRECT-TEXT MESSAGE',
    DIRECT_IMAGE: 'DIRECT-IMAGE',
    DIRECT_FORM: 'DIRECT-FORM',
    DIRECT_ATTACHMENT: 'DIRECT-ATTACHMENT',
    DIRECT_VIDEO: 'DIRECT-VIDEO',
    FORUM_PUSH: 'FORUM_PUSH',
}

export const FEED_CHANNEL = getFeedChannel();
export const FEED_RECORD_PER_PAGE = 25;
export const FEED_COMMENT_PER_PAGE = 25;

export const GROUP_NOTIFICATION_TYPE = [NOTIFICATION_TYPE.GROUP_MESSAGE, NOTIFICATION_TYPE.GROUP_ATTACHMENT, NOTIFICATION_TYPE.GROUP_FORM, NOTIFICATION_TYPE.GROUP_IMAGE, NOTIFICATION_TYPE.GROUP_VIDEO];

export const DIRECT_NOTIFICATION_TYPE = [NOTIFICATION_TYPE.DIRECT_MESSAGE, NOTIFICATION_TYPE.DIRECT_ATTACHMENT, NOTIFICATION_TYPE.DIRECT_FORM, NOTIFICATION_TYPE.DIRECT_IMAGE, NOTIFICATION_TYPE.DIRECT_VIDEO];

export const DELETE = 'delete';
export const COMMENT = 'comment';
export const ADDED = 'added';

export const BARC10_NOTE_TEMPLATE_FORM_TITLE = "BARC-10 Note Template V2.0";

export const MIXPANEL_REPORT_EVENTS = [
    {
        environment: ISLAND,
        userMessagesDataEvent: 'User Messages Data - Island'
    },
    {
        environment: ILLINOIS,
        userMessagesDataEvent: 'User Messages Data - Illinois'
    },
    {
        environment: HP,
        userMessagesDataEvent: 'User Messages Data - NewEngland'
    },
    {
        environment: AMERI_HEALTH,
        userMessagesDataEvent: 'User Messages Data - AmeriHealth'
    }
];

export const FILTER_BY_ALL_FORMS_FLAG = 'ALL';
export const FILTER_BY_BARC10_FORMS_FLAG = 'BARC10';
export const FILTER_BY_EXCEPT_BARC10_FORMS_FLAG = 'EXCEPT_BARC10';

export const SUBMITTED_FROM_TYPE_ADMIN = 'ADMIN';
export const SUBMITTED_FROM_TYPE_DIRECT = 'DIRECT';
export const SUBMITTED_FROM_TYPE_WAITING = 'WAITING';
export const SUBMITTED_FROM_TYPE_GROUP = 'GROUP';

export const FORM_NAME_APP_ENCOUNTER_TEMPLATE = 'App Encounter Template (Hidden)';
export const FORM_NAME_APP_ENCOUNTER_TEMPLATE_V2 = 'App Encounter Template V2.0';
export const FORM_NAME_OUTREACH_NOTE = 'Outreach Note';
export const FORM_NAME_ENCOUNTER_NOTE_V2 = 'Encounter Note V2.0 (Hidden)';
export const FORM_NAME_ENCOUNTER_NOTE_V3 = 'Encounter Note V3.0';
export const FORM_NAME_ORIENTATION_NOTE_V2 = 'Orientation Note V2';
export const FORM_NAME_BARC10_NOTE_TEMPLATE = 'BARC-10 Note Template (Hidden)';
export const FORM_NAME_BARC10_NOTE_TEMPLATE_V2 = 'BARC-10 Note Template V2.0';
export const FORM_NAME_INTAKE = 'Intake';
export const FORM_NAME_SDOH_SCREEN = 'SDOH Screen';
export const FORM_NAME_BARC10 = 'BARC10';
export const FORM_NAME_MENTAL_HEALTH_RECOVERY_MEASURE = 'Mental Health Recovery Measure';
export const FORM_NAME_ENROLLMENT_NOTE = 'Enrollment Note';

export const PUBNUB_ENCRYPTION_KEY = 'ddd66c131e0f2f4da26a634a171199e0';

export const SORTING_ORDERS = {
    ascend: 'asc',
    descend: 'desc'
};

export const USERS_WAU_DATA_FETCH_FLAGS_TERRITORY = 'TERRITORY';
export const USERS_WAU_DATA_FETCH_FLAGS_SITES = 'SITES';
export const USER_STATUS_ALL_STATUS = 'ALL_STATUS';
export const TERRITORIES_TYPE_ALL_TERRITORIES = 'ALL_TERRITORIES';
export const SELECT_SENDER_PHONE_NUMBER = 'Select Sender Phone Number';
export const SENDER_PHONE_NUMBER_LIST = [`${REACT_APP_FROM_NUMBER}-Primary Number`, `${REACT_APP_BULK_SMS_FROM_NUMBER}-Outreach Number`];
export const BADGE_TYPE_ENROLLED_CODE = 'EN';
export const BADGE_TYPE_FLAGS_ARR = [
    { title: 'Enrolled', code: 'EN' },
    { title: 'Eligible', code: 'EL' },
    { title: 'No Badge', code: 'MB' },
    { title: 'All Members', code: 'AL' },
];

export const TERRITORY_TYPE_DEVELOPMENT = 'Development';

export const USER_BADGE_TYPES_ARR = [{
    label: 'Enrolled',
    value: 'ENROLLED'
},
{
    label: 'Eligible',
    value: 'ELIGIBLE'
},
{
    label: 'Not Members',
    value: 'NOT_ELIGIBLE'
}];

export const TASK_DATE_FORMAT = 'MMM DD YYYY';
export const DEFAULT_PAGE_SIZE = 10;
export const MARIGOLD_SUPPORT_DM = 'Marigold Support';
export const MESSAGE_TYPE_FLAGS = {
    MESSAGE_TYPE_TEXT: 'text',
    MESSAGE_TYPE_DM_RESOLVE: 'DM_RESOLVE',
    MESSAGE_TYPE_IMG: 'img',
    MESSAGE_TYPE_DOCUMENT: 'document',
    MESSAGE_TYPE_FORM: 'form',
    MESSAGE_TYPE_AUDIO: 'audio',
    MESSAGE_TYPE_VIDEO: 'video',
    MESSAGE_TYPE_GIF: 'gif',
};

export const IMAGE_CONTENT_TYPE = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/webp'];
export const VIDEO_CONTENT_TYPE = ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/3gpp', 'video/x-matroska'];

export const MAX_UPLOAD_IMAGE_SIZE = 10;
export const MAX_UPLOAD_VIDEO_SIZE = 25;
export const FORM_QUESTION_TYPE_DESCRIPTIVE = 'descriptive';
export const FORM_QUESTION_TYPE_MULTIPLE_CHOICES_RADIO = 'multiple_choices_radio';
export const FORM_QUESTION_TYPE_MULTIPLE_CHOICES = 'multiple_choices';
export const FORM_QUESTION_CATEGORY_TYPE_EDITABLE = 'editable';
export const FORM_QUESTION_TYPE_DATEPICKER = 'datepicker';
export const FORM_QUESTION_CATEGORY_TYPE_EDITABLE_TOUCHPOINT_DATE = 'editable-touchpoint-date';
export const FORM_QUESTION_CATEGORY_TYPE_TOUCHPOINT_MEDIUM = 'touchpoint-medium';
export const FORM_QUESTION_CATEGORY_TYPE_TOUCHPOINT_CHANNEL = 'touchpoint-channel';
export const FORM_QUESTION_CATEGORY_TYPE_USER_GOALS = 'user-goals';

export const MAX_ROWS_FOR_TEXT_AREA = 9;
export const MAX_LENGTH_FOR_PIN_VERIFICATION = 4;
export const VERIFICATION_PIN = 7889;
export const MAX_LIMIT_FOR_ICEBREAKER_TITLE = 50;
export const MAX_LIMIT_FOR_ICEBREAKER_DESCRIPTION = 200;
export const CHAT_ACTIVE_TAB_GROUP = 'group';
export const CHAT_ACTIVE_TAB_DM = 'dm';
export const CHAT_ACTIVE_TAB_QUEUE = 'queue message';
export const CHAT_ACTIVE_TAB_FEED = 'feed';
export const QUESTION_STATUS_FLAGS = {
    REQUIRED: 'REQUIRED',
    HIDDEN: 'HIDDEN',
    OPTIONAL: 'OPTIONAL'
};

export const REWARD_STATUS = {
    READY_TO_REDEEM: "READY_TO_REDEEM",
    IN_PROGRESS: "IN_PROGRESS",
    EXPIRED: "EXPIRED"
};

export const FORM_OPEN_METHOD_DOCUMENT = 'DOCUMENT';
export const GENDER_TYPES = {
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    TRANSGENDER_MALE: "TRANSGENDER_MALE",
    TRANSGENDER_FEMALE: "TRANSGENDER_FEMALE",
    NON_BINARY_GENDER_NEUTRAL: "NON_BINARY_GENDER_NEUTRAL",
    PREFER_NOT_TO_RESPOND_NOT_LISTED: "PREFER_NOT_TO_RESPOND_NOT_LISTED"
};

export const GENDER_MAPPING = {
    MALE: 'Male',
    FEMALE: 'Female',
    OTHER: 'Other',
    TRANSGENDER_MALE: "Transgender Male",
    TRANSGENDER_FEMALE: "Transgender Female",
    NON_BINARY_GENDER_NEUTRAL: "Non-binary/Gender Neutral",
    PREFER_NOT_TO_RESPOND_NOT_LISTED: "Prefer not to respond/Not listed"
}


export const PRONOUNS_TYPES = {
    SHE: "She/Her/Hers",
    HE: "He/Him/His",
    THEY: "They/Them/Theirs",
    OTHER: "Not listed"
};

export const AVAILABILITY_BY_TIME_OF_DAY_TYPES = {
    MORNING: "Morning",
    AFTERNOON: "Afternoon",
    EVENING: "Evening",
    NONE: "No preference"
};

export const AVAILABILITY_BY_TIME_OF_DAY_MAPPING = {
    Morning: "Morning 8 am - 12 pm",
    Afternoon: "Afternoon 12 pm- 5 pm",
    Evening: "Evening 5 pm – 8 pm",
    "No preference": "No preference"
};

export const AVAILABILITY_BY_TYPE_OF_DAY_TYPES = {
    WEEKDAYS: "Weekdays",
    WEEKEND: "Weekends",
    ANYDAY: "No preference"
};

export const SUPPORT_TYPES = {
    MENTAL_HEALTH: 'Mental Health',
    SUBSTANCE_USE: 'Substance Use',
    BOTH: 'Both',
    NEITHER: 'Neither'
};

export const MODALITY_TYPES = {
    PHONE: 'Phone',
    CHAT: 'Chat',
    SMS: 'SMS',
    EMAIL: 'Email'
};

export const REWARD_TYPE = {
    WELLNESS_JOURNEY: 'wellness-journey',
    BARC10: 'barc10',
    FIRST_MESSAGE: 'first-message',
    INTAKE: 'intake',
    SDOH: 'SDOH',
    CUSTOM_REWARD: 'custom-reward',
}

export const REWARD_LABEL = {
    WELLNESS_JOURNEY: 'Wellness Plan',
    BARC10: 'B10',
    FIRST_MESSAGE: 'First-Message',
    INTAKE: 'Intake',
    SDOH: 'SDOH Screening',
}

export const REWARD_STATUS_LABEL = {
    SUBMITTED: 'Submitted',
    REVIEWED: 'Reviewed',
    TASK1: "Great start! One task completed",
    TASK2: "Halfway there! Two tasks completed",
    TASK3: "You did it! You have earned the weekly bonus reward"
}

export const WEEKLY_FORM_TYPE = ["weekly", "weekly-bonus","first-message","intake","SDOH"];
export const HIDE_STEP_FORM_TYPE=["weekly","weekly-bonus","first-message"];

export const GENDER_TYPES_ARR = [...Object.values(GENDER_TYPES)];
export const PRONOUNS_TYPES_ARR = [...Object.values(PRONOUNS_TYPES)];
export const AVAILABILITY_BY_TIME_OF_DAY_TYPES_ARR = [...Object.values(AVAILABILITY_BY_TIME_OF_DAY_TYPES)];
export const AVAILABILITY_BY_TYPE_OF_DAY_TYPES_ARR = [...Object.values(AVAILABILITY_BY_TYPE_OF_DAY_TYPES)];
export const SUPPORT_TYPES_ARR = [...Object.values(SUPPORT_TYPES)];
export const MODALITY_TYPES_ARR = [...Object.values(MODALITY_TYPES)];
export const CATEGORY_TYPES = [
    { title: "Mental Health Support", value: "0" },
    { title: "Addiction Recovery Support", value: "1" }
];
export const WAITING_FOR_REVIEW = "Waiting For Review";
export const WAITING_FOR_MEMBER = "Waiting For Member";
export const WAITING_FOR_REWARD = "Waiting For Reward";
export const IN_PROGRESS = "In Progress";
export const COMPLETED = "Completed";


export const GET_STREAM_API_KEY = "63z88xf8ah2n";
export const GET_STREAM_APP_ID = "1270549";
export const GET_FEED_LIMIT = 100;
export const GET_FLAGGED_FEED_LIMIT = 100;
export const REACTION_TYPES = {
    like: 'like',
    flag: 'flag',
    comment: 'comment',
    unlike: 'unlike',
    unflag: 'unflag',
    uncomment: 'uncomment',
};
export const GET_COMMENT_LIMIT = 25;
export const CHILD_REACTION_TYPES = {
    like: 'like',
    flag: 'flag',
};
export const FEED_IMAGE_URL = 'https://cleanslate-marigold.s3.us-east-2.amazonaws.com';
export const FEED_SUBSCRIPTION_ACTION = {
    CREATED: 'feed added',
    DELETED: 'feed removed',
    UPDATED: 'feed updated',
};
export const TIME_FILTERS = {
    NEW_POSTS: "New",
    HOT_POSTS: "Hot",
    TOP_POSTS_ALL_TIME: "Top Posts (All Time)",
    RECENTLY_COMMENTED: "Recently Commented Posts",
    FLAGGED: "Flagged",
};
export const UserBadges = {
    ENROLLED_HP: IMG_ENROLLED_HP,
    ELIGIBLE_HP: IMG_ELIGIBLE_HP,
    ENROLLED_DELAWARE: IMG_ENROLLED_DELAWARE,
    ELIGIBLE_DELAWARE: IMG_ELIGIBLE_DELAWARE,
    ENROLLED_IHP: IMG_ENROLLED_IHP,
    ELIGIBLE_IHP: IMG_ELIGIBLE_IHP,
    ENROLLED_STUDY: IMG_ENROLLED_STUDY,
    ELIGIBLE_STUDY: IMG_ELIGIBLE_STUDY,
    ENROLLED_WOODRIVER: IMG_ENROLLED_WOODRIVER,
    ELIGIBLE_WOODRIVER: IMG_ELIGIBLE_WOODRIVER,
    ENROLLED_WOODRIVER_IHP: IMG_ENROLLED_WOODRIVER_IHP,
    ELIGIBLE_WOODRIVER_IHP: IMG_ELIGIBLE_WOODRIVER_IHP,
    ENROLLED_ACDC: IMG_ENROLLED_ACDC,
    ELIGIBLE_ACDC: IMG_ELIGIBLE_ACDC,
    ENROLLED_CTC: IMG_ENROLLED_CTC,
    ELIGIBLE_CTC: IMG_ELIGIBLE_CTC,
};

export const GIF_TYPE = 'gif';

export const TYPE_OF_NEEDS = {
    COC_VIOLATIONS: 'CoC Violation',
    DV_SI_HI: 'DV/SI/HI',
    MENTAL_HEALTH_DISTRESS: 'Mental health distress',
    SUD_DISTRESS: 'SUD distress',
    MEDICAL_DENTAL_NEED: 'Medical/dental need',
    SDOH: 'SDOH',
    TEAM_REVIEW: 'Team Review',
}

export const MAX_REWARD_AMOUNT = {
    SA: 50,
    MODERATOR: 5
}

export const PUBNUB_SUBSCRIBE_ALLOWED_GROUPS = [`What's New`, `New Members Group`];
