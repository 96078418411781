import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown, Icon, Menu, Modal, message as infoMessage } from "antd";
import { IMG_FORM, IMG_MENTION, IMG_SEND } from "../../utils/ImageUtils";
import GroupStore from '../../stores/Groups';
import AuthStore from '../../stores/AuthStore';
import MentionUserModal from '../UI/MentionUser'
import {
    publish,
    getPubnubInstanceByUserType,
  } from '../../utils/PubnubMethods';
import { inject, observer } from "mobx-react";
import { ALIKE_MODERATOR, SEMI_MODERATOR } from "../../constants/UserRolesConstant";
import { color } from '../../en.json';
import ExpandableTextArea from "../UI/ExpandableTextArea";

const MentionComponent = inject("store")(observer((props) => {

    const {
        store: {
          AuthStore: { type: userType },
          FormMessageStore: { formList, sendFormInGroup },
        },
        activeTab,
        mentionedUserName,
        clearMentionedUserName,
        replyingToMessage,
        clearReplyingToMessage,
        isGifModalVisible,
        closeModal,
        renderGifData,
        setUploadRef,
        uploadImage,
        optionsList,
        sendButtonIsDisabled,
        onSend,
        onKeyDown,
        onMention,
        textAreaOnChange,
        textAreaValue,
        fetchMentionOptions,
   } = props;

    const [message, SetMessage] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const selectedGroupUsers = GroupStore.selectedGroupUsers
    const textAreaRef = useRef(null);
    const expandableTextAreaRef = useRef(null);

    const isSemiModerator = userType === SEMI_MODERATOR;
    const colorOptions = [
      "#2F3BED",
      "#2F80ED",
      "#4CA2CD",
      "#41C479",
      "#F7971E",
      "#E47B3B",
      "#DD2476",
      "#453093",
      "#29323C",
    ];
    const sendForm = async ({ key }) => {
      sendFormInGroup(Number(key));
    };
    const isFeedTabActive = activeTab === "feed";

    const formsList = (
      <div className="form-dropdown">
        <Menu onClick={sendForm} className="menu">
          {formList.map((form) => {
            return (
              <Menu.Item key={form.id}>
                <div>{form.name}</div>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );

    const changeColor = async ({ key }) => {
      const {
        store: {
          GroupStore: { updateColor },
          MessagesStore: {
            selectedGroup: { changeColor },
          },
        },
      } = props;

      const hide = infoMessage.loading(color.updatingColor, 0);
      const success = await changeColor(key);
      hide();
      if (success) {
        infoMessage.success(color.colorUpdated, 2.5);
        updateColor(key);
      } else {
        infoMessage.error(color.colorNotUpdated, 2.5);
      }
    };

    const menu = (
      <Menu onClick={changeColor}>
        {colorOptions.map((color) => (
          <Menu.Item key={color}>
            <div className="color-box" style={{ background: color }} />
          </Menu.Item>
        ))}
      </Menu>
    );

    const addMentionInMessage = (mentionedUserName) => {
      if (isFeedTabActive) {
        onMention(mentionedUserName);
      } else {
        SetMessage(`${message}@${mentionedUserName} `);
      }
    }

    const appendData = async () => {
        if (message.trim() !== "") {
            const pubnub = getPubnubInstanceByUserType(AuthStore.type);
            message.trim() !== "" && await publish(pubnub, message, replyingToMessage);
            SetMessage('');
            clearReplyingToMessage();

          if (expandableTextAreaRef && expandableTextAreaRef.current) {
            expandableTextAreaRef.current.contractTextArea();
          }
        }
    };

    const closeFormPendingWizardOnConfirm = (value) => {
        setIsModalOpen(false)
        setTimeout(() => {
            textAreaRef.current.focus();
        },0);
    }
    const cancelFormConfirmModal = () => {
        setIsModalOpen(false)
        setTimeout(() => {
            textAreaRef.current.focus();
        },0);
    };

    useEffect(() => {
        if (mentionedUserName !== '') {
            addMentionInMessage(mentionedUserName);
            clearMentionedUserName();
            textAreaRef.current.focus();
        }
    }, [
        mentionedUserName,
        clearMentionedUserName
    ]);

    useEffect(() => {
      if (replyingToMessage) {
        textAreaRef.current.focus();
      }
    }, [replyingToMessage]);

    const handleKeyPress = async (e) => {
      if (isFeedTabActive) {
          onKeyDown(e);
      } else if (!e.shiftKey && e.key === 'Enter') {
          await appendData();
      }
    };

    const getSendButtonStyle = () => {
      if (isFeedTabActive) {
        return { cursor: sendButtonIsDisabled ? 'not-allowed' : 'pointer', opacity: sendButtonIsDisabled ? 0.6 : 1 };
      }

      return { opacity: message.trim() !== "" ? 1 : 0.4 };
    }

    const getSendButtonOnClickFunction = () => {
      if (isFeedTabActive) {
        return onSend;
      }

      return appendData;
    }

    const getTextAreaOnChange = (e) => {
      if (isFeedTabActive) {
        textAreaOnChange(e.target.value);
      } else {
        SetMessage(e.target.value);
      }
    }

  const getTextAreaValue = () => {
    if (isFeedTabActive) {
      return textAreaValue;
    } else {
      return message;
    }
  }

  const getTextAreaPlaceholder = () => {
    if (isFeedTabActive) {
      return "Write a Comment";
    } else {
      return "Type here to send a message...";
    }
  }

  const getMentionOptions = (searchValue = "") => {
    if (isFeedTabActive) {
      return fetchMentionOptions(searchValue);
    } else {
      return !searchValue ?
        selectedGroupUsers :
        selectedGroupUsers.filter(option => option.toLowerCase().includes(searchValue.toLowerCase()));
    }
  }

    return <>
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <MentionUserModal isModalVisible={isModalOpen}
                                onConfirm={closeFormPendingWizardOnConfirm}
                                onCancel={cancelFormConfirmModal}
                                addMentionInMessage={addMentionInMessage}
                                getMentionOptions={getMentionOptions}
              />
              <Button
                onClick={(e) => {setIsModalOpen(true);}}
                shape="circle"
                style={["group", "feed"].includes(activeTab) ? { display: 'inline-block',marginLeft:'5px' } : { display: 'none' }}
              >
                <img style={{ marginBottom: "70px" }} src={IMG_MENTION} className="send-form" alt="Form" />
              </Button>
              <ExpandableTextArea
                ref={expandableTextAreaRef}
                onChange={getTextAreaOnChange}
                value={getTextAreaValue() ?? ""}
                placement="top"
                placeholder={getTextAreaPlaceholder()}
                onKeyDown={(event) => {handleKeyPress(event)}}
                textAreaRef={textAreaRef}
              />
            </div>

            <div className={`chat-icons-wrapper ${
              activeTab === 'dm' ? 
                'inactive' : 
                isFeedTabActive ?
                  'forum-chat-icons' :
                  'active'
            }`}>
              {ALIKE_MODERATOR.includes(userType) || isSemiModerator ? (
                <>
                  {/* Here we are showing form list icon */}
                  {activeTab !== "feed" && <Dropdown
                    overlay={formsList}
                    trigger={["click"]}
                    placement="topRight"
                  >
                    <img src={IMG_FORM} className="send-form" alt="Form"/>
                  </Dropdown>}
                  {/* Modal to select GIFs */}
                  <Modal title="Select GIFs"
                         centered
                         visible={isGifModalVisible}
                         onOk={closeModal}
                         onCancel={closeModal}
                  >
                    {renderGifData()}
                  </Modal>
                  {/* Input type to select file */}
                  <input
                    type="file"
                    ref={setUploadRef}
                    onChange={uploadImage}
                    accept="image/*"
                    data-field="groupImage"
                    className="chat-image-upload"
                  />
                </>
              ) : null}

              {/* Here we are rendering plus(+) icon where we will show Upload Image & Upload GIF menu */}
              <Dropdown overlay={optionsList}
                        trigger={["click"]}
                        placement="topCenter"
              >
                <Icon type="plus-circle" theme="filled" style={{ fontSize: 25 }} />
              </Dropdown>

              {/* If active tab is not dm or feed then we are showing bg color icon */}
              {["dm", "feed"].includes(activeTab) ? null : (
                <Dropdown
                  overlay={menu}
                  className="color-dropdown"
                  placement="topRight"
                >
                  <Icon type="bg-colors" />
                </Dropdown>
              )}

              <img
                src={IMG_SEND}
                onClick={getSendButtonOnClickFunction()}
                style={getSendButtonStyle()}
                alt="Send"
              />
            </div>
        </div>
    </>
}));

export default MentionComponent;
