import { destroy, types } from "mobx-state-tree";

import ApiService from "../utils/ApiService";
import AuthStore from "../stores/AuthStore";
import FlaggedMessage from "./FlaggedMessage";
import NotificationStore from "../stores/NotificationStore";
import ProfileStore from "../stores/ProfileStore";
import MessagesStore from "../stores/MessagesStore";
import GroupListStore from "../stores/GroupListStore";
import _ from "lodash";
import { toJS } from "mobx";
import { flagMessages } from "../en.json";
import {
  getMessageInfo,
  getPubnubInstanceByUserType,
} from "../utils/PubnubMethods";
import { user } from "../en.json";
import { setStreamInstance, streamClient } from "../utils/streamInstance";
import { getActivitiesByIds } from "../utils/forumFeedService";
import { decryptData } from "../utils/CommonUtils";
import { FEED_IMAGE_URL, GIF_TYPE } from "../constants/GlobalConstant";

const FlaggedMessagesList = types
  .model("FlaggedMessagesList", {
    unresolvedFlaggedMessages: types.array(FlaggedMessage),
    resolvedFlaggedMessages: types.array(FlaggedMessage),
    loading: false,
    userFlagsLoading: false,
    groupFlagsLoading: false,
    groupFlagsLoadMore: false,
    urlAlertId: types.maybeNull(types.number),
    selectedUrlAlert: types.maybeNull(FlaggedMessage),
    endOfUnresolvedFlaggedMessageList: false,
    endOfResolvedFlaggedMessageList: false,
    resolveLoader: false,
    unresolvedTotal: types.maybe(types.number),
    selectedMessageId: types.maybeNull(types.number),
    userUnresolvedFlaggedMessages: types.array(FlaggedMessage),
    userResolvedFlaggedMessages: types.array(FlaggedMessage),
    groupUnresolvedFlaggedMessages: types.array(FlaggedMessage),
    groupResolvedFlaggedMessages: types.array(FlaggedMessage),
    endOfUserUnresolvedFlaggedMessageList: false,
    endOfUserResolvedFlaggedMessageList: false,
    endOfGroupUnresolvedFlaggedMessageList: false,
    endOfGroupResolvedFlaggedMessageList: false,
    userUnResolvedFlagedMessagesTotal: 0,
    userResolvedFlagedMessagesTotal: 0,
    groupUnResolvedFlagedMessagesTotal: 0,
    groupResolvedFlagedMessagesTotal: 0,
    fetchingUserStatus: false,
    usernames: types.map(types.frozen({ username: types.string })),
    senderIds: types.map(types.frozen({ id: types.number })),
    assignedAlerts: types.map(
      types.frozen({ assignedUsername: types.array(types.string) })
    ),
    userSuspendStatus: types.map(types.frozen({ isSuspended: types.boolean })),
    urlAlerlLoading: types.maybeNull(types.boolean),
    numberOfUnResolvedFlags: types.maybeNull(types.number),
    selectedMessage: types.maybeNull(FlaggedMessage),
    selectedTab: types.maybeNull(types.string),
  })
  .actions((self) => ({
    async getUsernameFromIds(idArray) {
      if (idArray.length) {
        const query = idArray.reduce((obj, id, index) => {
          const key = `id[${index}]`;
          if (!obj.hasOwnProperty(key)) {
            obj[key] = id;
          }
          return obj;
        }, {});
        const params = {
          "$select[1]": "id",
          "$select[2]": "username",
          ...query,
        };
        const response = await ApiService.getRequest("users", params);
        return response.data;
      }
    },
    async getAssignedToResponses(alertIds) {
      if (alertIds.length) {
        const query = alertIds.reduce((obj, alert, index) => {
          const key = `messageReactionId[${index}]`;
          if (!obj.hasOwnProperty(key)) {
            obj[key] = alert;
          }
          return obj;
        }, {});
        let skip = 0,
          total = 1,
          assigned = [];
        while (skip < total) {
          const params = {
            ...query,
            $skip: skip,
          };
          const response = await ApiService.getRequest("assign-alert", params);

          if (response.success && response.data.length) {
            skip = skip + response.meta.limit;
            total = response.meta.total;
            assigned = [...assigned, ...response.data];
          } else {
            return;
          }
        }

        return assigned;
      }
    },
    async getIdsFromUsernames(usernameArray) {
      if (usernameArray.length) {
        const query = usernameArray.reduce((obj, username, index) => {
          const key = `username[${index}]`;
          if (!obj.hasOwnProperty(key)) {
            obj[key] = username;
          }
          return obj;
        }, {});
        const params = {
          "$select[1]": "id",
          "$select[2]": "username",
          ...query,
        };
        const response = await ApiService.getRequest("users", params);
        return response.data;
      }
    },
    setFetchingUserStatus(value) {
      self.fetchingUserStatus = value;
    },
    async checkIsSuspended(messageId, senderId, groupId) {
      if (!senderId || !groupId) {
        return;
      }
      self.setFetchingUserStatus(true);
      const params = {
        userId: senderId,
        groupId,
      };
      const response = await ApiService.getRequest("user-groups", params);
      if (response.success) {
        if (!response.data.length) {
          NotificationStore.setNotification(
            "error",
            "User is not a part of the group"
          );
          self.setFetchingUserStatus(false);
          return;
        }
        self.setUserStatus(messageId, response.data[0].status);
        self.setFetchingUserStatus(false);
      } else {
        self.setFetchingUserStatus(false);
        NotificationStore.setNotification("error", user.generelErrorMessage);
      }
    },
    setUserStatus(messageId, status) {
      const isSuspended = status === 2 ? true : false;
      self.userSuspendStatus.set(messageId, isSuspended);
    },
    setSenderIds(data) {
      data.forEach((user) => {
        if (!self.senderIds.has(user.username)) {
          self.senderIds.set(user.username, user.id);
        }
      });
    },
    setResolvedByUsernames(data) {
      data.forEach((user) => {
        if (!self.usernames.has(user.id)) {
          self.usernames.set(user.id, user.username);
        }
      });
    },
    updateAssignedList(messageReactionId, username) {
      if (!self.assignedAlerts.has(messageReactionId)) {
        self.assignedAlerts.set(messageReactionId, [username]);
      } else if (self.assignedAlerts.has(messageReactionId)) {
        let assignedUsers = self.assignedAlerts.get(messageReactionId);
        if (!assignedUsers.includes(username)) {
          assignedUsers = [...assignedUsers, username];
        }
        self.assignedAlerts.set(messageReactionId, assignedUsers);
      }
    },
    setAssignedUsers(data) {
      data.forEach((alert) => {
        if (!self.assignedAlerts.has(alert.messageReactionId)) {
          self.assignedAlerts.set(alert.messageReactionId, [
            alert.targetUserUsername.username,
          ]);
        } else if (self.assignedAlerts.has(alert.messageReactionId)) {
          let assignedUsers = self.assignedAlerts.get(alert.messageReactionId);
          if (!assignedUsers.includes(alert.targetUserUsername.username)) {
            assignedUsers = [
              ...assignedUsers,
              alert.targetUserUsername.username,
            ];
          }
          self.assignedAlerts.set(alert.messageReactionId, assignedUsers);
        }
      });
    },
    async fetchFlaggedMessagesSummary(params = {}, fromLoadMore = false) {
      if (
        fromLoadMore 
        && !params?.isResolved 
        && self?.unresolvedFlaggedMessages?.length === 0
      ) { return }
      const limit = 10;
      params = {
        ...params,
        reactionId: 2,
        $skip: self.endOfUnresolvedFlaggedMessageList
          ? self.resolvedFlaggedMessages.length
          : self.unresolvedFlaggedMessages.length,
        $limit: limit,
      };
      if (params.isResolved) {
        params = { ...params, "$sort[updatedAt]": -1 };
      } else {
        params = { ...params, "$sort[createdAt]": -1 };
      }

      self.setLoading(true);
      const response = await ApiService.getRequest(
        "message-reactions-summary",
        params
      );
      if (!_.get(response, "data.length", 0)) {
        self.endOfUnresolvedFlaggedMessageList
          ? self.setEndOfResolvedFlaggedMessageList(true)
          : self.setEndOfUnresolvedFlaggedMessageList(true);
      }
      let promises = [],
        resolvedByIds = [],
        alertIds = [],
        userIdFetchPromises = [];
      if (response.success) {
        if (!params.isResolved && response.meta.skip === 0) {
          self.setAlertsTotal(response.meta);
        }
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        if(!streamClient) {
          await setStreamInstance();
        }
        response.data.forEach((message) => {
          const { isForumFlag, commentId, activityId } = message;
          if(isForumFlag) {
            if(commentId && streamClient) {
              promises.push(streamClient.reactions.get(commentId));
            } else {
              promises.push(getActivitiesByIds([activityId]));
            }
          } else {
            promises.push(
              getMessageInfo(pubnub, {
                channel: message.channel,
                messageId: message.messageId,
              })
            );
            alertIds.push(message.id);
          }
          if (params.isResolved) {
            resolvedByIds.push(message.resolvedBy);
          }
        });
        const assigned = await self.getAssignedToResponses(alertIds);
        if (assigned && assigned.length) {
          self.setAssignedUsers(assigned);
        }
        const userResponse = await self.getUsernameFromIds(resolvedByIds);
        if (userResponse && userResponse.length) {
          self.setResolvedByUsernames(userResponse);
        }

        Promise.all(promises)
          .then(async (res) => {
            res.forEach((item) => {
              if (item && item?.messages?.length && item?.messages[0]?.entry) {
                userIdFetchPromises.push(item.messages[0].entry.sender);
              }
            });
            const senderResponse = await self.getIdsFromUsernames(
              userIdFetchPromises
            );
            if (senderResponse && senderResponse.length) {
              self.setSenderIds(senderResponse);
            }

            for (let index = 0; index < res.length; index++) {
              const element = res[index];
              if (element && element?.messages?.length && element?.messages[0]?.entry) {
                response.data[index]["text"] = _.get(element, "messages[0].entry.text", "Message not found");
                response.data[index]["sender"] = _.get(element, "messages[0].entry.sender");
                response.data[index]["type"] = _.get(element, "messages[0].entry.type");
                response.data[index]["imgUrl"] = _.get(element, "messages[0].entry.imgUrl");
              } else if (element && element?.kind === 'comment') {
                const comment = {
                  ...element, 
                  data: decryptData(element?.data?.data),
                  user: {
                      ...element?.user,
                      data: element?.user?.data?.encryptedData 
                              ? decryptData(element?.user?.data?.encryptedData) 
                              : element?.user?.data,
                  }
                };
                const [activity] = await getActivitiesByIds([element?.activity_id]);
                response.data[index]["comment"] = comment;
                response.data[index]["activity"] = activity;
                response.data[index]["sender"] = comment?.user?.data?.username;
                response.data[index]["senderId"] = +comment?.user_id;
                const commentAttachment = _.get(comment, 'data.attachments.0', null);
                if (commentAttachment) {
                  const isGif = commentAttachment?.type === GIF_TYPE;
                  response.data[index]["imgUrl"] = `${(!isGif ? `${FEED_IMAGE_URL}` : '')}${commentAttachment?.url}`;
                }
              } else if (element && element?.length && element[0]?.verb === 'post') {
                response.data[index]["sender"] = element[0]?.actor?.data?.username;
                response.data[index]["senderId"] = +element[0]?.actor?.id;
                response.data[index]["activity"] = element[0];
              }
            }
            self.endOfUnresolvedFlaggedMessageList
              ? self.setResolvedFlaggedMessages(response.data)
              : self.setUnresolvedFlaggedMessages(response.data);
            self.setLoading(false);
          })
          .catch((error) => {
            self.setLoading(false);
            throw error;
          });
      } else {
        NotificationStore.setNotification("error", flagMessages.flagFetchError);
        self.setLoading(false);
        return;
      }
    },
    setUserFlagsLoading(value) {
      self.userFlagsLoading = value;
    },
    setGroupFlagsLoading(value) {
      self.groupFlagsLoading = value;
    },
    async fetchNumberOfUnResolvedAlerts(params = {}) {
      const limit = 1;
      params = {
        ...params,
        isResolved: false,
        reactionId: 2,
        $skip: 0,
        $limit: limit,
      };
      const response = await ApiService.getRequest(
        "message-reactions-summary",
        params
      );
      if (response.success) {
        self.setUnresolvedCount(response.meta);
      }
    },
    setUnresolvedCount(meta) {
      self.unresolvedTotal = meta.total;
    },
    setUrlAlertId(value) {
      self.urlAlertId = value;
    },

    async fetchUserFlags(params = {}) {
      const limit = 10;
      const userId = ProfileStore.userId;
      params = {
        ...params,
        senderId: userId,
        $skip: self.endOfUserUnresolvedFlaggedMessageList
          ? self.userResolvedFlaggedMessages.length
          : self.userUnresolvedFlaggedMessages.length,
        $limit: limit,
        reactionId: 2,
      };

      let promises = [],
        resolvedByIds = [],
        alertIds = [],
        arr = [];
      self.setUserFlagsLoading(true);
      const response = await ApiService.getRequest("message-reactions", params);
      if (!_.get(response, "data.length", 0)) {
        self.endOfUserUnresolvedFlaggedMessageList
          ? self.setEndOfUserResolvedFlaggedMessageList(true)
          : self.setEndOfUserUnresolvedFlaggedMessageList(true);
      }

      if (response.success) {
        if (params.isResolved) {
          self.setUserResolvedFlagsTotal(response.meta);
        } else {
          self.setUserUnResolvedFlagsTotal(response.meta);
        }
        if (response.data.length) {
          const pubnub = getPubnubInstanceByUserType(AuthStore.type);
          response.data.forEach((message) => {
            promises.push(
              getMessageInfo(pubnub, {
                channel: message.channel,
                messageId: message.messageId,
              })
            );
            if (params.isResolved) {
              resolvedByIds.push(message.resolvedBy);
            }
            alertIds.push(message.id);
            resolvedByIds.push(message.userId);
          });
        }
        const assigned = await self.getAssignedToResponses(alertIds);
        if (assigned && assigned.length) {
          self.setAssignedUsers(assigned);
        }
        const userResponse = await self.getUsernameFromIds(resolvedByIds);
        if (userResponse && userResponse.length) {
          self.setResolvedByUsernames(userResponse);
        }
        Promise.all(promises)
          .then((res) => {
            const data = res.reduce((newData, element) => {
              newData[_.get(element, "messages[0].timetoken", "")] = {
                sender: _.get(element, "messages[0].entry.sender", ""),
                text: _.get(element, "messages[0].entry.text", ""),
                type: _.get(element, "messages[0].entry.type", ""),
                imgUrl: _.get(element, "messages[0].entry.imgUrl", ""),
              };
              return newData;
            }, {});

            arr = _.cloneDeep(response.data);
            let groupNameFetchPromises = [];
            arr.forEach((item) => {
              const channelSplit = item.channel.split("_");
              const length = channelSplit.length;
              const channelId = channelSplit[length - 1];
              const channelType = channelSplit[0];
              let groupNameFetchParams = {
                id: channelId,
                "$select[1]": "id",
                "$select[2]": "name",
              };
              channelType === "GROUP"
                ? groupNameFetchPromises.push(
                  ApiService.getRequest("groups", groupNameFetchParams)
                )
                : groupNameFetchPromises.push(
                  ApiService.getRequest("dm-channels", groupNameFetchParams)
                );

              item.text = _.get(
                data,
                `[${item.messageId}].text`,
                "Message not found"
              );
              item.sender = _.get(
                data,
                `[${item.messageId}].sender`,
                "Sender not found"
              );
              item.type = _.get(data, `[${item.messageId}].type`, null);
              item.imgUrl = _.get(data, `[${item.messageId}].imgUrl`, "");
            });

            Promise.all(groupNameFetchPromises)
              .then((groupDataFetchResponse) => {
                const groupData = groupDataFetchResponse.reduce(
                  (newData, element) => {
                    newData[_.get(element, "data[0].id", "")] = {
                      name: _.get(element, "data[0].name"),
                    };
                    return newData;
                  },
                  {}
                );
                arr.forEach((item) => {
                  item.name = _.get(
                    groupData,
                    `[${item.channel.split("_")[2]}].name`,
                    null
                  );
                });
                if (params.isResolved) {
                  self.setUserResolvedFlaggedMessages(arr);
                } else {
                  self.setUserUnresolvedFlaggedMessages(arr);
                }
                self.setUserFlagsLoading(false);
              })
              .catch((err) => {
                self.setUserFlagsLoading(false);
              });
          })
          .catch((err) => {
            self.setUserFlagsLoading(false);
          });
      } else {
        NotificationStore.setNotification("error", flagMessages.flagFetchError);
        self.setUserFlagsLoading(false);
      }
    },
    setUserUnresolvedFlaggedMessages(data) {
      self.userUnresolvedFlaggedMessages = _.concat(
        self.userUnresolvedFlaggedMessages,
        data
      );
    },

    setGroupResolvedFlaggedMessages(data) {
      self.groupResolvedFlaggedMessages = [
        ...self.groupResolvedFlaggedMessages,
        ...data,
      ];
    },

    setGroupUnresolvedFlaggedMessages(data) {
      self.groupUnresolvedFlaggedMessages = [
        ...self.groupUnresolvedFlaggedMessages,
        ...data,
      ];
    },

    setAlertsTotal(meta) {
      self.numberOfUnResolvedFlags = meta.total;
    },

    setUserUnResolvedFlagsTotal(meta) {
      self.userUnResolvedFlagedMessagesTotal = meta.total;
    },
    setUserResolvedFlagsTotal(meta) {
      self.userResolvedFlagedMessagesTotal = meta.total;
    },
    setGroupUnResolvedFlagsTotal(meta) {
      self.groupUnResolvedFlagedMessagesTotal = meta.total;
    },
    setGroupResolvedFlagsTotal(meta) {
      self.groupResolvedFlagedMessagesTotal = meta.total;
    },

    setUserResolvedFlaggedMessages(data) {
      self.userResolvedFlaggedMessages = _.concat(
        self.userResolvedFlaggedMessages,
        data
      );
    },

    removeEntry(userId) {
      self.unresolvedFlaggedMessages.forEach((entry) => {
        if (entry.senderId === userId || entry.userId === userId) {
          destroy(entry);
        }
      });
      self.resolvedFlaggedMessages.forEach((entry) => {
        if (entry.senderId === userId || entry.userId === userId) {
          destroy(entry);
        }
      });
    },

    clearUserFlags() {
      self.userFlagsLoading = false;
      self.endOfUserUnresolvedFlaggedMessageList = false;
      self.endOfUserResolvedFlaggedMessageList = false;
      self.userUnresolvedFlaggedMessages = [];
      self.userResolvedFlaggedMessages = [];
    },

    clear() {
      self.unresolvedFlaggedMessages = [];
      self.resolvedFlaggedMessages = [];
      self.groupResolvedFlaggedMessages = [];
      self.groupUnresolvedFlaggedMessages = [];
      self.loading = false;
      self.groupFlagsLoading = false;
      self.endOfUnresolvedFlaggedMessageList = false;
      self.endOfResolvedFlaggedMessageList = false;
      self.endOfGroupResolvedFlaggedMessageList = false;
      self.endOfGroupUnresolvedFlaggedMessageList = false;
      self.resolveLoader = false;
      self.selectedMessageId = null;
    },

    setLoading(value) {
      self.loading = value;
    },

    setUnresolvedFlaggedMessages(data) {
      self.unresolvedFlaggedMessages = _.concat(
        self.unresolvedFlaggedMessages,
        data
      );
    },

    setResolvedFlaggedMessages(data) {
      self.resolvedFlaggedMessages = _.concat(
        self.resolvedFlaggedMessages,
        data
      );
    },

    async fetchGroupFlags(params = {}) {
      const { selectedGroup } = GroupListStore;
      const groupId = selectedGroup.id;
      const limit = 10;
      params = {
        ...params,
        channel: `GROUP_CHAT_${groupId}`,
        $limit: limit,
        reactionId: 2,
      };
      if (params.isResolved) {
        params = { ...params, "$sort[updatedAt]": -1 };
      } else {
        params = { ...params, "$sort[createdAt]": -1 };
      }
      let promises = [],
        resolvedByIds = [],
        alertIds = [],
        arr = [];
      self.setGroupFlagsLoading(true);
      const response = await ApiService.getRequest("message-reactions", params);

      if (!_.get(response, "data.length", 0)) {
        self.endOfGroupUnresolvedFlaggedMessageList
          ? self.setEndOfGroupResolvedFlaggedMessageList(true)
          : self.setEndOfGroupUnresolvedFlaggedMessageList(true);
      }
      if (response.success) {
        if (params.isResolved) {
          self.setGroupResolvedFlagsTotal(response.meta);
        } else {
          self.setGroupUnResolvedFlagsTotal(response.meta);
        }

        if (response.data.length) {
          const pubnub = getPubnubInstanceByUserType(AuthStore.type);
          response.data.forEach((message) => {
            promises.push(
              getMessageInfo(pubnub, {
                channel: message.channel,
                messageId: message.messageId,
              })
            );
            if (params.isResolved) {
              resolvedByIds.push(message.resolvedBy);
            }
            resolvedByIds.push(message.userId);
            alertIds.push(message.id);
          });
        }
        const assigned = await self.getAssignedToResponses(alertIds);
        if (assigned && assigned.length) {
          self.setAssignedUsers(assigned);
        }
        const userResponse = await self.getUsernameFromIds(resolvedByIds);
        if (userResponse && userResponse.length) {
          self.setResolvedByUsernames(userResponse);
        }
        Promise.all(promises)
          .then((res) => {
            const data = res.reduce((newData, element) => {
              newData[_.get(element, "messages[0].timetoken", "")] = {
                sender: _.get(element, "messages[0].entry.sender", ""),
                text: _.get(element, "messages[0].entry.text", ""),
                type: _.get(element, "messages[0].entry.type", ""),
                imgUrl: _.get(element, "messages[0].entry.imgUrl", ""),
              };
              return newData;
            }, {});

            arr = _.cloneDeep(response.data);
            let groupNameFetchPromises = [];
            arr.forEach((item) => {
              const channelSplit = item.channel.split("_");
              const channelId = channelSplit[2];
              const channelType = channelSplit[0];
              let groupNameFetchParams = {
                id: channelId,
                "$select[1]": "id",
                "$select[2]": "name",
              };
              channelType === "GROUP"
                ? groupNameFetchPromises.push(
                  ApiService.getRequest("groups", groupNameFetchParams)
                )
                : groupNameFetchPromises.push(
                  ApiService.getRequest("dm-channels", groupNameFetchParams)
                );
              item.text = _.get(
                data,
                `[${item.messageId}].text`,
                "Message not found"
              );
              item.sender = _.get(
                data,
                `[${item.messageId}].sender`,
                "Sender not found"
              );
              item.type = _.get(data, `[${item.messageId}].type`, null);
              item.imgUrl = _.get(data, `[${item.messageId}].imgUrl`, "");
            });
            Promise.all(groupNameFetchPromises).then(
              (groupDataFetchResponse) => {
                const groupData = groupDataFetchResponse.reduce(
                  (newData, element) => {
                    newData[_.get(element, "data[0].id", "")] = {
                      name: _.get(element, "data[0].name"),
                    };
                    return newData;
                  },
                  {}
                );
                arr.forEach((item) => {
                  item.name = _.get(
                    groupData,
                    `[${item.channel.split("_")[2]}].name`,
                    null
                  );
                });
                if (params.isResolved) {
                  self.setGroupResolvedFlaggedMessages(arr);
                } else {
                  self.setGroupUnresolvedFlaggedMessages(arr);
                }
                self.setGroupFlagsLoading(false);
              }
            );
          })
          .catch((error) => {
            self.setGroupFlagsLoading(false);
          });
      } else {
        NotificationStore.setNotification("error", flagMessages.flagFetchError);
        self.setGroupFlagsLoading(false);
      }
    },
    async changeResolveStatus(message, isResolved) {
      const path = message?.isForumFlag ? "forum-flags" : "message-reactions";
      self.setSelectedMessageId(message.id);
      this.setResolveLoader(true);
      const response = await ApiService.patchRequest(path, message.id, {
        isResolved,
      });
      this.setResolveLoader(false);
      if (response.success) {
        ProfileStore.setProfileVisibility(false);
        ProfileStore.changeSelectedAction("");
      }
    },
    setSelectedMessageId(id) {
      self.selectedMessageId = id;
    },
    async changeUserFlagResolveStatus(message, isResolved) {
      const path = "message-reactions";
      self.setSelectedMessageId(message.id);
      this.setResolveLoader(true);
      const response = await ApiService.patchRequest(path, message.id, {
        isResolved,
      });
      this.setResolveLoader(false);
      if (response.success) {
        ProfileStore.setProfileVisibility(false);
        ProfileStore.changeSelectedAction("");
        isResolved
          ? self.addUserResolvedMessage(message, response)
          : self.addUserUnresolvedMessage(message, response);
      }
    },
    async changeGroupFlagResolveStatus(message, isResolved) {
      const path = "message-reactions";
      self.setSelectedMessageId(message.id);
      this.setResolveLoader(true);
      const response = await ApiService.patchRequest(path, message.id, {
        isResolved,
      });
      this.setResolveLoader(false);
      if (response.success) {
        ProfileStore.setProfileVisibility(false);
        ProfileStore.changeSelectedAction("");
        isResolved
          ? self.addGroupResolvedMessage(message, response)
          : self.addGroupUnresolvedMessage(message, response);
      }
    },
    addUserResolvedMessage(message, response) {
      const clonedData = _.cloneDeep(self.userUnresolvedFlaggedMessages);
      self.userUnresolvedFlaggedMessages = clonedData.filter(
        (item) => item.id !== response.data.id
      );
      self.userResolvedFlaggedMessages = [
        message,
        ...self.userResolvedFlaggedMessages,
      ];
    },
    addGroupResolvedMessage(message, response) {
      const clonedData = _.cloneDeep(self.groupUnresolvedFlaggedMessages);
      self.groupUnresolvedFlaggedMessages = clonedData.filter(
        (item) => item.id !== response.data.id
      );
      self.groupResolvedFlaggedMessages = [
        message,
        ...self.groupResolvedFlaggedMessages,
      ];
    },
    async fetchUrlAlert(selectedId) {
      self.setUrlAlertLoading(true);
      const params = { id: selectedId };
      const res = await ApiService.getRequest("message-reactions", params);
      if (res.success && res.data.length) {
        const alert = _.cloneDeep(res.data[0]);
        const groupId = alert.channel.split("_")[2];
        const userId = alert.userId;
        const userParams = {
          id: userId,
          "$select[1]": "username",
        };
        const userResponse = await ApiService.getRequest("users", userParams);
        const groupParams = {
          id: groupId,
          "$select[1]": "name",
        };
        const groupResponse = await ApiService.getRequest(
          "groups",
          groupParams
        );
        const pubnub = getPubnubInstanceByUserType(AuthStore.type);
        const messageInfo = await getMessageInfo(pubnub, {
          channel: alert.channel,
          messageId: alert.messageId,
        });
        const data = {
          [messageInfo.messages[0].timetoken]: {
            sender: _.get(messageInfo, "messages[0].entry.sender", ""),
            text: _.get(messageInfo, "messages[0].entry.text", ""),
            type: _.get(messageInfo, "messages[0].entry.type", ""),
            imgUrl: _.get(messageInfo, "messages[0].entry.imgUrl", ""),
          },
        };

        alert.text = _.get(
          data,
          `[${alert.messageId}]text`,
          "Message not found"
        );
        alert.sender = _.get(
          data,
          `[${alert.messageId}]sender`,
          "Sender not found"
        );
        alert.type = _.get(data, `[${alert.messageId}]type`, null);
        alert.imgUrl = _.get(data, `[${alert.messageId}]imgUrl`, "");
        alert.username = _.get(userResponse, "data[0].username", null);
        alert.name = _.get(groupResponse, "data[0].name", null);

        self.setUrlAlert(alert);
      } else {
        self.setUrlAlertLoading(false);
        return;
      }
    },
    setUrlAlertLoading(value) {
      self.urlAlerlLoading = value;
    },
    setUrlAlert(alert) {
      const groupId = Number(alert.channel.split("_")[2]);
      self.selectedUrlAlert = FlaggedMessage.create(alert);
      ProfileStore.changeSelectedAction("View in context");
      ProfileStore.setSelectedAlert(alert);
      MessagesStore.loadChannelMessages(
        groupId,
        alert.name,
        "group",
        alert.messageId
      );
      MessagesStore.setScrollVisible(true);
      MessagesStore.selectedGroup.setFlaggedMessageTimeToken(alert.messageId);
      self.setUrlAlertLoading(false);
    },
    async addToUnResolvedSignal(newAlert) {
      const pubnub = getPubnubInstanceByUserType(AuthStore.type);
      const messageInfo = await getMessageInfo(pubnub, {
        channel: newAlert.channel,
        messageId: newAlert.messageId,
      });

      let fetchIdParams = {
        username: _.get(messageInfo, "messages[0].entry.sender", ""),
        "$select[1]": "id",
        "$select[2]": "username",
      };
      const userResponse = await ApiService.getRequest("users", fetchIdParams);

      const data = {
        [messageInfo.messages[0].timetoken]: {
          sender: _.get(messageInfo, "messages[0].entry.sender", ""),
          text: _.get(messageInfo, "messages[0].entry.text", ""),
          type: _.get(messageInfo, "messages[0].entry.type", ""),
          imgUrl: _.get(messageInfo, "messages[0].entry.imgUrl", ""),
        },
      };
      const senderData = {
        [_.get(userResponse, "data[0].username", "")]: {
          senderId: _.get(userResponse, "data[0].id", null),
        },
      };
      newAlert.text = _.get(
        data,
        `[${newAlert.messageId}]text`,
        "Message not found"
      );
      newAlert.sender = _.get(
        data,
        `[${newAlert.messageId}]sender`,
        "Sender not found"
      );
      newAlert.type = _.get(data, `[${newAlert.messageId}]type`, null);
      newAlert.imgUrl = _.get(data, `[${newAlert.messageId}]imgUrl`, "");
      newAlert.senderId = _.get(
        senderData,
        `[${newAlert.sender}]senderId`,
        null
      );
      self.addToUnresolvedFlags(newAlert);
    },
    removeFlagMessage(id, isResolved) {
      if (isResolved) {
        const alert = _.find(self.resolvedFlaggedMessages, { id });
        if (alert) {
          const clonedData = _.cloneDeep(self.resolvedFlaggedMessages);
          self.resolvedFlaggedMessages = clonedData.filter(
            (item) => item.id !== alert.id
          );
        }
      } else {
        self.unresolvedTotal = self.unresolvedTotal - 1;
        self.numberOfUnResolvedFlags = self.numberOfUnResolvedFlags - 1;
        const alert = _.find(self.unresolvedFlaggedMessages, { id });
        if (alert) {
          const clonedData = _.cloneDeep(self.unresolvedFlaggedMessages);
          self.unresolvedFlaggedMessages = clonedData.filter(
            (item) => item.id !== alert.id
          );
        }
      }
    },
    addToUnresolvedFlags(newAlert) {
      self.unresolvedFlaggedMessages = [
        newAlert,
        ...self.unresolvedFlaggedMessages,
      ];
      self.unresolvedTotal = self.unresolvedTotal + 1;
      self.numberOfUnResolvedFlags = self.numberOfUnResolvedFlags + 1;
    },
    addUserUnresolvedMessage(message, response) {
      const clonedData = _.cloneDeep(self.userResolvedFlaggedMessages);
      self.userResolvedFlaggedMessages = clonedData.filter(
        (item) => item.id !== response.data.id
      );
      self.userUnresolvedFlaggedMessages = [
        message,
        ...self.userUnresolvedFlaggedMessages,
      ];
    },
    addGroupUnresolvedMessage(message, response) {
      const clonedData = _.cloneDeep(self.groupResolvedFlaggedMessages);
      self.groupResolvedFlaggedMessages = clonedData.filter(
        (item) => item.id !== response.data.id
      );
      self.groupUnresolvedFlaggedMessages = [
        message,
        ...self.groupUnresolvedFlaggedMessages,
      ];
    },

    updateUsingSignal(id, isResolved) {
      if (self.selectedUrlAlert && self.selectedUrlAlert.id === id) {
        self.selectedUrlAlert.isResolved = isResolved;
      }
      if (isResolved) {
        const alert = _.find(self.unresolvedFlaggedMessages, { id });

        if (alert) {
          const clonedData = _.cloneDeep(self.unresolvedFlaggedMessages);
          self.unresolvedFlaggedMessages = clonedData.filter(
            (item) => item.id !== alert.id
          );
          self.numberOfUnResolvedFlags = self.numberOfUnResolvedFlags - 1;
          self.unresolvedTotal = self.unresolvedTotal - 1;
          self.resolvedFlaggedMessages = [
            toJS(alert),
            ...self.resolvedFlaggedMessages,
          ];
        }
      } else {
        const alert = _.find(self.resolvedFlaggedMessages, { id });

        if (alert) {
          const clonedData = _.cloneDeep(self.resolvedFlaggedMessages);
          self.resolvedFlaggedMessages = clonedData.filter(
            (item) => item.id !== alert.id
          );
          self.unresolvedTotal = self.unresolvedTotal + 1;
          self.numberOfUnResolvedFlags = self.numberOfUnResolvedFlags + 1;
          self.unresolvedFlaggedMessages = [
            toJS(alert),
            ...self.unresolvedFlaggedMessages,
          ];
        }
      }
    },
    setEndOfUnresolvedFlaggedMessageList(value) {
      self.endOfUnresolvedFlaggedMessageList = value;
    },
    setResolveLoader(value) {
      self.resolveLoader = value;
    },
    setEndOfUserResolvedFlaggedMessageList(value) {
      self.endOfUserResolvedFlaggedMessageList = value;
    },
    setEndOfGroupResolvedFlaggedMessageList(value) {
      self.endOfGroupResolvedFlaggedMessageList = value;
    },
    setEndOfGroupUnresolvedFlaggedMessageList(value) {
      self.endOfGroupUnresolvedFlaggedMessageList = value;
    },
    setEndOfUserUnresolvedFlaggedMessageList(value) {
      self.endOfUserUnresolvedFlaggedMessageList = value;
    },
    setEndOfResolvedFlaggedMessageList(value) {
      self.endOfResolvedFlaggedMessageList = value;
    },
    setSelectedMessage(value) {
      self.selectedMessage = _.cloneDeep(value);
      self.selectedMessageId = value?.id;
    },
    setSelectedTab(value) {
      self.selectedTab = value;
    }
  }));

export default FlaggedMessagesList;
