import React, { useEffect, useState } from "react";
import { Button, Modal, Select } from "antd";
import _ from "lodash";
import { inject, observer } from "mobx-react";
const Option = Select.Option;

const MentionUserModal = inject("store")(observer((props) => {
    const [user, setUser] = useState("");
    const [mentionOptions, setMentionOptions] = useState([]);

    const {
        onConfirm,
        onCancel,
        isModalVisible,
        addMentionInMessage,
        getMentionOptions,
        store: {
            ForumFeedStore: { loadingMentionOptions }
        },
    } = props;

    const handleSearch = _.debounce(async (value) => {
        const updatedMentionOptions = await getMentionOptions(value);
        setMentionOptions(updatedMentionOptions);
        return updatedMentionOptions;
    }, 500);

    const clickonOk = () => {
        addMentionInMessage(user)
        setUser('');
        onConfirm();
    }
    const clickOnCancle = () => {
        setUser('');
        onCancel();
    }

    useEffect(() => {
        (async () => {
            const initialMentionOptions = await getMentionOptions();
            setMentionOptions(initialMentionOptions);
        })();
    }, []);

    return (
        <Modal
            title="Please Select User"
            centered
            width={"400px"}
            visible={isModalVisible}
            onOk={clickonOk}
            onCancel={clickOnCancle}
        >

            <Select
                showSearch
                onChange={(value) => { setUser(value) }}
                filterOption={false} // Disable default Antd filtering behavior
                onSearch={handleSearch}
                value={user}
                style={{ width: 200 }}
                placeholder="Select an option"
                loading={loadingMentionOptions ?? false}
            >
                {mentionOptions.map(option => (
                  <Option key={`${option}1`} value={option}>
                      {option}
                  </Option>
                ))}
            </Select>
            <div className="form-confirm-modal">
                <Button key="back" style={{ marginRight: 10 }} onClick={clickOnCancle}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" disabled={user.length == 0} onClick={clickonOk}>
                    Ok
                </Button>
            </div>
        </Modal>
    );
}));

export default MentionUserModal;
