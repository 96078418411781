import React, { Fragment } from "react";
import { Spin, Input, Icon, Select } from "antd";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import moment from "moment";

import {
    TERRITORIES_TYPE_ALL_TERRITORIES,
    USER_STATUS_ALL_STATUS,
    USER_BADGE_TYPES_ARR,
    MESSAGE_TYPE_FLAGS,
    VERIFICATION_PIN,
} from '../../constants/GlobalConstant';
import { ALIKE_MODERATOR } from '../../constants/UserRolesConstant';
import { getAllTerritoriesBySites, getSortedDMsByDateSection } from '../../utils/CommonUtils';
import { IMG_DOCUMENT, IMG_FORM_SEND, IMG_GALLERY, IMG_HEADPHONES, IMG_GIF, IMG_VIDEO, IMG_PIN_VERIFIED, IMG_PIN_UNCONFIRMED } from '../../utils/ImageUtils';
import { IS_ISLAND } from "../../utils/getEnvironment";
import UserBadges from "../UI/UserBadges";
import CustomPinModal from "../UI/CustomPinModal";

const { Search } = Input;
const { Option } = Select;

const {
    MESSAGE_TYPE_TEXT,
    MESSAGE_TYPE_DM_RESOLVE,
    MESSAGE_TYPE_IMG,
    MESSAGE_TYPE_DOCUMENT,
    MESSAGE_TYPE_FORM,
    MESSAGE_TYPE_AUDIO,
    MESSAGE_TYPE_VIDEO,
    MESSAGE_TYPE_GIF
} = MESSAGE_TYPE_FLAGS;

@inject("store")
@observer
class DMPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allTerritories: [],
            isPinVerificationModalVisible: false,
            isPinVerified: false,
            pinValue: '',
            selectedAdminUsernameForDMListing: null
        }
    }

    componentDidMount() {
        const {
            store: {
                AuthStore: {
                    dmsData,
                    username,
                    setAllDMsData,
                    setSelectedTerritoryForDM,
                    setSelectedUserStatusForDM
                },
                MemberListStore: { adminUsers, fetchAdminUsers },
                SiteStore: { allTerritories, allSites },
            }
        } = this.props;

        const sitesData = toJS(allSites);
        let allDMsData = [];
        let territoriesData = [];
        if (dmsData && dmsData.length) {
            allDMsData = [...getSortedDMsByDateSection(dmsData, true)];
        }
        if (sitesData && !allTerritories.length) {
            territoriesData = getAllTerritoriesBySites(sitesData);
        }
        if (!adminUsers.length) {
            // fetch admins list to display in select dropdown
            fetchAdminUsers({ isMarigoldAdminsRequired: true });
        }
        // When component mount we need to set default territory as "ALL_TERRITORIES"
        setSelectedTerritoryForDM(TERRITORIES_TYPE_ALL_TERRITORIES);
        // When component mount we need to set default user status as "ALL_STATUS"
        setSelectedUserStatusForDM(USER_STATUS_ALL_STATUS);
        // Then we will set sorted DMs data to the mobx state
        setAllDMsData(allDMsData);
        this.setState({
            ...this.state,
            allTerritories: territoriesData,
            // Setting the loggedin user's username to show above DMs list
            selectedAdminUsernameForDMListing: username
        });
    }

    componentWillUnmount() {
        const {
            store: {
                AuthStore: { setSelectedAdminIdForDMListing, fetchDMsForSpecificUser },
            }
        } = this.props;
        // We need to reset selected admin id when component unmounts
        setSelectedAdminIdForDMListing(null);
        // We need to fetch dms data for loggedin user
        fetchDMsForSpecificUser();
    }

    // This function is used to fetch all data by resetting every filter that is applied
    handleAllUsersClick = () => {
        const {
            store: {
                AuthStore: {
                    setIsRepliedFilterSelected,
                    setIsMyCaseloadSelected,
                    setSelectedTerritoryForDM,
                    setSelectedUserStatusForDM,
                    setAllDMsData,
                    fetchDMsForSpecificUser
                },
                MessagesStore: {
                    updateQueryString
                }
            }
        } = this.props;
        // If "Everyone" is already selected then we don't need to fetch users list again
        if (this.checkValidationForEveryoneButton()) {
            return;
        }
        // We need to reset "isMyCaseloadSelected" to false
        setIsMyCaseloadSelected(false);
        // We need to reset "isRepliedFilterSelected" to false
        setIsRepliedFilterSelected(false);
        // We need to reset selected territory to "ALL_TERRITORIES"
        setSelectedTerritoryForDM(TERRITORIES_TYPE_ALL_TERRITORIES);
        // We need to reset user selected status to "ALL_STATUS"
        setSelectedUserStatusForDM(USER_STATUS_ALL_STATUS);
        // We need to reset search box to empty string
        updateQueryString('searchedDmQuery', '');
        // We will reset existing "allDMsData" and fetch the latest data 
        setAllDMsData([]);
        // We need to fetch dms data for loggedin user
        fetchDMsForSpecificUser();
    };

    // This function is used to check validation whenever filter changes 
    // then according to this we will apply selected border on "Everyone" button
    checkValidationForEveryoneButton = () => {
        const {
            store: {
                AuthStore: {
                    isRepliedFilterSelected,
                    isMyCaseloadSelected,
                    selectedTerritoryForDM,
                    selectedUserStatusForDM
                },
                MessagesStore: {
                    searchedDmQuery
                }
            }
        } = this.props;
        let isAllUsersSelected = true;

        // If any territory is selected then we will return false
        // If any user status is selected then we will return false
        // If Replied filter is selected then we will return false
        const isConditionValidate = selectedTerritoryForDM !== TERRITORIES_TYPE_ALL_TERRITORIES ||
            selectedUserStatusForDM !== USER_STATUS_ALL_STATUS ||
            searchedDmQuery ||
            isRepliedFilterSelected ||
            isMyCaseloadSelected;

        if (isConditionValidate) {
            isAllUsersSelected = false;
        }

        return isAllUsersSelected;
    };

    // This function is used to create territory change from dropdown
    handleTerritoryChange = (value) => {
        const {
            store: {
                AuthStore: {
                    setSelectedTerritoryForDM,
                    fetchDMsForSpecificUser,
                    setAllDMsData
                },
            }
        } = this.props;
        // Here we will set allDMsData to empty array
        setAllDMsData([]);
        // Here we will set the value of selected territory from dropdown to the mobx state
        setSelectedTerritoryForDM(value);
        // Then we will fetch all dms related to loggedin user
        fetchDMsForSpecificUser();
    };

    // This function is used to create user status change from dropdown 
    handleUserStatus = (value) => {
        const {
            store: {
                AuthStore: {
                    setSelectedUserStatusForDM,
                    fetchDMsForSpecificUser,
                    setAllDMsData
                },
            }
        } = this.props;
        // Here we will set allDMsData to empty array
        setAllDMsData([]);
        // Here we will set the value of selected user status from dropdown to the mobx state
        setSelectedUserStatusForDM(value);
        // Then we will fetch all dms related to loggedin user
        fetchDMsForSpecificUser();
    };

    // Here we are filtering users who have not replied by any peer (username contains "marigold")
    handleRepliedActionClick = () => {
        const {
            store: {
                AuthStore: {
                    setLoading,
                    setAllDMsData,
                    dmsData,
                    setIsRepliedFilterSelected,
                    isRepliedFilterSelected
                }
            }
        } = this.props;
        setIsRepliedFilterSelected(!isRepliedFilterSelected);

        setLoading(true);
        let filteredDMArr = [...dmsData];
        if (!isRepliedFilterSelected) {
            filteredDMArr = dmsData.filter(({ message }) => !message?.sender.toLowerCase().includes('marigold'));
        }
        setAllDMsData([...getSortedDMsByDateSection(filteredDMArr, true)]);
        setLoading(false);
    };

    handleMyCaseloadClick = () => {
        const {
            store: {
                AuthStore: {
                    isMyCaseloadSelected,
                    setIsMyCaseloadSelected,
                    fetchDMsForSpecificUser,
                    setAllDMsData
                }
            }
        } = this.props;
        // Here we will set allDMsData to empty array
        setAllDMsData([]);
        // We need to set "isMyCaseloadSelected" to selected value to mobx state
        setIsMyCaseloadSelected(!isMyCaseloadSelected);
        // Then we will fetch all dms related to loggedin user
        fetchDMsForSpecificUser();
    };

    // Here we are filtering users by username which matches the search query
    handleEnterPressOnSearch = () => {
        const {
            store: {
                AuthStore: {
                    setLoading,
                    setAllDMsData,
                    dmsData,
                },
                MessagesStore: {
                    searchedDmQuery
                }
            }
        } = this.props;

        setLoading(true);
        let filteredDMArr = [...dmsData];
        filteredDMArr = dmsData.filter(({ usersData = [] }) => {
            if (usersData.length) {
                return usersData.some(({ username }) => username && _.includes(username.toLowerCase(), searchedDmQuery.toLowerCase()));
            }
            return false;
        });
        setAllDMsData([...getSortedDMsByDateSection(filteredDMArr, true)]);
        setLoading(false);
    };

    // Here we are adding icon (For Audio, Video, Image, Form etc.) with last message that was sent by user or peer
    getIconWithLastMessage = (messageData = {}) => {
        const { type, text, fileName, formName } = messageData;
        switch (type) {
            case MESSAGE_TYPE_TEXT:
                return (<>{text}</>);

            case MESSAGE_TYPE_FORM:
                return (<><img src={IMG_FORM_SEND} alt="Form" className="last-message icon" /> {formName}</>);

            case MESSAGE_TYPE_DOCUMENT:
                return (<><img src={IMG_DOCUMENT} alt="Document" className="last-message icon" />  {fileName || 'Document'}</>);

            case MESSAGE_TYPE_IMG:
                return (<><img src={IMG_GALLERY} alt="Image" className="last-message icon" /> Image</>);

            case MESSAGE_TYPE_GIF:
                return (<><img src={IMG_GIF} alt="GIF" className="last-message icon" />  GIF</>);

            case MESSAGE_TYPE_AUDIO:
                return (<><img src={IMG_HEADPHONES} alt="Audio" className="last-message icon" />  Audio: {fileName || 'Audio'}</>);

            case MESSAGE_TYPE_VIDEO:
                return (<><img src={IMG_VIDEO} alt="Video" className="last-message icon" />  Video: {fileName || 'Video'}</>);

            default:
                return <>Message...</>;
        }
    };

    // Here we are rendering last message with the message icon
    renderLastMessageByMessageType = (messageData = {}) => {
        const {
            store: {
                AuthStore: { username },
                FormMessageStore: { formList },
            }
        } = this.props;
        const { type, sender, formId } = messageData;
        const senderUserName = username === sender ? 'You' : sender;
        if (type === MESSAGE_TYPE_DM_RESOLVE) {
            return (<div className="last-message">This chat is resolved by {sender}</div>);
        }
        let formName = 'Form';
        if (type === MESSAGE_TYPE_FORM && formId) {
            const formFound = formList.find((formObj) => formObj.id === +formId);
            if (formFound) {
                formName = formFound.name;
            }
        }
        return (<div className="last-message">{senderUserName}: {this.getIconWithLastMessage({ ...messageData, formName })}</div>);
    };

    // Here we are rendering all DM names in left section with their assigned badges
    renderUsernamesInDMLeftSection = (usersData = []) => {
        return (<Fragment>
            {usersData.map(({ id, username, badgeType }, index) => (
                <span key={index}>
                    {username}
                    {badgeType ? <UserBadges className="chat-user-badge" badgeType={badgeType} /> : null}
                    {index === usersData.length - 1 ? '' : ', '}
                </span>
            ))}
        </Fragment>);
    };

    // Here we are rendering all usernames list for DM
    renderUsernamesListForDM = (dmsData = []) => {
        const {
            store: {
                AuthStore: {
                    isUserSuspended,
                    type,
                    setIsUserDMResolved
                },
                MessagesStore: {
                    selectedGroup,
                    hasUnreadMessages,
                    hasDeletedMessages,
                },
                NotificationStore: { setNotification },
            },
            getDmNames,
            handleClickDM,
        } = this.props;

        return (<ul>
            {
                _.uniqBy(dmsData, 'id').map((dmObj, index) => {
                    const { id, name: channelName, isBot, usersData = [], message = null, timetoken = null, isResolved = false } = dmObj;
                    const name = getDmNames(channelName);
                    const highlighted = selectedGroup.id === id;
                    const unreadMessages = hasUnreadMessages(id);
                    const deletedMessages = hasDeletedMessages(`DIRECT_MESSAGE_${id}`);

                    return (<li key={index} style={{
                        background: highlighted && "#D8D8D8",
                        width: '100%'
                    }}
                        onClick={() => {
                            if (isUserSuspended(id)) {
                                setNotification("error", "You have been suspended from this group.", null, 2.5);
                            } else {
                                handleClickDM(id, channelName, type, isBot);
                                setIsUserDMResolved(isResolved);
                            }
                        }}
                        className="list-group-item d-flex justify-content-between align-items-center observe"
                    >
                        <div style={{ width: '80%' }}>
                            <h3>{usersData && usersData.length ? this.renderUsernamesInDMLeftSection(usersData) : name}</h3>
                            {message ? this.renderLastMessageByMessageType(message) :
                                (<div className="last-message">No messages send yet.</div>)
                            }
                        </div>
                        <div style={{ width: '20%', textAlign: 'center' }}>
                            {unreadMessages && (
                                <span className="badge badge-primary badge-pill" />
                            )}
                            {deletedMessages && (
                                <span className="badge badge-danger badge-pill" />
                            )}
                            {message && timetoken ? <small>{moment(timetoken).format('hh:mm A')}</small> : null}

                        </div>
                    </li>)
                })}
        </ul>);
    };

    renderDMSectionListData = (dmsSectionListData = []) => {
        return (<div className="dm-section-list-container">
            {dmsSectionListData.map(({ title, data: userDmsData }, index) => (
                <React.Fragment key={index}>
                    {userDmsData && userDmsData.length ? (
                        <div className="dm-list-date-section">
                            <span className="date-title">{title}</span>
                            {this.renderUsernamesListForDM(userDmsData)}
                        </div>
                    ) : null}
                </React.Fragment>
            ))}
        </div>);
    };

    reloadUserDMsList = () => {
        const {
            store: {
                AuthStore: {
                    setAllDMsData,
                    loading,
                    fetchDMsForSpecificUser,
                    setIsRepliedFilterSelected,
                    isRepliedFilterSelected
                },
                MessagesStore: {
                    updateQueryString
                }
            }
        } = this.props;
        if (loading) return;
        // Here we will set allDMsData to empty array
        setAllDMsData([]);
        // If value of "isRepliedFilterSelected" is true then we will set it to false
        if (isRepliedFilterSelected) {
            setIsRepliedFilterSelected(false);
        }
        // Here we will reset the search value of DM
        updateQueryString('searchedDmQuery', '');
        // Then we will fetch all dms related to loggedin user
        fetchDMsForSpecificUser();
    };

    // This function is used to clear search value entered in search box
    clearSearchString = () => {
        const {
            store: {
                MessagesStore: {
                    updateQueryString
                },
            },
        } = this.props;
        // Here we will reset the search value
        updateQueryString('searchedDmQuery', '');
        // Here we will fetch all DMs again 
        this.handleEnterPressOnSearch();
    };

    // Here we are toggling the pin verification modal
    togglePinVerificationModal = (value) => {
        this.setState({
            ...this.state,
            isPinVerificationModalVisible: value,
            pinValue: ''
        });
    };

    // Here we are changing pin value from text input
    handlePinChange = (event) => {
        const pinValue = event.target.value;
        this.setState({
            ...this.state,
            pinValue
        });
    };

    // Here we are verifying that pin is valid or not
    handlePinVerifyClick = () => {
        const {
            store: {
                NotificationStore: { setNotification },
            },
        } = this.props;
        const { pinValue } = this.state;
        // PIN is stored as string so we are parsing that to number
        const parsedPinValue = +pinValue;
        // If type of PIN is number and it is equal to our predefined PIN then we are setting "isPinVerified" to true 
        if (parsedPinValue && parsedPinValue === VERIFICATION_PIN) {
            this.setState({
                ...this.state,
                isPinVerified: true
            });
            // Show a toaster that PIN verification is successful
            setNotification('success', 'Verification succeeded');
            return;
        }
        // Show a toaster that entered PIN is not valid. 
        setNotification('error', 'Please enter a valid pin.');
    };

    // Here we are keeping track of admin change from admin dropdown after verification is successful
    handleAdminChangeForDMListing = (selectedId) => {
        const {
            store: {
                AuthStore: { setSelectedAdminIdForDMListing },
            },
        } = this.props;
        setSelectedAdminIdForDMListing(selectedId);
    };

    // When any admin is selected from dropdown then we are fetching its all DM by calling dm-channels API
    findDMsForSelectedAdmin = () => {
        const {
            store: {
                AuthStore: {
                    setAllDMsData,
                    selectedAdminIdForDMListing,
                    fetchDMsForSpecificUser
                },
                MemberListStore: { adminUsers },
            },
        } = this.props;
        // Here we are finding selected admin details by selectedAdminId, which is stored in our local state after changing 
        const selectedAdminDetails = adminUsers.find((obj) => obj.id === selectedAdminIdForDMListing);
        if (selectedAdminDetails) {
            // When we have admin details then we are changing the value of DMs data to empty array
            setAllDMsData([]);
            // Here we are fetching DMs for selected admin from dropdown
            fetchDMsForSpecificUser();
            // Here we are setting isPinVerificationModalVisible to false as we need to hide that modal after clicking on "Find DMs" button
            this.setState({
                ...this.state,
                selectedAdminUsernameForDMListing: selectedAdminDetails.username,
                isPinVerificationModalVisible: false
            });
        }
    };

    // Here we need to reset selected admin DMs when clicking on cross icon near username
    resetSelectedAdminForDMListing = () => {
        const {
            store: {
                AuthStore: {
                    username,
                    setAllDMsData,
                    fetchDMsForSpecificUser,
                    setSelectedAdminIdForDMListing
                },
            },
        } = this.props;
        // First we need to set selected admin value to null
        setSelectedAdminIdForDMListing(null);
        // We need to set "allDMsData" to empty array
        setAllDMsData([]);
        // Now we need to fetch DMs of loggedin user
        fetchDMsForSpecificUser();
        // Here we need to set username of loggedin user to local state
        this.setState({
            ...this.state,
            selectedAdminUsernameForDMListing: username,
        });
    };

    render() {
        const {
            store: {
                AuthStore: {
                    username,
                    loading,
                    dmsData,
                    allDMsData,
                    type: userType,
                    isRepliedFilterSelected,
                    selectedTerritoryForDM,
                    selectedUserStatusForDM,
                    isMyCaseloadSelected,
                    selectedAdminIdForDMListing
                },
                MessagesStore: {
                    searchedDmQuery,
                    setQuery,
                },
                MemberListStore: { adminUsers, loading: isFetchingAdmins },
            },
            updateQueryString,
        } = this.props;
        const { allTerritories, isPinVerificationModalVisible, pinValue, isPinVerified, selectedAdminUsernameForDMListing } = this.state;

        return (
            <>
                {/* Custom PIN modal to verify PIN and select admins from there */}
                <CustomPinModal
                    visible={isPinVerificationModalVisible}
                    title={isPinVerified ? "Select Admins" : "PIN Verification"}
                    onOk={() => this.togglePinVerificationModal(false)}
                    onCancel={() => this.togglePinVerificationModal(false)}
                    pinValue={pinValue}
                    isPinVerified={isPinVerified}
                    onChange={this.handlePinChange}
                    handlePinVerifyClick={this.handlePinVerifyClick}
                    adminUsers={adminUsers}
                    selectedAdminId={selectedAdminIdForDMListing}
                    handleAdminChange={this.handleAdminChangeForDMListing}
                    findDMsForSelectedAdmin={this.findDMsForSelectedAdmin}
                    isFetchingAdmins={isFetchingAdmins}
                />

                <div style={{ marginBottom: 10 }}>
                    <Input
                        prefix={
                            <Icon type="search" style={{ color: "rgba(0,0,0,.25)" }} />
                        }
                        suffix={
                            searchedDmQuery.trim() ?
                                <Icon
                                    type="close"
                                    style={{ color: "rgba(0,0,0,.45)" }}
                                    onClick={() => this.clearSearchString()}
                                /> : null
                        }
                        size="large"
                        className='inbox-input'
                        onChange={(e) => updateQueryString("searchedDmQuery", e)}
                        value={searchedDmQuery}
                        placeholder="Search by username..."
                        onPressEnter={this.handleEnterPressOnSearch}
                        disabled={!dmsData.length}
                    />
                </div>

                {/* Wrapper for first row of DM filters */}
                {ALIKE_MODERATOR.includes(userType) ? <div className="dm-filters-row-one">
                    {/* Option to select Everyone */}
                    <span
                        className={`everyone-label ${this.checkValidationForEveryoneButton() ? 'selected' : ''}`}
                        onClick={this.handleAllUsersClick}>
                        Everyone
                    </span>

                    {/* Dropdown to select territory */}
                    <Select
                        className={TERRITORIES_TYPE_ALL_TERRITORIES === selectedTerritoryForDM ? '' : 'selected-territory-wrapper'}
                        value={selectedTerritoryForDM}
                        style={{ width: "max-content", marginLeft: 10 }}
                        onChange={this.handleTerritoryChange}
                        dropdownMatchSelectWidth={false}
                    >
                        <Option value={TERRITORIES_TYPE_ALL_TERRITORIES} disabled={TERRITORIES_TYPE_ALL_TERRITORIES === selectedTerritoryForDM}>
                            All Territories
                        </Option>
                        {allTerritories.map((territory, index) => (
                            <Option key={index} value={territory} disabled={territory === selectedTerritoryForDM}>
                                {territory}
                            </Option>
                        ))}
                    </Select>

                    {/* Dropdown to select status */}
                    <Select
                        className={USER_STATUS_ALL_STATUS === selectedUserStatusForDM ? '' : 'selected-user-status-wrapper'}
                        value={selectedUserStatusForDM}
                        style={{ width: 150, marginLeft: 10 }}
                        onChange={this.handleUserStatus}
                    >
                        <Option value={USER_STATUS_ALL_STATUS} disabled={USER_STATUS_ALL_STATUS === selectedUserStatusForDM}>
                            All Status
                        </Option>
                        {USER_BADGE_TYPES_ARR.map(({ label, value }, index) => (
                            <Option key={index} value={value} disabled={value === selectedUserStatusForDM}>
                                {label}
                            </Option>
                        ))}
                    </Select>
                </div> : null}

                {/* Wrapper for second row of DM filters */}
                {ALIKE_MODERATOR.includes(userType) ? <div className="dm-filters-row-two">

                    <span className={`filter-label ${loading ? 'selected' : ''}`}
                        onClick={this.reloadUserDMsList}>
                        Refresh
                        <Icon
                            type="reload"
                            className="reload-icon"
                            spin={loading}
                        />
                    </span>

                    {/* Option to select Replied or not filter */}
                    <span
                        className={`filter-label ${isRepliedFilterSelected ? 'selected' : ''}`}
                        onClick={this.handleRepliedActionClick}>
                        Not Replied Yet
                    </span>

                    {/* Option to select My Caseload filters */}
                    <span
                        className={`filter-label ${isMyCaseloadSelected ? 'selected' : ''}`}
                        onClick={this.handleMyCaseloadClick}>
                        My Caseload
                    </span>

                    {/* Button to open modal to validate PIN */}
                    <span
                        className={`everyone-label ${isPinVerificationModalVisible ? 'selected' : ''}`}
                        onClick={() => this.togglePinVerificationModal(true)}
                    >
                        <img src={isPinVerified ? IMG_PIN_VERIFIED : IMG_PIN_UNCONFIRMED}
                            alt="PinVerification"
                            style={{ width: 20, height: 20 }}
                        />
                    </span>
                </div> : null}

                {ALIKE_MODERATOR.includes(userType) ? <div className="selected-admin-wrapper">You are viewing the DMs of:  <strong style={{ marginLeft: 5 }}>{selectedAdminUsernameForDMListing}</strong>
                    {username !== selectedAdminUsernameForDMListing ? <Icon
                        type="close"
                        className="close-icon"
                        onClick={() => this.resetSelectedAdminForDMListing()}
                    /> : null}
                </div> : null}

                {/* List all DMs with last message */}
                {allDMsData && allDMsData.length ? this.renderDMSectionListData(allDMsData) : null}

                {loading ? (
                    <div className="form-loading">
                        <Spin />
                    </div>
                ) : allDMsData.length ? null : (
                    <div className="no-alerts">No Direct Messages yet.</div>
                )}
            </>
        );
    }
};

export default DMPanel;
