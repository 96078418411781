import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from "lodash";

import { alertLeftSide } from "../../en.json";
import { Icon, Popover, Spin } from 'antd';
import ContentList from './ContentList';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const SingleForumAlert = inject("store")(observer((props) => {

    const { 
        message,
        usernamePress,
        isResolvedMessage,
        store: {
            ProfileStore: { setSelectedAlert },
            FlaggedMessagesStore: {
                selectedMessageId,
                resolveLoader,
                setSelectedMessage
            }
        }
    } = props;

    const arr = [
        "View Profile",
        "Direct Message",
    ];

    const renderPopOverContent = (arr, message) => {
        return arr.map((item) => (
            <ContentList
                key={item}
                name={item}
                usernamePress={() => usernamePress(message?.commentId ? message?.comment?.user_id : message?.activity?.actor?.id)}
                message={message}
            />
        ));
    }

    const handleClick = async () => {
        const { activityId, commentId, isForumFlag } = message;
        const { 
            ForumFeedStore: {
                setNotificationCommentId,
                setCurrentFeedId,
                tags,
                fetchTags,
                setComments,
            },
            ProfileStore: {
                changeSelectedAction
            },
        } = props.store;
        if (isForumFlag) {
            changeSelectedAction("View in context");
            if (selectedMessageId !== message.id) {
                setSelectedAlert({});
                setSelectedMessage(message);
                if (commentId) {
                    setComments([]);
                    setNotificationCommentId(commentId);
                } else if (!tags.length) {
                    await fetchTags();
                }
                setCurrentFeedId(activityId);
            }
        }
    }

    const toggleResolveStatus = (message, flag) => {
        const { changeResolveStatus } = props.store.FlaggedMessagesStore;
        changeResolveStatus(_.cloneDeep(message), flag);
    }
    

    const title = message?.commentId 
        ? message?.comment?.data?.comment || "" 
        : message?.activity?.meta?.title || "";
    const tag = message?.commentId ? "Comment on post" : "Post";
    const subTitle = message?.commentId ? message?.activity?.meta?.title : null;
    return <React.Fragment>
        <div key={message?.id}>
            <div
                className="flag-container"
                style={
                    selectedMessageId === message.id
                    ? { border: "5px solid #4168ca" }
                    : null
                }
            >
                <div className="flag-upper">
                    <div
                        className="flag-name-container"
                        style={{ cursor: "pointer" }}
                    >
                        <div>
                            {title && (
                                <span className="flag-name word-wrap">{title}</span>
                            )}
                            {message?.imgUrl && (
                                <img src={message?.imgUrl} alt="" height="60" width="60"/>
                            )}
                        </div>
                        <div className="flag-profile-container">
                            <span className="flag-profile">
                                {tag}
                            </span>
                        </div>
                    </div>
                    <div className="flag-time">
                        {moment(message.createdAt).calendar()}
                    </div>
                </div>
                {(
                    <div className="info-icon-container">
                        <div className="flag-group">
                            {subTitle && `in ${subTitle.length > 30
                                ? `${subTitle.slice(0, 30)}...`
                                : subTitle
                            }`}
                        </div>
                    </div>
                )}
                <div className="flag-middle">
                    <span className="subHeading">
                        {`Flagged by ${message.username}`}
                    </span>
                </div>
                <div className="flag-lower">
                    <div className="flag-buttons">
                        <button
                            className="flag-message-button"
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleClick();
                            }}
                        >
                            {alertLeftSide.viewInContext}
                        </button>
                        {selectedMessageId === message.id && resolveLoader ? (
                            <div className="flag-resolve-button">
                                <Spin indicator={antIcon} />
                            </div>
                        ) : (
                            <button
                                className="flag-resolve-button"
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleResolveStatus(message, !isResolvedMessage);
                                }}
                            >
                                {isResolvedMessage ? alertLeftSide.unresolve : alertLeftSide.resolve}
                            </button>
                        )}
                    </div>
                    <div
                        className="flag-div"
                    >
                        <Popover
                            content={renderPopOverContent(arr, message)}
                            placement="right"
                            trigger="click"
                        >
                            <Icon
                                className="flag-icon"
                                type="more"
                                style={{ fontSize: 30 }}
                            />
                        </Popover>
                    </div>
                </div>
            </div>
            <hr className="horizontal-tag" />
        </div>
    </React.Fragment>
}));

export default SingleForumAlert;
