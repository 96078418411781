import React from "react";
import { Table, Select, Tooltip, Row, Col, Button, Form, Switch } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { toJS } from "mobx";

import { caseloads, toolTips, stats } from "../../en.json";
import UserBadges from "../UI/UserBadges";
import { getMonthRanges, getFormattedDate, checkIsActive } from '../../utils/CommonUtils';
import { IS_HP } from "../../utils/getEnvironment";
import {
    TABLE_ROW_RED_COLOR,
    TABLE_ROW_GREEN_COLOR,
    TABLE_ROW_YELLOW_COLOR,
    STYLE_OBJ_FOR_CERTAIN_GROUP,
    DEFAULT_GROUP_NAME,
    CASELOAD_USER_FLAGS,
    TERRITORIES_TYPE_ALL_TERRITORIES,
    TABLE_FONT_RED_COLOR,
    TABLE_FONT_YELLOW_COLOR,
    TABLE_FONT_GREEN_COLOR,
    TEXT_RED_COLOR,
    TEXT_GREEN_COLOR,
    TEXT_YELLOW_COLOR,
    SORTING_ORDERS,
    BADGE_TYPE_FLAGS_ARR,
    BADGE_TYPE_ENROLLED_CODE,
    TABLE_FONT_BLACK_COLOR,
    WAITING_FOR_REVIEW,
    WAITING_FOR_MEMBER,
    WAITING_FOR_REWARD,
    IN_PROGRESS,
    COMPLETED, customArrayCaseload
} from "../../constants/GlobalConstant";
import { IMG_CALENDAR, IMG_CHART, IMG_EYE, IMG_EYE_GREY, IMG_NOTE_GREEN, IMG_NOTE_GREY, IMG_NOTE_ORANGE, IMG_SEND_GREY, IMG_SEND_ICON, IMG_TICK_CIRCLE_SOLID, IMG_NOTE_RED, IMG_SEND_RED, IMG_EYE_RED } from "../../utils/ImageUtils";
import { FormSwitch } from "../UI/FormSwitch";
import { DEV } from "../../constants/UserRolesConstant";

const { Option } = Select;

const NOT_ASSIGNED = -1;
const WELCOME_PROCESS = -2;
const ALL_ENROLLED = -3;
let offset = new Date().getTimezoneOffset();

const previewContolArr = [
    { title: caseloads['indator'], dataIndex: 'indator', isVisible: true },
    { title: caseloads['fullName'], dataIndex: 'fullName', isVisible: true },
    { title: caseloads['username'], dataIndex: 'username', isVisible: true },
    { title: caseloads['peer'], dataIndex: 'peerFullName', isVisible: true },
    { title: caseloads['badge'], dataIndex: 'badgeType', isVisible: true },
    { title: caseloads['joinedDate'], dataIndex: 'createdAt', isVisible: true },
    { title: caseloads['groups'], dataIndex: 'groups', isVisible: true },
    { title: caseloads['dmChannels'], dataIndex: 'dmChannels', isVisible: true },
    // { title: caseloads["wellnessPlan"], dataIndex: "wellnessPlan", isVisible: true },
    // { title: caseloads["barc10Date"], dataIndex: "barc10Date", isVisible: true },
    { title: caseloads["rewardDetails"], dataIndex: "rewardDetails", isVisible: true },
    { title: caseloads["rewardB10Details"], dataIndex: "rewardB10Details", isVisible: true },
    { title: caseloads["rewardWellnessDetails"], dataIndex: "rewardWellnessDetails", isVisible: true },
    { title: caseloads["outreachNote"], dataIndex: "outreachNote", isVisible: true },
    { title: caseloads["statusQT"], dataIndex: "qtPoints", isVisible: true },
];

@inject("store")
@observer
class StatsCaseLoads extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            qtPointsSortOrder: "",
            barc10DateSortOrder: "",
            showPeerColumn: false,
            openIFrame: false,
            peerName: "",
            assignPeerId: null,
            enrollPeerId: null,
            filteredAdminUsers: []
        }
    }
    componentDidMount() {
        const {
            store: {
                MemberListStore: { adminUsersForCaseLoad, fetchAdminUsers },
                SiteStore: { allSites },
                StatsStore: { allTerritories, fetchAllTerritoriesBySites, setSelectedTerritoryForCaseLoad, setSelectedBadgeForCaseLoad, fetchPreviewControl },
                AuthStore: { type },
            }
        } = this.props;
        if (!adminUsersForCaseLoad.length) {
            // fetch admins list to display in select dropdown
            fetchAdminUsers({ isMarigoldAdminsRequired: true, isAdminsRequiredInCaseLoad: true });
        }
        setSelectedTerritoryForCaseLoad(TERRITORIES_TYPE_ALL_TERRITORIES);
        setSelectedBadgeForCaseLoad(BADGE_TYPE_ENROLLED_CODE);
        if (allSites && !allTerritories.length) {
            fetchAllTerritoriesBySites(toJS(allSites));
        }
        if (type == DEV) { previewContolArr.splice(1, 1); }
        fetchPreviewControl(previewContolArr);
    };

    componentWillUnmount() {
        const {
            store: { StatsStore }
        } = this.props;
        StatsStore.reset();
        this.setState({ showPeerColumn: false })
    };

    handleTableChange = (pagination, filters, sorter) => {
        const {
            store: {
                StatsStore: {
                    setPaginationData,
                    setSortParameters,
                    resetSortParameters,
                    selectedUserIdForCaseLoad,
                    fetchCaseLoadData
                },
            },
        } = this.props;

        let sortOrder;

        // If admin is selected in case load dropdown then and then only sorting and other thing will work
        // If we are not applying this condition then what will happen if admin is not selected first time then api call will be made by using sorting indicators in case load headers
        if (selectedUserIdForCaseLoad) {
            if (sorter.hasOwnProperty('column')) {
                const { field, order } = sorter;
                if (field && order) {
                    // if field & order is available then we will set the sort parameters in state
                    setSortParameters(field, order);
                }
            } else {
                //  if user cancel the sorting then we need to reset sort parameters and fetch data without sorting
                resetSortParameters();
            }
            // Set pagination data i.e. skip, limit while clicking on page number in table
            setPaginationData(pagination);
            // Fetch case load data according to the updated data of pagination
            fetchCaseLoadData(selectedUserIdForCaseLoad, sortOrder);
        }
    };

    handleFullNamePress = async (sender) => {
        try {
            const {
                store: {
                    ProfileStore: {
                        setMemberProfileVisibility,
                        setMemberData,
                        setAssignedPeerDetails,
                        toggleLabel,
                        reset,
                        userId,
                        getHighPointUsers,
                        changeSelectedAction,
                        getProviderModeratedGroups,
                        fetchGroupsFromForms,
                        getContactModeratedGroups,
                    },
                    DeletedMessagesStore: { setShowDeleted },
                    MemberListStore: { getMemberDetails, fetchAdminUsers },
                },
            } = this.props;
            if (sender === userId) {
                return;
            }
            reset();
            setShowDeleted(false);
            setMemberProfileVisibility(true);
            setAssignedPeerDetails({});
            fetchAdminUsers();
            const member = await getMemberDetails(sender);
            if (member) {
                const { providerId, recommendedBy } = member;
                setMemberData(member);
                if (IS_HP) {
                    getHighPointUsers();
                    if (providerId) {
                        getProviderModeratedGroups(providerId);
                    }
                    if (recommendedBy) {
                        getContactModeratedGroups(recommendedBy);
                    }
                    fetchGroupsFromForms();
                }
            }
            toggleLabel(false);
            changeSelectedAction("");
        } catch (error) {
        }
    };
    handleCaseloadsChange = async (peerId) => {
        const {
            store: {
                StatsStore: { setSortParameters, resetSortParameters, resetPagination, setSelectedUserIdForCaseLoad, setSelectedUsernameForCaseLoad, fetchCaseLoadData, setSelectedEnrolledForCaseLoad },
                MemberListStore: { adminUsersForCaseLoad, },
            }
        } = this.props;
        this.setState({ filteredAdminUsers: [] })
        const [status, id] = peerId.split('_');
        const value = parseInt(id);
        let isEnrolled = false;
        let peerName = '';
        let isAssigned = false;
        if (status === 'enroll') {
            isEnrolled = true;
            isAssigned = false;
            this.setState({ enrollPeerId: value });
            this.setState({ assignPeerId: null });
        } else if (status === 'assign') {
            isAssigned = true;
            isEnrolled = false;
            this.setState({ assignPeerId: value });
            this.setState({ enrollPeerId: null });
        } else if (status === 'option') {
            isAssigned = false;
            isEnrolled = false;
            const customArray = customArrayCaseload;
            const adminObj = customArray.find(obj => obj.id === value);
            peerName = adminObj?.fullName;
            this.setState({ assignPeerId: null });
            this.setState({ enrollPeerId: null });
        }
        if (adminUsersForCaseLoad && adminUsersForCaseLoad.length) {
            const adminObj = adminUsersForCaseLoad.find(obj => obj.id === value);
            setSelectedUsernameForCaseLoad(adminObj ? adminObj.username : null);
            if (isEnrolled) {
                peerName = `ENROLLMENT ${adminObj?.fullName}`;
            } else if (isAssigned) {
                peerName = adminObj?.fullName;
            }
        }
        this.setState({ peerName });
        resetPagination();
        // We need to reset sort parameters if admin change user in case load drop down
        resetSortParameters();
        // Need to update user id after selecting in drop down
        setSelectedUserIdForCaseLoad(value);
        setSelectedEnrolledForCaseLoad(isEnrolled);
        if (value === WELCOME_PROCESS || value === ALL_ENROLLED) {
            this.setState({ showPeerColumn: true })
            setSortParameters('createdAt', 'descend');
        } else {
            this.setState({ showPeerColumn: false })
        }
        // Fetch all the case load data if user id is changed in drop down
        fetchCaseLoadData(value);
    };
    handleSearch = value => {
        const {
            store: {
                MemberListStore: { adminUsersForCaseLoad },
            },
        } = this.props;
        const additionalOptions = customArrayCaseload;
        const combinedArray = [
            ...adminUsersForCaseLoad.map(({ id, fullName }) => ({ id: `assign_${id}`, fullName })),
            ...additionalOptions.map(({ id, fullName }) => ({ id: `option_${id}`, fullName })),
            { id: "enrolled_peers", fullName: "Enrollment Peer" },
            ...adminUsersForCaseLoad.map(({ id, fullName }) => ({ id: `enroll_${id}`, fullName: `ENROLLMENT ${fullName}` }))
        ];
        const filteredAdminUsers = combinedArray.filter(({ fullName }) =>
            fullName.toLowerCase().includes(value.toLowerCase())
        );
        this.setState({ filteredAdminUsers });

    }
    handleTerritoryChange = (value) => {
        const {
            store: {
                StatsStore: { setSelectedTerritoryForCaseLoad, selectedUserIdForCaseLoad, fetchCaseLoadData },
            }
        } = this.props;
        setSelectedTerritoryForCaseLoad(value);
        fetchCaseLoadData(selectedUserIdForCaseLoad);
    };

    handleBadgeTypeChange = (value) => {
        const {
            store: {
                StatsStore: { setSelectedBadgeForCaseLoad, selectedUserIdForCaseLoad, fetchCaseLoadData },
            }
        } = this.props;
        setSelectedBadgeForCaseLoad(value);
        fetchCaseLoadData(selectedUserIdForCaseLoad);
    };

    render() {
        const {
            store: {
                StatsStore: {
                    loading,
                    pagination,
                    usersData,
                    selectedUserIdForCaseLoad,
                    selectedUsernameForCaseLoad,
                    selectedTerritoryForCaseLoad,
                    selectedBadgeForCaseLoad,
                    getSortOrder,
                    allTerritories,
                    previewControl
                },
                MemberListStore: { adminUsersForCaseLoad },
                AuthStore: { retrieveSSOToken, type },
            },
        } = this.props;

        const { showPeerColumn } = this.state;

        // Get month range from utils function (moment) to display it in indator field
        const currentMonthRange = getMonthRanges();
        const lastMonthRange = getMonthRanges(1);

        // When user clicks on specific row then we need to show the user details popup
        const rowEvents = (record, rowIndex) => {
            return {
                onClick: () => {
                    this.handleFullNamePress(record.id);
                },
            };
        };

        // Render join date in DD/MM/YYYY format by using utils function
        const renderJoinDate = (text, row) => {
            const { createdAt } = row;
            return <>
                {createdAt ? getFormattedDate(createdAt) : null}
            </>
        };

        // From api, we are getting group names as array of strings so need to iterate over it then we can display it in table
        const renderGroupNames = (groups = [], isGroupLengthIsOneFound = false) => {
            return (
                <>
                    {groups.map((name, index) => {
                        // Here we are checking if group length is 1 and group name is equal to DEFAULT_GROUP_NAME then we need to add red border around default group name
                        const styleObj = isGroupLengthIsOneFound && name.toLowerCase().trim() === DEFAULT_GROUP_NAME ? STYLE_OBJ_FOR_CERTAIN_GROUP : {};
                        return <div style={styleObj} key={index}>{name}</div>
                    })}
                </>
            )
        };

        // From api, we are getting dm channels names as array of strings so need to iterate over it then we can display it in table
        const renderDMChannels = (dmChannels) => {
            return dmChannels.map((dmChannel, index) => {
                return (
                    <div className="group-activity table-col" key={index}>
                        {dmChannel}
                    </div>
                );
            });
        };

        const renderTotalQT = (record) => {
            if (!record.totalPoints && !record.lastCompletedAt) {
                return <div className="group-activity table-col total-qt-container" style={{ color: 'green' }}>0 QT</div>
            }
            let monthDiff = 0;
            if (record.lastCompletedAt) {
                monthDiff = findDiffBetweenMonths(record.lastCompletedAt);
            }
            return <div className="group-activity table-col total-qt-container">
                <span style={{ color: 'green' }}>{`${monthDiff ? 0 : record.totalPoints} QT`}</span>
                {record.lastCompletedAt ? <>
                    <span>{`Last QT: ${monthDiff ? `${monthDiff} ${monthDiff === 1 ? `month` : `months`} ago` : moment(record.lastCompletedAt).startOf('day').from(moment().startOf('day'))}`}</span>
                    <span>{`${moment(record.lastCompletedAt).format("ll")}`}</span>
                </> : <span>{stats.noPastQT}</span>}
            </div>
        };

        const findDiffBetweenMonths = (date) => {
            const curDate = moment().format('DD/MM/YYYY');
            const targetDate = moment(date).format('DD/MM/YYYY');

            const getDate = (date) => moment(date, 'DD/MM/YYYY').startOf('month');
            const diff = Math.abs(getDate(curDate).diff(getDate(targetDate), 'months'));
            return diff;
        }

        // It is used to render form completed date or submitted date
        const renderBARC10Date = (record) => {
            const { formReviewedAt, formCompletedAt, formSendAt } = record;

            let numberOfDays = 0
            let lastCompletedBARC10Color = '#000000'
            let BARC10CalenderIcon = IMG_NOTE_GREY

            // last completed BARC10
            if (formCompletedAt) {
                const completedDate = moment(formCompletedAt);
                const currentDate = moment();
                // Here we are calculating number of days between two dates
                numberOfDays = currentDate.diff(completedDate, 'days');

                lastCompletedBARC10Color = numberOfDays > 30 ? TABLE_FONT_RED_COLOR : numberOfDays > 15 ? TABLE_FONT_YELLOW_COLOR : TABLE_FONT_GREEN_COLOR;
                BARC10CalenderIcon = numberOfDays > 30 ? IMG_NOTE_RED : numberOfDays > 15 ? IMG_NOTE_ORANGE : IMG_NOTE_GREEN;
            }

            return {
                children: <div className="barc10-container">
                    {formReviewedAt && <Tooltip placement="left" title={toolTips.barc10Reviewd}>
                        <div className="barc10-row-container">
                            <img src={IMG_EYE_GREY} className="barc10-row-icon" />
                            <span>{getFormattedDate(formReviewedAt)}</span>
                        </div></Tooltip>}
                    {formCompletedAt && <Tooltip placement="left" title={toolTips.lastbarc10Date}>
                        <div className="barc10-row-container">
                            <img src={BARC10CalenderIcon} className="barc10-row-icon" />
                            <span style={{ color: lastCompletedBARC10Color }}>{getFormattedDate(formCompletedAt)} {(`(${numberOfDays} days)`)}</span>
                        </div></Tooltip>}
                    {/* hide sent date  */}
                    {/* {formSendAt && <Tooltip placement="left" title={toolTips.lastbarc10Send}>
                        <div className="barc10-row-container">
                            <img src={IMG_SEND_GREY} className="barc10-row-icon" />
                            <span>{getFormattedDate(formSendAt)}</span>
                        </div></Tooltip>} */}
                </div>
            };
        };

        // It is used to render outreach note form
        const renderOutReachNote = (record) => {
            if (!record || !record.length) {
                return <div className="group-activity table-col total-qt-container">-</div>
            }

            const outreachNote = record[0];

            return {
                children: <div className="barc10-container">
                    <>
                        <Tooltip placement="left" title={toolTips.outreachSubmittedAt}>
                            <div className="barc10-row-container">
                                <img src={IMG_NOTE_GREY} className="barc10-row-icon" />
                                <span>{getFormattedDate(outreachNote.createdAt)}</span>
                            </div>
                        </Tooltip>
                        <p><span style={{ fontWeight: 'bold' }}>Modality: </span>{outreachNote.option}</p>
                    </>
                </div>
            };
        };

        const sortCatogoryByString = (arr) => {
            let records = [...arr]
            records.sort((a, b) => {
                let fa = a.category.toLowerCase(),
                    fb = b.category.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            return records
        }

        const renderWellnessPlan = (record) => {
            const { wellnessPlan, wellnessFormCompletedAt } = record;
            const { store: { StatsStore: { usersData } } } = this.props;

            if (wellnessPlan && wellnessPlan.length) {
                let wellnessPlanArr = sortCatogoryByString(wellnessPlan)
                /**
                 * Check for status:
                 * 1. Default blank - No answer at all
                 * 2. In-progress - Partially answered
                 * 3. Completed - All answer given
                 */

                // Default blank status
                let status = ""

                // Check for in-progress status
                let inProgress = wellnessPlanArr.filter(wp => {
                    if (wp.questioncount != 0 && (wp.questioncount > 0 && wp.answercount != wp.questioncount)) return wp
                })
                // Set status in-progress
                status = inProgress.length ? "in-progress" : ""

                // skip status check if it is already set
                if (!status) {
                    // Check for completed status
                    let completed = wellnessPlanArr.filter(wp => {
                        if (wp.questioncount != 0 && (wp.questioncount > 0 && wp.answercount == wp.questioncount)) return wp
                    })
                    // Set status completed
                    status = completed.length ? "completed" : ""
                }

                return {
                    children: <div>
                        <Tooltip placement="left" title={toolTips.wellnessSubmittedAt}>
                            <div className="barc10-row-container">
                                <img src={IMG_NOTE_GREY} className="barc10-row-icon" />
                                <span>{getFormattedDate(wellnessFormCompletedAt)}</span>
                            </div></Tooltip>
                        {status && status == "in-progress" ? <div className="wellnessplan-container">
                            <img src={IMG_CHART} className="barc10-row-icon" />
                            <span style={{ color: '#F98654' }}>In Progress</span>
                        </div> : <div className="wellnessplan-container">
                            <img src={IMG_TICK_CIRCLE_SOLID} className="barc10-row-icon" />
                            <span style={{ color: '#3FBF61' }}>Completed</span>
                        </div>}

                        {wellnessPlanArr.map((wp, key) => {
                            return (
                                <>
                                    <div className="barc10-container" key={key}>
                                        {wp.category == "goals" && <div className="barc10-row-container">
                                            <span style={{ color: wp.answercount === 0 ? TEXT_RED_COLOR : wp.questioncount === wp.answercount ? TEXT_GREEN_COLOR : TEXT_YELLOW_COLOR }}>{`Goals: ${wp.answercount}/${wp.questioncount}`}</span>
                                        </div>}
                                        {wp.category == "strengths" && <div className="barc10-row-container">
                                            <span style={{ color: wp.answercount === 0 ? TEXT_RED_COLOR : wp.questioncount === wp.answercount ? TEXT_GREEN_COLOR : TEXT_YELLOW_COLOR }}>{`Strength: ${wp.answercount}/${wp.questioncount}`}</span>
                                        </div>}
                                        {wp.category == "stress-crisis-prevention" && <div className="barc10-row-container">
                                            <span style={{ color: wp.answercount === 0 ? TEXT_RED_COLOR : wp.questioncount === wp.answercount ? TEXT_GREEN_COLOR : TEXT_YELLOW_COLOR }}>{`Crisis: ${wp.answercount}/${wp.questioncount}`}</span>
                                        </div>}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                };
            }
        };

        const renderUsername = (text, row) => {
            const { color } = row;
            return (
                <>
                    <div className="link-text table-col">
                        {text}
                    </div>
                </>
            );
        };
        const renderRewardB10 = (record) => {
            const { rewardB10Details, lastB10ReviewedDate } = record;
            if (rewardB10Details && rewardB10Details.length)
                return rewardB10Details.map((reward, index) => {
                    const flagColor = reward.isSubmitted && reward.isReviewed ? TABLE_FONT_GREEN_COLOR : TABLE_FONT_RED_COLOR;
                    const dueDateDifference = new Date(reward.dueDate).getTime() < new Date().getTime()
                    const isPastDue = dueDateDifference ? (reward.isSubmitted ? TABLE_FONT_GREEN_COLOR : TABLE_FONT_RED_COLOR) : TABLE_FONT_BLACK_COLOR;
                    const isWaitingForMember = reward.isSubmitted === null && !reward.isReviewed;
                    const isWaitingForReview = reward.isSubmitted && !reward.isReviewed && dueDateDifference;
                    return (
                        <div className="group-activity table-col" key={index}>
                            {<p style={{ color: isPastDue }}> Due:{moment(reward.dueDate).format('MM/DD/YYYY')} </p>}
                            {lastB10ReviewedDate && <p style={{ color: TABLE_FONT_GREEN_COLOR }}> Last Reviewed:{moment(lastB10ReviewedDate).format('MM/DD/YYYY')} </p>}
                            {isWaitingForMember &&
                                <div>
                                    {dueDateDifference && <p style={{ color: flagColor }}>({WAITING_FOR_MEMBER})</p>}
                                </div>}
                            {isWaitingForReview &&
                                <div>
                                    <p style={{ color: flagColor }}>({WAITING_FOR_REVIEW})</p>
                                </div>}
                            {reward.isReviewed &&
                                <div>
                                    <p>Reviewed: {moment(reward.reviewedDate).format('MM/DD/YYYY')}</p>
                                    <p>Reward Sent: {moment(reward.rewardSentDate).format('MM/DD/YYYY')}</p>
                                    <p style={{ color: flagColor }}>({WAITING_FOR_REWARD})</p>
                                </div>}
                        </div>
                    );
                });
        };
        const renderRewardWP = (record) => {
            const { wellnessPlan, rewardWellnessDetails, dueWPDate } = record;
            if (wellnessPlan && wellnessPlan.length && rewardWellnessDetails && rewardWellnessDetails.length && dueWPDate) {
                const wellnessPlanArr = sortCatogoryByString(wellnessPlan);
                let status = "";
                const inProgress = wellnessPlanArr.filter(wp => {
                    if (wp.questioncount !== 0 && (wp.questioncount > 0 && wp.answercount !== wp.questioncount)) return wp;
                });
                status = inProgress.length ? "in-progress" : "";

                if (!status) {
                    let completed = wellnessPlanArr.filter(wp => {
                        if (wp.questioncount !== 0 && (wp.questioncount > 0 && wp.answercount === wp.questioncount)) return wp;
                    });
                    status = completed.length ? "completed" : "";
                }

                const categories = [...new Set(rewardWellnessDetails.map(detail => detail.category))];
                const flagColor = rewardWellnessDetails.length !== 13 || !rewardWellnessDetails[0].isReviewed ? TABLE_FONT_RED_COLOR : TABLE_FONT_GREEN_COLOR;
                const dueDateDifference = new Date(dueWPDate).getTime() < new Date().getTime();
                const isPastDue = dueDateDifference ? (rewardWellnessDetails[0].isSubmitted ? TABLE_FONT_GREEN_COLOR : TABLE_FONT_RED_COLOR) : TABLE_FONT_BLACK_COLOR;
                const isUpdatedColor = rewardWellnessDetails[0].isSubmitted ? TABLE_FONT_GREEN_COLOR : TABLE_FONT_BLACK_COLOR;
                const isReviewed = rewardWellnessDetails[0]?.isReviewed;
                const isSubmitted = rewardWellnessDetails[0]?.isSubmitted;
                const questionsCount = rewardWellnessDetails.length;
                const isWaitingForMember = !isSubmitted && !isReviewed && dueDateDifference;
                const isWaitingForReview = isSubmitted && !isReviewed && questionsCount === 13 && dueDateDifference;
                return (
                    <div className="group-activity table-col">

                        <div>
                            <p style={{ color: isPastDue }}>Due:{moment(dueWPDate).format('MM/DD/YYYY')} </p>
                            {questionsCount && <p style={{ color: isUpdatedColor }}>Updated: {moment(rewardWellnessDetails[0]?.updatedAt).format('MM/DD/YYYY')}</p>}
                            {checkIsActive(rewardWellnessDetails[0],offset) && (status && status == "in-progress" ? <div className="wellnessplan-container">
                                <img src={IMG_CHART} className="barc10-row-icon" />
                                <span style={{ color: '#F98654' }}>In Progress</span>
                            </div> : <div className="wellnessplan-container">
                                <img src={IMG_TICK_CIRCLE_SOLID} className="barc10-row-icon" />
                                <span style={{ color: '#3FBF61' }}>Completed</span>
                            </div>)}
                            {checkIsActive(rewardWellnessDetails[0],offset) && wellnessPlanArr.map((wp, key) => {
                                return (
                                    <>
                                        <div className="barc10-container" key={key}>
                                            {wp.category === "goals" && <div className="barc10-row-container">
                                                <span style={{ color: wp.answercount === 0 ? TEXT_RED_COLOR : wp.questioncount === wp.answercount ? TEXT_GREEN_COLOR : TEXT_YELLOW_COLOR }}>{`Goals: ${wp.answercount}/${wp.questioncount}`}</span>
                                            </div>}
                                            {wp.category === "strengths" && <div className="barc10-row-container">
                                                <span style={{ color: wp.answercount === 0 ? TEXT_RED_COLOR : wp.questioncount === wp.answercount ? TEXT_GREEN_COLOR : TEXT_YELLOW_COLOR }}>{`Strength: ${wp.answercount}/${wp.questioncount}`}</span>
                                            </div>}
                                            {wp.category === "stress-crisis-prevention" && <div className="barc10-row-container">
                                                <span style={{ color: wp.answercount === 0 ? TEXT_RED_COLOR : wp.questioncount === wp.answercount ? TEXT_GREEN_COLOR : TEXT_YELLOW_COLOR }}>{`Crisis: ${wp.answercount}/${wp.questioncount}`}</span>
                                            </div>}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                        {record.lastWPReviewedDate && <p style={{ color: TABLE_FONT_GREEN_COLOR }}>Last Reviewed: {moment(record.lastWPReviewedDate).format('MM/DD/YYYY')}</p>}
                        {isWaitingForMember && <div><p style={{ color: TABLE_FONT_RED_COLOR }}>({WAITING_FOR_MEMBER})</p></div>}
                        {isWaitingForReview && <div><p style={{ color: flagColor }}>({WAITING_FOR_REVIEW})</p></div>}
                        {isReviewed && questionsCount === 13 && <div><p style={{ color: flagColor }}>Completed: {moment(rewardWellnessDetails[0].completedDate).format('MM/DD/YYYY')} </p></div>}
                    </div>
                );
            } else if (dueWPDate && !rewardWellnessDetails && !wellnessPlan) {
                const isPast = new Date(dueWPDate).getTime() < new Date().getTime();
                return <div>
                    <p style={{ color: isPast }}>Due:{moment(dueWPDate).format('MM/DD/YYYY')} </p>
                    {isPast && <p style={{ color: TABLE_FONT_RED_COLOR }}>({WAITING_FOR_MEMBER})</p>}
                </div>
            }
        };
        const renderOnboarding = (rewardSteps) => {
            return rewardSteps.map((rewardStep, index) => {

                const valuesArray = Object.values(rewardStep).slice(4, 9);
                const countTrue = valuesArray.filter(value => value === true).length;
                const diffTime = Math.abs(new Date() - new Date(rewardStep.updatedAt));
                const differenceDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                const updatedDate = moment(rewardStep.updatedAt).format('MM/DD/YYYY');
                const differenceDaysColor = countTrue === 5 ? TABLE_FONT_GREEN_COLOR : differenceDays > 3 ? TABLE_FONT_RED_COLOR : TABLE_FONT_BLACK_COLOR;
                return (
                    <div className="group-activity table-col" key={index}>
                        <p>{countTrue}/5 steps completed</p>
                        <span style={{ color: differenceDaysColor }}>Last Updated: {updatedDate} ({differenceDays} Days) </span>
                    </div>
                );
            });
        };

        const renderUserBadges = (badgeType) => {
            return (
                <>
                    {badgeType && <UserBadges badgeType={badgeType} />}
                </>
            );
        };

        const renderIndatorData = (row) => {
            const { indatorFlag, monthFlag, thisMonthMessages, lastMonthMessages } = row;
            // Here if user has not messaged in this month or last month then we need to highlight in RED color 
            // Also need to add No in indator field, by default we are taking "No" value
            let text = 'No';
            let textForLastMonth = '';
            let backgroundColor = TABLE_ROW_RED_COLOR;
            // Here if user has messaged in this month then we need to highlight in GREEN color 
            // Also need to add current month/ this month range with total messages count
            if (indatorFlag === 'GREEN') {
                backgroundColor = TABLE_ROW_GREEN_COLOR;
                if (monthFlag === CASELOAD_USER_FLAGS.BOTH_MONTH) {
                    text = `${currentMonthRange.startDateOfMonth} to ${currentMonthRange.endDateOfMonth} : ${thisMonthMessages} Msgs`;
                    textForLastMonth += `${lastMonthRange.startDateOfMonth} to ${lastMonthRange.endDateOfMonth} : ${lastMonthMessages} Msgs`;
                } else if (monthFlag === CASELOAD_USER_FLAGS.THIS_MONTH) {
                    text = `${currentMonthRange.startDateOfMonth} to ${currentMonthRange.endDateOfMonth} : ${thisMonthMessages} Msgs`;
                }

            } else if (indatorFlag === 'YELLOW' && monthFlag === CASELOAD_USER_FLAGS.LAST_MONTH) {
                // Here if user has messaged in last month then we need to highlight in YELLOW color 
                // Also need to add last month range with total messages count
                text = `${lastMonthRange.startDateOfMonth} to ${lastMonthRange.endDateOfMonth} : ${lastMonthMessages} Msgs`;
                backgroundColor = TABLE_ROW_YELLOW_COLOR;
            }
            return {
                props: {
                    style: { background: backgroundColor, }
                },
                children:
                    <>
                        <div>{text}</div>
                        {textForLastMonth ? <div><br /> {textForLastMonth}</div> : null}
                    </>
            };
        };

        const isColumnVisible = (dataIndex) => {
            const column = previewControl.find(item => item.dataIndex === dataIndex);
            return column?.isVisible;
        };

        const renderColumns = (columns) => {
            const { showPeerColumn } = this.state;
            return columns.filter(item => {
                if (item.isVisible) {
                    if (item.dataIndex === "peerFullName") {
                        if (showPeerColumn) {
                            return item;
                        }
                    } else {
                        return item;
                    }
                }
            });
        }

        const changeVisibleState = (dataIndex, value) => {
            const {
                store: {
                    StatsStore: { previewControl, savePreviewControlToLS, setPreviewControl }
                }
            } = this.props;

            let previewControlClone = [...previewControl];
            const index = previewControlClone.findIndex(item => item.dataIndex === dataIndex);

            if (index !== -1) {
                previewControlClone[index] = { ...previewControlClone[index], isVisible: value };
            }

            setPreviewControl(previewControlClone);
            savePreviewControlToLS(previewControlClone);
        }

        const columns = [
            {
                title: <div className="group-head">{caseloads.indator}</div>,
                width: '8%',
                sorter: true,
                render: renderIndatorData,
                dataIndex: "indator",
                sortOrder: getSortOrder("indator"),
                isVisible: isColumnVisible("indator")
            },
            {
                title: <div className="group-head">{caseloads.fullName}</div>,
                width: '12%',
                render: (text) => <div className="table-col">{text}</div>,
                dataIndex: "fullName",
                sorter: false,
                isVisible: isColumnVisible("fullName")
            },
            {
                title: <div className="group-head">{caseloads.username}</div>,
                dataIndex: "username",
                width: '10%',
                render: renderUsername,
                sorter: true,
                sortOrder: getSortOrder("username"),
                isVisible: isColumnVisible("username")
            },
            {
                title: <div className="group-head">{caseloads.peer}</div>,
                render: (text) => <div className="table-col">{text}</div>,
                width: '8%',
                dataIndex: "peerFullName",
                sorter: false,
                className: !this.state.showPeerColumn && 'hide-peers-column',
                isVisible: isColumnVisible("peerFullName")
            },
            {
                title: <div className="group-head">{caseloads.badge}</div>,
                dataIndex: "badgeType",
                render: renderUserBadges,
                sorter: true,
                sortOrder: getSortOrder("badgeType"),
                isVisible: isColumnVisible("badgeType")
            },
            {
                title: <div className="group-head">{caseloads.joinedDate} </div>,
                dataIndex: "createdAt",
                width: '10%',
                render: renderJoinDate,
                sorter: true,
                sortOrder: getSortOrder("createdAt"),
                isVisible: isColumnVisible("createdAt")
            },
            {
                title: <div className="group-head">{caseloads.groups} </div>,
                dataIndex: "groups",
                width: '15%',
                render: (groups) => {
                    if (groups && groups.length) {
                        const isGroupLengthIsOneFound = groups.length === 1;
                        return renderGroupNames(groups, isGroupLengthIsOneFound);
                    }
                    //  If there is no groups present then we need to mark the empty cell in RED background color
                    return {
                        props: {
                            style: { background: TABLE_ROW_RED_COLOR }
                        },
                    };
                },
                isVisible: isColumnVisible("groups")
            },
            {
                title: <div className="group-head">{caseloads.dmChannels} </div>,
                dataIndex: "dmChannels",
                width: '10%',
                render: (dmChannels) => {
                    if (dmChannels && dmChannels.length) {
                        // Check if DM Channel exists & selected user in dropdown is exists in that DM Channels array or not
                        if (dmChannels.indexOf(selectedUsernameForCaseLoad) > -1) {
                            return renderDMChannels(dmChannels);
                        }
                        // If DM Channel exists and login user name is not exists in dm channels array 
                        // then we need to turn the background color to RED
                        return {
                            props: {
                                style: { background: TABLE_ROW_RED_COLOR }
                            },
                            children: renderDMChannels(dmChannels)
                        };
                    }
                    // If there is no DM Channel present then we need to mark the empty cell in RED background color
                    return {
                        props: {
                            style: { background: TABLE_ROW_RED_COLOR }
                        },
                    };
                },
                isVisible: isColumnVisible("dmChannels")
            },
            {
                title: <div className="group-head">{caseloads.rewardDetails}</div>,
                dataIndex: "rewardDetails",
                width: '15%',
                render: (rewardDetails) => {
                    if (rewardDetails && rewardDetails.length) {
                        return renderOnboarding(rewardDetails);
                    }
                    return '-';
                },
                sorter: false,
                //it's static, so used "username" as of now
                isVisible: isColumnVisible("rewardDetails")
            },
            {
                title: <div className="group-head">{caseloads.rewardB10Details}</div>,
                dataIndex: "",
                width: '10%',
                render: renderRewardB10,
                sorter: false,
                isVisible: isColumnVisible("rewardB10Details")
            },
            {
                title: <div className="group-head">{caseloads.rewardWellnessDetails}</div>,
                dataIndex: "",
                width: '25%',
                render: renderRewardWP,
                sorter: false,
                isVisible: isColumnVisible("rewardWellnessDetails")
            },
            // {
            //     title: <div className="group-head">{caseloads.wellnessPlan} </div>,
            //     width: '10%',
            //     sorter: true,
            //     render: renderWellnessPlan,
            //     dataIndex: "",
            //     sortOrder: getSortOrder("wellnessPlan"),
            //     isVisible: isColumnVisible("wellnessPlan")
            // },
            // {
            //     title: <div className="group-head">{caseloads.barc10Date} </div>,
            //     width: '10%',
            //     render: renderBARC10Date,
            //     sorter: true,
            //     dataIndex: "barc10Date",
            //     sortOrder: getSortOrder("barc10Date"),
            //     isVisible: isColumnVisible("barc10Date")
            // },
            {
                title: <div className="group-head">{caseloads.outreachNote} </div>,
                width: '10%',
                render: renderOutReachNote,
                dataIndex: "outreachNote",
                sorter: true,
                sortOrder: getSortOrder("outreachNote"),
                isVisible: isColumnVisible("outreachNote")
            },
            {
                title: <div className="group-head">{`${caseloads.statusQT}`} </div>,
                width: '5%',
                render: renderTotalQT,
                sorter: true,
                dataIndex: "qtPoints",
                sortOrder: getSortOrder("qtPoints"),
                isVisible: isColumnVisible("qtPoints")
            },
        ];
        
        // removing name
        if (type == DEV) { columns.splice(1, 1); }
        
        const customArray = customArrayCaseload;
        let combinedArray = [];
        if (adminUsersForCaseLoad && adminUsersForCaseLoad.length) {
            combinedArray = [
                ...adminUsersForCaseLoad.map(({ id, fullName }) => ({ id: `assign_${id}`, fullName })),
                ...customArray.map(({ id, fullName }) => ({ id: `option_${id}`, fullName })),
                { id: "enrolled_peers", fullName: "Enrollment Peer" },
                ...adminUsersForCaseLoad.map(({ id, fullName }) => ({ id: `enroll_${id}`, fullName: `ENROLLMENT ${fullName}` }))
            ];
        }
        const { peerName, enrollPeerId, assignPeerId, filteredAdminUsers } = this.state;
        const displayAdminUsers = filteredAdminUsers.length > 0 ? filteredAdminUsers : combinedArray;
        return (
            <React.Fragment>
                <div className="caseload-container">
                    <Row style={{ width: '100%' }}>
                        <Col span={12} style={{ width: '50%', display: 'flex' }}>
                            {displayAdminUsers && displayAdminUsers.length ? (
                                <div>
                                    <label>Caseloads</label>
                                    <Select
                                        value={peerName || selectedUserIdForCaseLoad}
                                        style={{ width: 295, marginLeft: 10 }}
                                        onChange={value => this.handleCaseloadsChange(value)}
                                        onSearch={value => this.handleSearch(value)}
                                        showSearch

                                        filterOption={(input, option) =>
                                            option.children?.toLowerCase().indexOf(input.toLowerCase()) !== -1 ||
                                            option.value === "enrolled_peers"
                                        }>
                                        {displayAdminUsers.map(({ id, fullName }, index) => {
                                            const optionType = id.split('_')[0];
                                            const optionId = id.split('_')[1];
                                            let isDisabled = false;
                                            if (optionType === 'assign') {
                                                isDisabled = optionId && Number(optionId) === Number(assignPeerId);
                                            } else if (optionType === 'enroll') {
                                                isDisabled = optionId && Number(optionId) === Number(enrollPeerId);
                                            } else {
                                                isDisabled = optionId && Number(optionId) === Number(selectedUserIdForCaseLoad);
                                            }
                                            return (
                                                <Option key={`option_${id}_${index}`} value={id} disabled={isDisabled}
                                                    style={id === "enrolled_peers" ? { color: 'orange' } : null}>
                                                    {fullName}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                            ) : null}

                            {/* Render all the territories in the territory select dropdown */}
                            {selectedUserIdForCaseLoad && allTerritories && allTerritories.length ? (
                                <div style={{ marginLeft: 20 }}>
                                    <label>Territory</label>
                                    <Select
                                        value={selectedTerritoryForCaseLoad}
                                        style={{ width: 180, marginLeft: 10 }}
                                        onChange={this.handleTerritoryChange}
                                    >
                                        <Option value={TERRITORIES_TYPE_ALL_TERRITORIES} disabled={TERRITORIES_TYPE_ALL_TERRITORIES === selectedTerritoryForCaseLoad}>All Territories</Option>
                                        {allTerritories.map((territory, index) => (
                                            <Option key={index} value={territory} disabled={territory === selectedTerritoryForCaseLoad}>{territory}</Option>
                                        ))}
                                    </Select>
                                </div>
                            ) : null}

                            {/* Badge filter */}
                            {selectedUserIdForCaseLoad && allTerritories && allTerritories.length ? (
                                <div style={{ marginLeft: 20 }}>
                                    <label>Badge filter</label>
                                    <Select
                                        value={selectedBadgeForCaseLoad}
                                        style={{ width: 180, marginLeft: 10 }}
                                        onChange={this.handleBadgeTypeChange}
                                    >

                                        {BADGE_TYPE_FLAGS_ARR.map((territory, index) => (
                                            <Option key={index} value={territory.code} disabled={territory === selectedBadgeForCaseLoad}>{territory.title}</Option>
                                        ))}
                                    </Select>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </div>

                {selectedUserIdForCaseLoad && <div className="caseload-container">
                    <Form
                        layout="inline"
                        className="components-table-demo-control-bar"
                        style={{ marginBottom: 16 }}
                    >
                        {previewControl.map((item, key) => {
                            if (item.dataIndex === "peerFullName") {
                                if (!showPeerColumn) {
                                    return;
                                }
                            }
                            return <span key={key}>
                                <FormSwitch
                                    item={item}
                                    showPeerColumn={showPeerColumn}
                                    changeVisibleState={(dataIndex, value) => changeVisibleState(dataIndex, value)}
                                />
                            </span>
                        })}
                    </Form>
                </div>}
                <Table
                    rowKey="id"
                    columns={renderColumns(columns)}
                    dataSource={toJS(usersData)}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                    onRow={rowEvents}
                />

            </React.Fragment>
        );
    }
}

export default StatsCaseLoads;