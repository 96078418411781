import React from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroller";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import { Dropdown, Menu, Spin } from "antd";

import MessageBubble from "./MessageBubble";
import ExpandableTextArea from "../UI/ExpandableTextArea";
import { waiting } from "../../en.json";
import close from "../../static/images/close.png";
import sendIcon from "../../static/images/send-icon.png";
import { ALIKE_MODERATOR } from "../../constants/UserRolesConstant";
import { IMG_FORM } from "../../utils/ImageUtils";
import {
  publish,
  getPubnubInstanceByUserType,
  history,
} from "../../utils/PubnubMethods";

@inject("store")
@observer
class WaitingSection extends React.Component {
  constructor(props) {
    super(props);
    this.messageList = React.createRef();
    this.expandableTextAreaRef = React.createRef();
    this.state = {
      value: "",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 500);
  }

  scrollToBottom = () => {
    if (this.messageList.current) {
      const scrollHeight = this.messageList.current.scrollHeight;
      const height = this.messageList.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  appendData = () => {
    const {
      store: {
        MessagesStore,
        AuthStore: { type },
      },
    } = this.props;

    const message = MessagesStore.getMessage();
    const text = message.text;
    if (text.trim() !== "") {
      const pubnub = getPubnubInstanceByUserType(type);
      message.clearText();
      text.trim() !== "" && publish(pubnub, text);
      setTimeout(() => {
        this.scrollToBottom();
      }, 200);

      if (this.expandableTextAreaRef && this.expandableTextAreaRef.current) {
        this.expandableTextAreaRef.current.contractTextArea();
      }
    }
  };

  checkMessageType = (message) => {
    return (
      message.entry.type === "text" ||
      message.entry.type === "img" ||
      message.entry.type === "gif"
    );
  };

  handleChange = (e) => {
    const {
      store: { MessagesStore },
    } = this.props;

    const text = e.target.value;
    const message = MessagesStore.getMessage();

    this.setState({
      value: e.target.value,
    });

    message.setText(text);
  };

  handleClose = () => {
    const {
      store: {
        MessagesStore: { setIsWaiting, resetSelectedGroup },
        ProfileStore: { setSelectedWaiting },
      },
    } = this.props;
    setIsWaiting(false);
    setSelectedWaiting(null);
    resetSelectedGroup();
  };

  handleKeyPress = (e, broadcastMessage = false) => {
    const {
      store: {
        BroadcastStore: { startDm },
      },
    } = this.props;

    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      if (broadcastMessage) {
        startDm();
      } else {
        this.appendData();
      }
    }
  };

  loadMore = async () => {
    const {
      store: {
        MessagesStore: {
          selectedGroup: { setLoading, loading, reactionsLoaded },
          viewportMessagesLoaded,
        },
      },
    } = this.props;
    if (!loading && !reactionsLoaded && viewportMessagesLoaded) {
      const prevScrollHeight = this.messageList.props.current.scrollHeight;
      setLoading(true);
      history(this.scrollToTop, prevScrollHeight);
    }
  };

  // This function is used to send form by using form id as key
  sendForm = async ({ key }) => {
    const {
      store: {
        FormMessageStore: { sendFormInGroup },
      },
    } = this.props;
    sendFormInGroup(Number(key));
  };

  // This function is used to render all form names available in forms list data
  renderFormsList = () => {
    const {
      store: {
        FormMessageStore: { formList },
      },
    } = this.props;

    return (
      <div className="form-dropdown">
        <Menu onClick={this.sendForm} className="menu">
          {formList.map((form) => {
            return (
              <Menu.Item key={form.id}>
                <div>{form.name}</div>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );
  };

  render() {
    const {
      store: {
        MessagesStore: {
          selectedGroup: {
            id,
            messages,
            loading,
            hasMore,
            name,
            channel,
            groupLastSeen,
          },
          text,
        },
        AuthStore: { type },
      },
    } = this.props;

    const userType = type;
    let prevDate = "";
    return (
      <div className="right-section">
        <div className="header-section p-1">
          <h1 className="dm-heading">
            {name} Queue Messages
            <img src={close} onClick={() => this.handleClose()} alt="Close" />
          </h1>
        </div>
        <div className="d-flex">
          <div className="p-1" style={{ flex: "1", position: "relative" }}>
            <div className="d-flex flex-column h-100">
              <div className="input-container" style={{ left: "28%" }}>
                <ExpandableTextArea
                  defaultTextAreaRows={2}
                  expandIconClassName={"expand-textarea-img waiting-section"}
                  ref={this.expandableTextAreaRef}
                  value={text}
                  onChange={this.handleChange}
                  onKeyDown={this.handleKeyPress}
                  placeholder="Type here to send a message..."
                />
                {/* Here we only need to show the form icon to SA/NOA/moderator user type */}
                {ALIKE_MODERATOR.includes(userType) ? (
                  <Dropdown
                    overlay={this.renderFormsList()}
                    trigger={["click"]}
                    placement="topRight"
                  >
                    <img src={IMG_FORM} className="send-form active" alt="Form" style={{ right: 75 }} />
                  </Dropdown>
                ) : null}
                <img
                  src={sendIcon}
                  onClick={this.appendData}
                  value="Append"
                  className="send-text-img ml-auto"
                  style={text.trim() !== "" ? { opacity: 1 } : { opacity: 0.4 }}
                  alt="Send"
                />
              </div>

              {loading && <Spin />}
              <div id="display-data-Container" ref={this.messageList}>
                <InfiniteScroll
                  loadMore={this.loadMore}
                  hasMore={hasMore}
                  isReverse
                  useWindow={false}
                >
                  {_.uniqBy(messages, "timetoken").map((message, index) => {
                    const currDate = moment
                      .unix(parseInt(message.timetoken) / 10000000)
                      .format("LL");
                    const messageBox = (
                      <React.Fragment key={index}>
                        {currDate !== prevDate && (
                          <div className="message-date">
                            <span>{currDate}</span>
                          </div>
                        )}

                        <MessageBubble
                          message={message}
                          isWaiting={true}
                          groupChat={_.startsWith(channel, "GROUP_CHAT")}
                          groupId={
                            _.startsWith(channel, "GROUP_CHAT") ? id : null
                          }
                          groupLastSeen={groupLastSeen}
                          scrollToBottom={() => this.scrollToBottom()}
                        />
                      </React.Fragment>
                    );

                    prevDate = currDate;
                    return messageBox;
                  })}
                  {!messages.length ? (
                    <div className="no-messages">{waiting.noMessages}</div>
                  ) : null}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WaitingSection;
