import React from 'react';
import { Table, Spin, Select, Checkbox } from 'antd';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

// Components
import UserBadges from '../UI/UserBadges';

import { stats } from '../../en.json';
import { TERRITORIES_TYPE_ALL_TERRITORIES } from '../../constants/GlobalConstant';
import { getFormattedDate } from '../../utils/CommonUtils';
import { IS_HP } from '../../utils/getEnvironment';
import { DEV } from '../../constants/UserRolesConstant';

const { Option } = Select;

@inject("store")
@observer
class StatsEnrolled extends React.Component {
    componentWillUnmount() {
        const {
            store: { StatsStore }
        } = this.props;
        StatsStore.reset();
    };

    async fetchUsersList() {
        try {
            const {
                store: { StatsStore }
            } = this.props;
            StatsStore.setLoading(true);
            await StatsStore.getUsersList();
            StatsStore.setLoading(false);
        } catch (error) {
        }
    };

    handleTableChange = (pagination, filters, sorter) => {
        const {
            store: {
                StatsStore,
            },
        } = this.props;
        StatsStore.setPaginationData(pagination);
        this.fetchUsersList();
    };

    handleFullNamePress = async (sender) => {
        try {
            const {
                store: {
                    ProfileStore: {
                        setMemberProfileVisibility,
                        setMemberData,
                        setAssignedPeerDetails,
                        toggleLabel,
                        reset,
                        userId,
                        getHighPointUsers,
                        changeSelectedAction,
                        getProviderModeratedGroups,
                        fetchGroupsFromForms,
                        getContactModeratedGroups,
                    },
                    DeletedMessagesStore: { setShowDeleted },
                    MemberListStore: { getMemberDetails, fetchAdminUsers },
                    MessagesStore: { setIsSmsSectionVisible },
                },
            } = this.props;
            if (sender === userId) {
                return;
            }
            reset();
            setIsSmsSectionVisible(false);
            setShowDeleted(false);
            setMemberProfileVisibility(true);
            setAssignedPeerDetails({});
            fetchAdminUsers();
            const member = await getMemberDetails(sender);
            if (member) {
                const { providerId, recommendedBy } = member;
                setMemberData(member);
                if (IS_HP) {
                    getHighPointUsers();
                    if (providerId) {
                        getProviderModeratedGroups(providerId);
                    }
                    if (recommendedBy) {
                        getContactModeratedGroups(recommendedBy);
                    }
                    fetchGroupsFromForms();
                }
            }
            toggleLabel(false);
            changeSelectedAction("");
        } catch (error) {
        }
    };

    handleTerritoryChange = (value) => {
        const {
            store: {
                StatsStore: { setSelectedTerritoryForStatsUsers, getUsersList, },
            }
        } = this.props;
        setSelectedTerritoryForStatsUsers(value);
        getUsersList();
    };

    render() {
        const {
            store: {
                StatsStore: {
                    loading,
                    pagination,
                    usersData,
                    allTerritories,
                    selectedTerritoryForStatsUsers
                },
                AuthStore: { type },
            },
        } = this.props;

        const renderConsentInApp = (row) => {
            const { consentDoneAt } = row;
            return <>
                {consentDoneAt ? <Checkbox checked ></Checkbox> : null}
            </>
        };

        const rowEvents = (record, rowIndex) => {
            return {
                onClick: () => {
                    this.handleFullNamePress(record.id);
                },
            };
        };

        const renderJoinDate = (row) => {
            const { createdAt } = row;
            return <>
                {createdAt ? getFormattedDate(createdAt) : null}
            </>
        };

        const renderDmChannels = (dmChannels) => {
            return dmChannels.map((dmChannel, index) => {
                return (
                    <div className="group-activity table-col" key={index}>
                        {dmChannel}
                    </div>
                );
            });
        };

        const renderUserBadges = (badgeType) => {
            return (
                <>
                    {badgeType && <UserBadges badgeType={badgeType} />}
                </>
            );
        };

        const columns = [
            {
                title: <div className="group-head">{stats.joinDate}</div>,
                render: renderJoinDate,
                width: '10%'
            },
            {
                title: <div className="group-head">{stats.name}</div>,
                render: (text) => <div className="link-text table-col">{text}</div>,
                dataIndex: 'fullName',
                sorter: false,
            },
            {
                title: <div className="group-head">{stats.consentInApp}</div>,
                sorter: false,
                render: renderConsentInApp
            },
            {
                title: <div className="group-head">{stats.username} </div>,
                dataIndex: "username",
            },
            {
                title: <div className="group-head">{stats.badge} </div>,
                dataIndex: "badgeType",
                render: renderUserBadges
            },
            {
                title: <div className="group-head">{stats.dmChannels} </div>,
                dataIndex: "dmChannels",
                render: (dmChannels) => {
                    return <>
                        {dmChannels && dmChannels.length ? renderDmChannels(dmChannels) : null}
                    </>
                },
            },
            {
                title: <div className="group-head">{stats.territory} </div>,
                dataIndex: "territory",
            },
            {
                title: <div className="group-head">{stats.site} </div>,
                dataIndex: "siteName",
            },
            {
                title: <div className="group-head">{stats.notes} </div>,
                dataIndex: "notes",
            },
            {
                title: <div className="group-head">{stats.assignedPeer} </div>,
                dataIndex: "assignedPeerFullName",
            },
        ];
        // removing name
        if (type == DEV) { columns.splice(1, 1); }

        return (
            <React.Fragment>

                <div style={{ marginLeft: 20, marginBottom: 10 }}>
                    <label>{stats.territorySelect}</label>
                    <Select
                        value={selectedTerritoryForStatsUsers}
                        style={{ width: 180, marginLeft: 10 }}
                        onChange={this.handleTerritoryChange}
                    >
                        <Option value={TERRITORIES_TYPE_ALL_TERRITORIES} disabled={TERRITORIES_TYPE_ALL_TERRITORIES === selectedTerritoryForStatsUsers}>
                            All Territories
                        </Option>
                        {allTerritories.map((territory, index) => (
                            <Option key={index} value={territory} disabled={territory === selectedTerritoryForStatsUsers}>
                                {territory}
                            </Option>
                        ))}
                    </Select>
                </div>

                <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={usersData}
                    pagination={pagination}
                    loading={loading}
                    onChange={this.handleTableChange}
                    onRow={rowEvents}
                />
            </React.Fragment>
        );
    }
}

export default StatsEnrolled;