import { inject, observer } from "mobx-react";

import React from "react";
import { groupMembers, userProfile, groups } from "../../en.json";
import { Select, Spin, Button, Menu, Dropdown, Icon } from "antd";
import info from "../../static/images/info.png";
import some from "lodash/some";
import moment from "moment";
import { getBannerEmoji } from "../../utils/getEmoji";
import { CustomModal } from "../UI/CustomModal";
import { IS_HP } from "../../utils/getEnvironment";
import { CustomButton } from "../UI/CustomButton";
import {
  publishCustomBanner,
  getPubnubInstanceByUserType,
} from "../../utils/PubnubMethods";
import { toJS } from "mobx";
import { DEV } from "../../constants/UserRolesConstant";

const mixpanel = require('mixpanel-browser');

const Option = Select.Option;

@inject("store")
@observer
class SessionMembers extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
    };
  }

  changeModalVisible = (value) => {
    this.setState({
      visible: value,
    });
  };

  fetchGroupStatusHistory = (groupId, groupName, userId) => {
    const {
      store: {
        HistoryStore: { fetchStatusHistory, resetHistory, historyLoading },
      },
    } = this.props;
    if (!userId) {
      return;
    }
    const channelType = "GROUP";
    if (historyLoading) {
      return;
    } else {
      resetHistory();
      fetchStatusHistory(groupId, groupName, userId, channelType);
      this.changeModalVisible(true);
    }
  };

  getTime = (time) => {
    return moment(time).format("MMMM Do YYYY");
  };

  loadMoreStatusHistory = () => {
    const {
      store: {
        HistoryStore: {
          fetchStatusHistory,
          selectedGroupName,
          selectedUserId,
          selectedGroupId,
          historyLoading,
        },
      },
    } = this.props;
    if (historyLoading) {
      return;
    }
    const channelType = "GROUP";
    fetchStatusHistory(
      selectedGroupId,
      selectedGroupName,
      selectedUserId,
      channelType
    );
  };

  setGroupStatusForOnlineUsers = async (value, userId, groupId) => {
    const {
      store: {
        GroupStore: { changeStatus },
        GroupListStore: {
          removeGroupUser,
          removeOnlineUser,
          changeStatusOfOnlineUser,
          changeStatusInGroup,
        },
      },
    } = this.props;

    const success = await changeStatus(groupId, userId, value);
    if (success) {
      if (value === 4) {
        removeGroupUser(userId, groupId);
        removeOnlineUser(userId, groupId);
      } else {
        changeStatusInGroup(userId, value);
        changeStatusOfOnlineUser(userId, value);
      }
    }
  };

  setGroupStatusForOfflineUsers = async (value, userId, groupId) => {
    const {
      store: {
        GroupStore: { changeStatus },
        GroupListStore: {
          removeGroupUser,
          removeOfflineUser,
          changeStatusOfOfflineUser,
          changeStatusInGroup,
        },
      },
    } = this.props;

    const success = await changeStatus(groupId, userId, value);
    if (success) {
      if (value === 4) {
        removeGroupUser(userId, groupId);
        removeOfflineUser(userId, groupId);
      } else {
        changeStatusInGroup(userId, value);
        changeStatusOfOfflineUser(userId, value);
      }
    }
  };

  handleChange = (e) => {
    const {
      store: {
        MessagesStore: { setBannerMessage },
      },
    } = this.props;

    const text = e.target.value;
    setBannerMessage(text);
  };

  appendData = () => {
    const {
      store: {
        MessagesStore: { bannerMessage, bannerEmojiName, resetBannerMessage },
        AuthStore: { type },
      },
    } = this.props;

    const text = bannerMessage;
    if (text.trim() !== "") {
      const pubnub = getPubnubInstanceByUserType(type);
      resetBannerMessage();
      text.trim() !== "" && publishCustomBanner(pubnub, text, bannerEmojiName);
    }
  };

  handleKeyPress = (e) => {
    if (!e.shiftKey && e.key === "Enter") {
      e.preventDefault();
      this.appendData();
    }
  };

  status = (statusId) => {
    if (statusId === 1) {
      return "Active";
    } else if (statusId === 2) {
      return "Suspended";
    } else if (statusId === 3) {
      return "Paused";
    }
  };

  changeEmoji = ({ key }) => {
    const {
      store: {
        MessagesStore: { setBannerEmojiName },
      },
    } = this.props;
    setBannerEmojiName(key);
  };

  resetEmoji = () => {
    const {
      store: {
        MessagesStore: { resetBannerEmojiName },
      },
    } = this.props;
    resetBannerEmojiName();
  };

  changeUserStatus = (groupId, status) => {
    const {
      store: {
        GroupListStore: { pauseAllUsersInGroup },
        AuthStore: { userId, username },
      },
    } = this.props;
    mixpanel.track("User Status Changed In Group", {
      adminUserId: userId,
      adminUsername: username,
      groupId,
      isPaused: status,
      from: "WEB"
    });
    pauseAllUsersInGroup(groupId, status);
  };

  usernamePress = async (sender) => {
    const {
      store: {
        ProfileStore: {
          setMemberProfileVisibility,
          setMemberData,
          toggleLabel,
          reset,
          getHighPointUsers,
          changeSelectedAction,
          getProviderModeratedGroups,
          fetchGroupsFromForms,
          getContactModeratedGroups,
        },
        AuthStore: { type },
        DeletedMessagesStore: { setShowDeleted },
        MemberListStore: { getMemberDetails },
      },
    } = this.props;
    const isAdmin = type === "moderator" || type === "SA" || type === DEV;
    if (!isAdmin) {
      return;
    }
    reset();
    setShowDeleted(false);
    setMemberProfileVisibility(true);
    const member = await getMemberDetails(sender);
    if (member) {
      setMemberData(member);
      if (IS_HP) {
        getHighPointUsers();
        if (member.providerId) {
          getProviderModeratedGroups(member.providerId);
        }
        if (member.recommendedBy) {
          getContactModeratedGroups(member.recommendedBy);
        }
        fetchGroupsFromForms();
      }
    }
    toggleLabel(false);
    changeSelectedAction("");
  };

  render() {
    const emojiOptions = [
      "speaker",
      "mutedSpeaker",
      "microphone",
      "question",
      "handRaise",
      "box",
      "stopwatch",
      "joystick",
      "bulb",
      "note",
    ];

    const menu = (
      <Menu onClick={this.changeEmoji}>
        {emojiOptions.map((emoji) => (
          <Menu.Item key={emoji}>
            <div className="emoji-box" style={{ fontSize: "20px" }}>
              {getBannerEmoji(emoji)}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
    const {
      store: {
        GroupListStore: {
          selectedGroup,
          allPauseLoading,
          isAllPaused,
          isAllUnPaused,
          onlineUsers,
          offlineUsers,
        },
        HistoryStore: {
          historyLoading,
          skipStatusInGroup,
          totalStatusInGroup,
          userStatusInGroupHistory,
          resetHistory,
        },
        AuthStore: { type },
        MessagesStore: { bannerMessage, bannerEmojiName },
        NotificationStore
      },
    } = this.props;

    const isAdmin = type === "moderator" || type === "SA" || type === DEV;

    return (
      <div className="group-session-container">
        <CustomModal
          title="User Group Status History"
          visible={this.state.visible}
          onOk={this.handleOk}
          loading={historyLoading}
          loadMore={this.loadMoreStatusHistory}
          hasMore={skipStatusInGroup < totalStatusInGroup}
          onCancel={() => {
            resetHistory();
            this.changeModalVisible(false);
          }}
          data={userStatusInGroupHistory ? userStatusInGroupHistory : []}
        />
        <textarea
          className="session-text-area"
          rows={6}
          value={bannerMessage}
          cols={36}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyPress}
          placeholder="Type here to send a message..."
        />

        <div className="send-banner-container">
          <Dropdown overlay={menu} placement="bottomCenter">
            <div className="smile">
              {bannerEmojiName ? (
                getBannerEmoji(bannerEmojiName)
              ) : (
                <Icon type="smile" />
              )}
            </div>
          </Dropdown>
          {bannerEmojiName ? (
            <CustomButton
              size="large"
              type="primary"
              caption="Reset Emoji"
              onClick={this.resetEmoji}
            />
          ) : null}
          <CustomButton
            disabled={!bannerMessage.trim().length}
            size="large"
            type="primary"
            caption="Send Banner"
            onClick={this.appendData}
          />
        </div>
        {allPauseLoading ? (
          <div className="pauseAll-spin">
            <Spin />
          </div>
        ) : null}
        {selectedGroup.users && selectedGroup.users.length ? (
          <div className="session-buttons">
            <CustomButton
              className="session-button"
              caption="Pause All"
              disabled={allPauseLoading || isAllPaused}
              onClick={() =>
                NotificationStore.setConfirm(() => {
                  this.changeUserStatus(selectedGroup.id, true);
                }, groups.pausedAll)
              }
            />
            <CustomButton
              disabled={allPauseLoading || isAllUnPaused}
              className="session-button"
              caption="UnPause All"
              onClick={() =>
                NotificationStore.setConfirm(() => {
                  this.changeUserStatus(selectedGroup.id, false);
                }, groups.unpausedAll)
              }
            />
          </div>
        ) : null}

        <div className="group-members">
          {selectedGroup.users && selectedGroup.users.length ? (
            <>
              <h3 className="member-type-heading">{groupMembers.members}</h3>
              {onlineUsers.map((user) => {
                const groupStatus = this.status(user.status);
                const statusInGroup = user.status;
                return (
                  <div className="user-group-container" key={user.username}>
                    <div className="user-group">
                      <h6
                        className={
                          isAdmin
                            ? "group-users-with-status add-pointer"
                            : "group-users-with-status"
                        }
                        onClick={() => this.usernamePress(user.userId)}
                      >
                        <div className="online"></div>
                        {user.username}
                      </h6>
                      <div
                        id="session-members-container"
                        style={{ position: "relative" }}
                      >
                        <Select
                          value={groupStatus}
                          style={{ width: 120 }}
                          onChange={(value) =>
                            this.setGroupStatusForOnlineUsers(
                              value,
                              user.userId,
                              selectedGroup.id
                            )
                          }
                          getPopupContainer={() =>
                            document.getElementById("session-members-container")
                          }
                        >
                          {statusInGroup === 1 ? null : (
                            <Option title="Active" value={1}>
                              <i className="fa fa-check">
                                {userProfile.activate}
                              </i>
                            </Option>
                          )}
                          {statusInGroup === 2 ? null : (
                            <Option title="Suspended" value={2}>
                              <i className="fa fa-minus-circle">
                                {userProfile.suspend}
                              </i>
                            </Option>
                          )}
                          {statusInGroup === 3 ? null : (
                            <Option title="Paused" value={3}>
                              <i className="fa fa-pause">
                                {userProfile.paused}
                              </i>
                            </Option>
                          )}
                          <Option value={4}>
                            <i className="fa fa-ban">{userProfile.denied}</i>
                          </Option>
                        </Select>
                        {isAdmin ? (
                          <img
                            src={info}
                            className="info-icon"
                            alt="info"
                            onClick={() => {
                              this.fetchGroupStatusHistory(
                                selectedGroup.id,
                                selectedGroup.name,
                                user.userId
                              );
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    {user.addedByUsername ? (
                      <div className="Added-by">
                        {userProfile.addedBy}{" "}
                        <span className="added-by-username">
                          {user.addedByUsername}
                        </span>{" "}
                        on {this.getTime(user.createdAt)}
                      </div>
                    ) : null}
                  </div>
                );
              })}
              {offlineUsers.map((user) => {
                const groupStatus = this.status(user.status);
                const statusInGroup = user.status;
                return (
                  <div className="user-group-container" key={user.username}>
                    <div className="user-group">
                      <h6
                        className={
                          isAdmin
                            ? "group-users-with-status add-pointer"
                            : "group-users-with-status"
                        }
                        onClick={() => this.usernamePress(user.userId)}
                      >
                        {user.username}
                      </h6>
                      <div
                        id="session-members-container"
                        style={{ position: "relative" }}
                      >
                        <Select
                          value={groupStatus}
                          style={{ width: 120 }}
                          onChange={(value) =>
                            this.setGroupStatusForOfflineUsers(
                              value,
                              user.userId,
                              selectedGroup.id
                            )
                          }
                          getPopupContainer={() =>
                            document.getElementById("session-members-container")
                          }
                        >
                          {statusInGroup === 1 ? null : (
                            <Option title="Active" value={1}>
                              <i className="fa fa-check">
                                {userProfile.activate}
                              </i>
                            </Option>
                          )}
                          {statusInGroup === 2 ? null : (
                            <Option title="Suspended" value={2}>
                              <i className="fa fa-minus-circle">
                                {userProfile.suspend}
                              </i>
                            </Option>
                          )}
                          {statusInGroup === 3 ? null : (
                            <Option title="Paused" value={3}>
                              <i className="fa fa-pause">
                                {userProfile.paused}
                              </i>
                            </Option>
                          )}
                          <Option value={4}>
                            <i className="fa fa-ban">{userProfile.denied}</i>
                          </Option>
                        </Select>
                        {isAdmin ? (
                          <img
                            src={info}
                            className="info-icon"
                            alt="info"
                            onClick={() => {
                              this.fetchGroupStatusHistory(
                                selectedGroup.id,
                                selectedGroup.name,
                                user.userId
                              );
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    {user.addedByUsername ? (
                      <div className="Added-by">
                        {userProfile.addedBy}{" "}
                        <span className="added-by-username">
                          {user.addedByUsername}
                        </span>{" "}
                        on {this.getTime(user.createdAt)}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
export default SessionMembers;
